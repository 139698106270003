import React, { createContext, useContext } from 'react'

import _ from 'lodash'
import { toJS, makeAutoObservable, reaction } from 'mobx'

export class RouteMachineDebug {
  _store

  urlInfo = null

  consoleLogging = false

  constructor(store) {
    makeAutoObservable(this, {})

    this._store = store
  }

  loadFromStorage(cfg) {
    this.consoleLogging = cfg.consoleLogging
  }

  asJson() {
    return {
      consoleLogging: this.consoleLogging,
    }
  }

  setUrlInfo(urlInfo) {
    this.urlInfo = urlInfo
  }

  toggleConsoleLogging() {
    this.consoleLogging = !this.consoleLogging
  }
}
