import React, { useState } from 'react'

import _ from 'lodash'
import { useRouteMatch, generatePath, Redirect } from 'react-router'

const SearchRedirect = ({ rewrites: rw }) => {
  const [rewrites] = useState(_.keyBy(rw, 'from'))
  const { path, params } = useRouteMatch()

  const topath = rewrites[path]?.to
  const to = topath ? generatePath(topath, params) : '/404'

  return <Redirect to={to} />
}

export default SearchRedirect
