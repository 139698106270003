import React from 'react'
import { Typography, Box, Divider } from '@mui/material'

import { useConfig } from '@/config'
import LandingHero from './LandingHero.jsx'
import LandingTestimonials from './LandingTestimonials.jsx'
import LandingPoints from './LandingPoints.jsx'
import LandingRegAndList from './LandingRegAndList.jsx'
import LandingContactUs from './LandingContactUs.jsx'

const Landing = (props) => {
  const assets = useConfig()?.tenantConfig?.assets?.landing

  const points1 = [
    'Locates the nearest service or within a specific distance',
    'Finds both fixed and visiting services',
    'Provides options for when services are provided (date range)',
  ]

  const points2 = [
    'Fixed service: \nA service that is available to you at any time',
    'Visiting/Outreach service: \nA service that comes to your community at certain times.',
  ]

  const testimonials = [
    'Access My Healthcare is an online calendar of health and community services that are local to you.',
    '',
  ]

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <LandingHero />
      <LandingTestimonials title="Who are we?" body={testimonials[0]} />
      <Box sx={{ display: 'flex', flexDirection: 'column ', gap: 3 }}>
        <Typography
          variant="h4"
          subvariant="landing"
          color="primary"
          sx={{ margin: '0 auto' }}
        >
          How does it work?
        </Typography>
        <LandingPoints items={points1} image={assets.testimonial_1} />
        <Typography
          variant="h4"
          subvariant="landing"
          color="primary"
          sx={{ textAlign: 'center', marginTop: 5 }}
        >
          What do you mean by Fixed or Visiting/Outreach service?
        </Typography>
        <LandingPoints
          items={points2}
          image={assets.testimonial_2}
          alignImage="right"
        />
      </Box>
      <LandingTestimonials
        title="Find services local to you, and those that come to town."
        body={testimonials[1]}
      />
      <LandingRegAndList />
      <LandingContactUs />
    </Box>
  )
}

export default Landing
