import React from 'react'

import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { Helmet } from 'react-helmet'

import { useRouteStore } from '@/stores'

export const Headers = observer(() => {
  const routeStore = useRouteStore()

  const handleChangeClientState = ({ onChangeClientState, ...helmetState }) => {
    routeStore.setHelmetState(helmetState)
  }

  const metaTags = _.map(
    routeStore.metaTagInfo?.metaItems || {},
    ({ component: MetaTag, innerContent, ...props }, k, i) => (
      <MetaTag key={i} {...props}>
        {innerContent}
      </MetaTag>
    )
  )

  return (
    <Helmet onChangeClientState={handleChangeClientState}>{metaTags}</Helmet>
  )
})

export default Headers
