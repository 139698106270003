import { isObservableArray } from 'mobx'
import intersection from 'lodash/intersection'
import flatten from 'lodash/flatten'
import uniq from 'lodash/uniq'
import _ from 'lodash'

const ops = {
  eq(a0, a1) {
    return a0 === a1
  },

  neq(a0, a1) {
    return a0 !== a1
  },

  contains(a0, a1) {
    if (!isObservableArray(a0)) {
      console.error('"contains" for a0 only supports observable arrays')
      return false
    }

    if (typeof a1 === 'string') return a0.slice().includes(a1)

    if (Array.isArray(a1)) return intersection(a0.slice(), a1).length > 0

    console.error('"contains" type not supported for a1')
    return false
  },
}

export const evaluateStepRules = (survey, step, ruleset = 'when') => {
  if (!survey || !step) return false
  if (!(ruleset in step)) return true

  // only AND for all rules supported

  const res = step[ruleset].every(rule => {
    const [a0key, op, a1] = rule

    if (!(a0key in survey)) {
      console.error(`Invalid survey key "${a0key}"`)
      return false
    }

    if (!(op in ops)) {
      console.error(`Invalid operator "${op}"`)
      return false
    }

    try {
      const a0 = survey[a0key]
      return ops[op](a0, a1)
    } catch {
      return false
    }
  })

  return res
}

export const resolveVariantText = (obj, key, pathway = 'self') => {
  if (!obj) return undefined
  if (!(key in obj)) return undefined
  if (!obj[key]) return undefined
  if (typeof obj[key] === 'string') return obj[key]

  if (!(pathway in obj[key])) return undefined
  return obj[key][pathway]
}

export const resolveVariantStrings = (obj, pathway = 'self') => {
  if (!_.isObject(obj)) return undefined
  return _.mapValues(obj, v => (_.isString(v) ? v : v?.[pathway]))
}

// category mapping

export const mapInterestCategories = (question, interests) => {
  return {
    categories: uniq(
      flatten(
        question.interests
          .filter(i => 'categories' in i && interests.includes(i.id))
          .map(i => i.categories)
      )
    ),
  }
}

export const mapScaleEvalCategories = (score, cmap) => {
  // console.log('map', score, cmap)
  if (!score || !cmap || !(score in cmap)) return {}

  const categories = cmap[score].categories

  return {
    categories,
    selected: categories.length > 0 ? categories[0] : null,
  }
}

export const mapPropertyCategories = (value, cmap) => {
  if (!value || !cmap || !(value in cmap)) return {}

  return {
    categories: cmap[value].categories,
  }
}
