import { config } from '@/config'
import { milliseconds as ms } from 'date-fns'
import { QueryClient } from 'react-query'
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental'
import { persistQueryClient } from 'react-query/persistQueryClient-experimental'

export const createQueryClient = (options) => {
  const { useWebSoragePersistor = true } = options || {}

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        //  cacheTime: 1000 * 60 * 60 * 24, // 24 hours
        cacheTime: ms({ minutes: 10 }),
      },
    },
  })

  if (!useWebSoragePersistor) return queryClient

  const localStoragePersistor = createWebStoragePersistor({
    key: `ams-${config.appConfig.tenant}-query-persist`,
    storage: window.localStorage,
  })
  // const sessionStoragePersistor = createWebStoragePersistor({ storage: window.sessionStorage })

  persistQueryClient({
    queryClient,
    persistor: localStoragePersistor,
  })

  return queryClient
}
