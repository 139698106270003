import React, { useState } from 'react'

import ReactMarkdown from 'markdown-to-jsx'
import { NavLink } from 'react-router-dom'
import { Link as RouterLink } from 'react-router-dom'

// import scrollIntoView from 'scroll-into-view-if-needed'

import { withStyles } from '@mui/styles'
import { Box, Typography, Divider, Link, Button } from '@mui/material'

import MarkdownAccordion from '@/ui/markdown/MarkdownAccordion'
import ImageAsset from '@/ui/components/images/ImageAsset'
import HomeButtons from '@/ui/nav/home-buttons/HomeButtons'
import Logo from '@/ui/components/images/Logo'
import PreviousSearch from '@/ui/components/atoms/PreviousSearch'
import CieBlock from '@/ui/footer/CieBlock'
import Flags from '@/ui/footer/Flags'

const SiteSupport = () => (
  <Button
    variant="outlined"
    color="primary"
    to="/support"
    component={RouterLink}
    // onClick={handleClickSiteSupport}
  >
    <Box mr={1}>
      <i className="fas fa-user-headset" />
    </Box>
    Contact Site Support
  </Button>
)

const styles = (theme) => ({
  ul: {
    paddingInlineStart: '30px !important',
    [theme.breakpoints.down('sm')]: {
      paddingInlineStart: '20px !important',
    },
  },
  li: {
    marginTop: theme.spacing(1),
  },
  navLink: {
    fontSize: 16,
  },
})

const makeOptions = ({ expanded, onAccordionClick = () => undefined }) => ({
  overrides: {
    h1: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'h4',
        // sx: { mb: 2 },
      },
    },
    h2: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'h5',
        // sx: { mb: 2 },
      },
    },
    h3: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'subtitle1',
        // fontSize: '1.16rem',
        fontSize: '1rem',
        fontWeight: 600,
        // sx: { mb: 2, mt: 4 },
      },
    },
    h4: {
      component: Typography,
      props: { gutterBottom: true, variant: 'caption', paragraph: true },
    },
    h5: { component: Typography, props: { gutterBottom: true, variant: 'h6' } },
    p: { component: Typography, props: { variant: 'body1', paragraph: true } },
    a: { component: Link, props: { underline: 'none' } },
    ul: {
      component: withStyles(styles)(({ classes, children }) => (
        <ul className={classes.ul}>{children}</ul>
      )),
    },
    li: {
      component: withStyles(styles)(({ classes, ...props }) => (
        <li className={classes.li}>
          <Typography component="div" {...props} />
        </li>
      )),
    },
    hr: {
      component: Divider,
    },
    box: {
      component: Box,
    },
    faq: {
      component: Box,
      props: { my: 2 },
    },
    homebuttons: {
      component: HomeButtons,
    },
    disclaimer: {
      component: Box,
      props: { fontSize: 14, fontWeight: 300, color: '#666', pt: 6 },
    },
    accordion: {
      component: MarkdownAccordion,
      props: {
        expanded,
        onAccordionClick,
      },
    },
    navlink: {
      component: NavLink,
    },
    imgasset: {
      component: ImageAsset,
    },
    logo: {
      component: Logo,
    },
    previoussearch: {
      component: PreviousSearch,
    },
    sitesupport: {
      component: SiteSupport,
    },
    cieblock: {
      component: CieBlock,
    },
    flags: {
      component: Flags,
    },
  },
})

const SimpleMarkdown = ({ ...props }) => {
  const [expanded, setExpanded] = useState(undefined)

  const handleAccordionClick = (panel) => {
    setExpanded(panel === expanded ? undefined : panel)
  }

  const options = makeOptions({
    expanded,
    onAccordionClick: handleAccordionClick,
  })

  return <ReactMarkdown options={options} {...props} />
}

export default SimpleMarkdown
