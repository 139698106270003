import React from 'react'

import _ from 'lodash'
import { Link as RouterLink } from 'react-router-dom'

import clsx from 'clsx'

import { makeStyles, withStyles } from '@mui/styles'
import { Container, Grid, Box, Button, Link, Icon } from '@mui/material'
import { useConfig } from '@/config'
import { useRootStore } from '@/stores/root/store'
import { height } from '@mui/system'

const homeButtonStyles = () => ({
  root: {},
  iconContainer: {},
  label: {},
})

const HomeButton = withStyles(homeButtonStyles, { name: 'AMSHomeButton' })(
  (props) => {
    const {
      id,
      classes,
      color = 'primary',
      variant = 'contained',
      elevation = 2,
      label = 'Unnamed Button',
      icon = 'fas fa-question',
      route: to = '/404',
      sx: buttonSx = {},
      layout = 'column',
      toTab = null,
      loadiconWithId = false,
    } = props || {}

    const store = useRootStore()

    const { tenantConfig: tc } = useConfig()
    const btnDict = tc?.ui?.homeButtons?.iconImgSet

    const IconComp = () => {
      if (btnDict && loadiconWithId) {
        return btnDict[id]?.src
      }
      return <Box as="i" className={icon} />
    }

    let btnSX = {
      flexDirection: 'column',
      minHeight: '22vh',
      p: 2,
      py: { xs: 2, md: 6 },
      justifyContent: 'flex-center',
      alignItems: 'flex-center',
      ...buttonSx,
    }

    let iconSX = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: { xs: '1.6rem', md: '2.38rem' },
    }

    let labelSX = {
      mt: 2,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      fontSize: '1.125rem',
    }
    if (layout === 'row') {
      btnSX = {
        flexDirection: 'row',
        p: 5,
        minHeight: '21vh',
        justifyContent: 'flex-center',
        alignItems: 'flex-center',
        ...buttonSx,
      }
      iconSX = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '15px',
        fontSize: { xs: '1.6rem', md: '2.38rem' },
      }
      labelSX = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: '1.125rem',
      }
    }
    if (props.btnStyle) {
      btnSX = { borderRadius: props.btnStyle, ...btnSX }
    }
    const handleHomeBtnClick = (toTab, to) => {
      if (toTab !== null) {
        store.search.ui.setSelectedTab(toTab)
      }
    }

    let isExternalLink = to.startsWith('http')

    return isExternalLink ? (
      <a href={to} target="_blank" rel="noopener noreferrer">
        <Button
          // component={RouterLink}
          className={classes.root}
          variant={variant}
          color={color}
          elevation={elevation}
          sx={btnSX}
          fullWidth
          onClick={() => handleHomeBtnClick(toTab, to)}
        >
          <Box className={classes.iconContainer} sx={iconSX}>
            {IconComp()}
            {/* <Box as="i" className={icon} /> */}
            {/* <img src="/static/media/cutlery.a3e2a3f7.png"></img> */}
          </Box>
          <Box className={classes.label} sx={labelSX}>
            {label}
          </Box>
        </Button>
      </a>
    ) : (
      <Button
        component={RouterLink}
        className={classes.root}
        variant={variant}
        color={color}
        elevation={elevation}
        to={to}
        sx={btnSX}
        fullWidth
        onClick={() => handleHomeBtnClick(toTab)}
      >
        <Box className={classes.iconContainer} sx={iconSX}>
          {/* <Box as="i" className={icon} /> */}
          {IconComp()}
        </Box>
        <Box className={classes.label} sx={labelSX}>
          {label}
        </Box>
      </Button>
    )
  }
)

const homeButtonsStyles = (theme) => ({
  root: {},
})

const HomeButtons = (props) => {
  const { classes, maxWidth = 'md', spacing = 2, gridProps } = props

  const { tenantConfig: tc } = useConfig()
  const stackStyle = { grid: 6, column: 12, row: 4 }
  const config = tc.ui?.homeButtons || {}
  const displayMode = tc.ui?.homeButtons?.mode ?? 'grid'

  const { buttons } = config

  if (!buttons.length) return null

  return (
    <Box
      sx={{
        // border: '2px solid red',
        my: 3,
        // mx: -spacing,
        // margin: '0 auto',
        mx: 'auto',
        maxWidth,
      }}
    >
      <Grid container spacing={spacing}>
        {buttons.map((button, i) => (
          <Grid
            item
            key={i}
            {...{ xs: 12, md: stackStyle[displayMode], ...gridProps }}
          >
            <HomeButton {...button} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default withStyles(homeButtonsStyles, { name: 'AMSHomeButtons' })(
  HomeButtons
)
