import '../polyfills'
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'
import { StylesProvider } from '@mui/styles'
import { analytics } from '@/analytics'
import { MstReduxDevToolsProvider } from '@/stores/debug/mstReduxDevTools'
// import * as serviceWorker from './serviceWorker'
// import { RecoilRoot } from 'recoil'
import { Provider as JotaiProvider } from 'jotai'
import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClientProvider } from 'react-query'
import '@/tenants/shared/fontawesome'
import '@/tenants/shared/styles/perfect-scrollbar.scss'
import '@/tenants/shared/styles/rcslider.scss'
import { createQueryClient } from '@/ui/app/query-client'
import { useTenantThemes } from '@/ui/theme/TenantTheme'
import { AnalyticsProvider } from 'use-analytics'
import { setAxiosConfig } from '@ciss/cie-api-orval'
import { config } from '@/config'
import { AppContainer } from './AppMain'

import { ReduxDevtoolsMonitors } from '@/ui/debug/redux-devtools/ReduxMonitors'

/**
 * @ciss/cie-api-orval is built as a separate module and needs
 * an unversioned baseURL to configure Axios mutator
 */
;(() => {
  const { baseURL: url, apiKey } = config.appConfig
  const baseUrl = new URL(config.appConfig.apiURL).origin
  setAxiosConfig({
    baseUrl,
    apiKey,
  })
})()

// // eslint-disable-next-line no-undef
// const tenantConfig = require(`@/tenants/${process.env.TENANT}/config`).default

// if (tenantConfig.fonts) {
//   Webfont.load(tenantConfig.fonts)
// }

export const AppEntry = () => {
  // const queryClient = new QueryClient()
  const themes = useTenantThemes()
  const queryClient = createQueryClient({ useWebSoragePersistor: false })

  return (
    <JotaiProvider>
      <AnalyticsProvider instance={analytics}>
        <QueryClientProvider client={queryClient}>
          <StyledEngineProvider injectFirst>
            <StylesProvider>
              <ThemeProvider theme={themes.base}>
                <CssBaseline />
                <AppContainer />
              </ThemeProvider>
            </StylesProvider>
          </StyledEngineProvider>
        </QueryClientProvider>
      </AnalyticsProvider>
    </JotaiProvider>
  )
}

// serviceWorker.unregister()
