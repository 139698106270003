import React from 'react'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useAnalytics } from 'use-analytics'

import { makeStyles, withStyles } from '@mui/styles'
import { Grid } from '@mui/material'

import SearchInput from '@/ui/search/params/SearchInput'
import ParameterSelect from '@/ui/search/params/ParameterSelect'
import LocationInput from '@/ui/location/LocationInput'
import GeographySelect from '@/ui/search/params/GeographySelect'
import RadiusSlider from '@/ui/search/params/RadiusSlider'
import DateRangeParam from '@/ui/search/params/DateRangeParam'
import LabelSwitch from '@/ui/components/atoms/LabelSwitch'
import CategoryButtons from '@/ui/search/params/CategoryButtons'
import IconLabel from '@/ui/components/atoms/IconLabel'

import { useRootStore } from '@/stores/root/store'
import { config, useConfig } from '@/config'

import CategorySelect from './CategorySelect'
import LocationsDisplay from './LocationsDisplay'

// const { tenantConfig } = config

const Parameter = observer((props) => {
  const { control, param, disableLabel = false } = props
  const { track } = useAnalytics()
  const { tenantConfig: tc } = useConfig()

  const store = useRootStore()
  const params = store.search.params
  const ui = store.search.ui

  const handleChange = (value) => {
    track('searchParamaterChange', {
      category: 'Search',
      label: param.id,
    })

    store.search.params.set(param.id, value)
  }

  const handleCategoriesChange = (selected) => {
    track('searchParamaterChange', {
      category: 'Search',
      label: 'selectedCategories',
    })

    params.setSelectedCategories(selected)
  }

  const handleShowAllCategories = (value) => {
    track('categoriesToggleAllCategories', {
      category: 'Search',
      label: value ? 'Show' : 'Hide',
    })

    store.search.setShowAllCategories(value)
  }

  const type =
    'searchParamaterType' in param
      ? param.searchParamaterType
      : 'type' in param
      ? param.type
      : null

  const paramControl = () => {
    switch (type) {
      case 'query':
        return (
          <SearchInput
            value={params[param.id]}
            onChange={handleChange}
            {...control.props}
            fullWidth
          />
        )

      case 'location':
        return (
          <LocationInput
            value={params[param.id]}
            onChange={(_, value) => handleChange(value)}
            // showButtonLabel={false}
            fullWidth
            defaultLocationButton
            // defaultL
          />
        )

      case 'geographySelect':
        return (
          <GeographySelect
            paramConfig={param}
            value={toJS(params[param.id])} // why??
            onChange={handleChange}
            fullWidth
          />
        )

      case 'radius':
        return (
          <RadiusSlider
            value={params[param.id]}
            onChange={handleChange}
            {...param.props}
            fullWidth
          />
        )

      case 'dateRange':
        return (
          <DateRangeParam
            value={params[param.id]}
            onChange={handleChange}
            fullWidth
          />
        )

      case 'categoryButtons':
        return (
          <>
            {store.search.params.categories.length > 0 && (
              <LabelSwitch
                checked={ui.showAllCategories}
                onChange={handleShowAllCategories}
                label="Show all categories"
              />
            )}

            <CategoryButtons
              selectMode={tc.options.categoryMode}
              categories={params.visibleCategories}
              selected={params.selectedCategories}
              onChange={handleCategoriesChange}
              fullWidth
            />
          </>
        )

      case 'categorySelect':
        return (
          <CategorySelect
            selectMode={tc.options.categoryMode}
            categories={params.visibleCategories}
            selected={params.selectedCategories}
            onChange={handleCategoriesChange}
            fullWidth
          />
        )

      case 'select':
        return (
          <ParameterSelect
            value={params[param.id]}
            options={param.options}
            onChange={handleChange}
            fullWidth
          />
        )

      case 'outlets':
        return <LocationsDisplay />

      default:
        return (
          <div>
            {type} {JSON.stringify(param, null, 2)}
          </div>
        )
    }
  }

  const ctrl = paramControl()

  return (
    <>
      {disableLabel || (
        <IconLabel label={param.label} iconClasses={param.icon} />
      )}
      {ctrl}
    </>
  )
})

const styles = (theme) => ({
  root: {},
})

const SearchParameters = observer((props) => {
  const { tenantConfig } = useConfig()

  const {
    classes,
    containerProps: cprops = {},
    itemProps: iprops = {},
    disableLabels = false,
    controls = [],
  } = props
  // console.log('props: ', props)
  // console.log('tenantConfig: ', tenantConfig)
  const pc = tenantConfig.ui?.search?.parameters || {}

  const params = controls
    .map((c) => ({ control: c, param: pc[c.param] }))
    .filter((c) => !!c.param)

  if (params.length === 0) return null

  const { sx: csx = {}, ...otherCprops } = cprops

  const containerProps = {
    sx: { p: 3, ...csx },
    container: true,
    columns: 1,
    spacing: 3,
    alignItems: 'stretch',
    ...otherCprops,
  }

  const itemProps = {
    item: true,
    xs: 1,
    ...iprops,
  }

  const paramProps = {
    disableLabel: disableLabels,
  }

  return (
    <Grid className={classes.root} {...containerProps}>
      {params.map((p, i) => (
        <Grid key={i} {...itemProps}>
          <Parameter {...{ ...paramProps, ...p }} />
        </Grid>
      ))}
    </Grid>
  )
})

export default withStyles(styles, { name: 'AMSSearchParameters' })(
  SearchParameters
)
