import _ from 'lodash'

/**
 * Formats array of strings to human friendly list
 */
export const toEnglishList = (
  items: string[],
  opts: { delimiter?: string; terminator?: string }
) => {
  const { delimiter = ', ', terminator = ' and ' } = opts || {}

  if (!Array.isArray(items)) return null
  if (items.length <= 2) return items.join(terminator)

  return [_.take(items, items.length - 1).join(delimiter), _.last(items)].join(
    terminator
  )
}
