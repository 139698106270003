import React, { createContext, useContext, useEffect } from 'react'

import _ from 'lodash'

import { toJS, makeAutoObservable } from 'mobx'
import { toJSDeep } from '../root/utils/mobx'
import { useLocation, useRouteMatch } from 'react-router-dom'
import { Liquid } from 'liquidjs'

import { config } from '@/config'
import {
  loadRouteConfig,
  mergeMetaConfigs,
  buildMetaItems,
} from '@/stores/route/route-utils'

import { asArray } from '@/utils'

import { rootStore as rootStore } from '@/stores/root/store'
import { RoutePath } from './RoutePath'
import { RouteViews } from './RouteViews'

export class RouteConfig {
  _store = null

  constructor(store, config) {
    _.assign(this, config)

    makeAutoObservable(this, {
      store: false,
    })

    this._cfgkeys = _.keys(config)

    this.store = store
    this._config = config
  }

  get helpers() {
    return {
      json: [...this._cfgkeys],
    }
  }

  get routeConfigsByPath() {
    return _.fromPairs(
      _.flatten(
        _.map(this._config.routes, (r) => asArray(r.path).map((p) => [p, r]))
      )
    )
  }
}

export class RouteQuery {
  _store = null
  constructor(store) {
    makeAutoObservable(this, {})

    this._store = store
  }
}

export class RouteStore {
  rootStore = null
  config = null
  location = null
  match = null
  helmetState = null

  path = null
  query = null

  liquid = null

  constructor(options) {
    makeAutoObservable(this, {
      config: false,
      templateViews: false,
      liquid: false,
    })

    this.rootStore = rootStore

    const cfg = loadRouteConfig(config)
    this.config = new RouteConfig(this, cfg)

    this.routeViews = new RouteViews(this)
    this.path = new RoutePath(this)
    this.query = new RouteQuery(this)

    this.liquid = new Liquid()
  }

  get helpers() {
    return {
      config: { store: this.config },
      path: { store: this.path },
      json: [
        'location',
        'match',
        'matchedRoute',
        'metaConfig',
        'routeConfigViews',
        'metaTagInfo',
        'helmetState',
        // 'searchRouteHandler',
      ],
    }
  }

  updateFromReactRouter({ location, match }) {
    this.location = location
    this.match = match

    // this.searchRouteMachine.updateFromRouter({ match })
  }

  setLocation(location) {
    this.location = location
  }

  setHelmetState(state) {
    this.helmetState = state
  }

  setSearchRouteHandler(info) {
    this.searchRouteHandler = info
  }

  get tenant() {
    return this.rootStore.tenant
  }

  get matchedRoute() {
    if (!this.match) return null
    return this.config.routeConfigsByPath[this.match.path]
  }

  get metaConfig() {
    if (!this.matchedRoute) return null

    const { path } = this.match
    const configs = [this.config, this.matchedRoute].map((c) => c.meta)

    // console.log({ configs: toJSDeep(configs) })

    return mergeMetaConfigs(configs, { path })
  }

  get routeConfigViews() {
    const { views } = this.metaConfig || {}
    // console.log(this.metaConfig, { views })
    if (!views) return {}

    return _.fromPairs(views.map((k) => [k, this.routeViews[k]]))
    // return {}
  }

  get templateView() {
    return {
      ...rootStore,
      route: {
        ...this.routeConfigViews,
      },
      pathInfo: this.path.info,
      match: this.match,
    }
  }

  get metaTagInfo() {
    const { matchedRoute, templateView } = this
    if (!matchedRoute) return null

    const renderedTemplates = _.mapValues(
      this.metaConfig?.templates || {},
      (t) => this.liquid.parseAndRenderSync(t, this.templateView)
    )

    const metaItems = buildMetaItems({
      renderedTemplates,
      matchedRoute,
      templateView,
      pathInfo: this.path.info,
    })

    return {
      renderedTemplates,
      metaItems,
    }
  }
}

export const routeStore = new RouteStore()
window.routeStore = routeStore

const routeStoreContext = createContext(routeStore)
export const useRouteStore = () => useContext(routeStoreContext)

export const RouteStoreUpdater = () => {
  const store = useContext(routeStoreContext)
  const location = useLocation()
  const match = useRouteMatch()

  useEffect(() => {
    // console.log('RouteStoreProvider', { location, match })
    store.updateFromReactRouter({ location, match })
  }, [location, location?.pathname, location?.hash, location?.key])

  return null
}
