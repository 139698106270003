import React from 'react'
import _ from 'lodash'

import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'

export const useMediaChecks = (queries = []) => {
  const theme = useTheme()

  const props = queries.reduce((res, { query, breakpoints, prop }) => {
    const queryfn = theme.breakpoints[query]

    if (!queryfn) {
      console.warn(`useMediaProps: Invalid query ${query}`)
      return res
    }

    const match = useMediaQuery(queryfn(breakpoints))

    return {
      ...res,
      [prop]: match,
    }
  }, {})

  return props
}

export const ComponentMap = ({
  components = {},
  items = [],
  renderProps = ({ props }) => props,
}) => {
  const ritems = (items || [])
    .map(({ component, media, props }, k) => {
      const { render = true } = useMediaChecks(media)

      if (!render) return null

      const C = components[component]
      if (!C) return null

      const itemProps = renderProps({ component, props })

      return <C key={k} {...itemProps} />
    })
    .filter((x) => !!x)

  // const ritems = (items || []).map((c, k) => {
  //   const ckey = _.head(_.intersection(_.keys(c), _.keys(components)))
  //   const props = c[ckey]
  //   const C = components[ckey]

  //   if (!C || !props) return null

  //   const itemProps = renderProps({ component: ckey, props })

  //   return <C key={k} {...itemProps} />

  // }).filter(x => !!x)

  if (!ritems || ritems.length === 0) return null

  return <>{ritems}</>
}

export default ComponentMap
