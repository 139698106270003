import {
  DirectorySearchProps,
  DiarySearchProps,
  FavouritesSearchProps,
  useDirectorySearch,
  useDiarySearch,
  useFavouritesSearch,
} from '../queries'
import { SearchType } from '../queries/types'
import _ from 'lodash'
import { observer, useObserver } from 'mobx-react-lite'
import React, { FC, useEffect } from 'react'

/**
 * Entry FC for {@link useDirectorySearch}
 *
 * @category Components
 * @internal
 */
const DirectorySearch: FC<DirectorySearchProps> = (props) => {
  useDirectorySearch(props)
  return null
}

/**
 * Entry FC for {@link useDiarySearch}
 *
 * @category Components
 * @internal
 */
const DiarySearch: FC<DiarySearchProps & { searchId: string }> = (props) => {
  useDiarySearch(props)
  return null
}

/**
 * Entry FC for {@link useFavouritesSearch}
 *
 * @category Components
 * @internal
 */
const FavouritesSearch: FC<FavouritesSearchProps & { searchId: string }> = (
  props
) => {
  useFavouritesSearch(props)
  return null
}

/**
 * Host component for search query
 *
 * @category Components
 */
export const SearchQueryHost = observer((props: { queryProps: any }) => {
  const { queryProps } = props
  const { searchType } = queryProps

  const t = searchType as SearchType
  if (!t) return null

  if (t === 'directory') return <DirectorySearch {...queryProps} />
  if (t === 'diary') return <DiarySearch {...queryProps} />
  if (t === 'favourites') return <FavouritesSearch {...queryProps} />

  return null
})

// export const SearchQueryHost = observer(SearchQueryHostInner)
