import React, { useEffect, useState } from 'react'

const getSize = () => ({
  width: window.innerWidth || 0,
  height: window.innerHeight || 0,
})

export const useResizeDetect = () => {
  const [size, setSize] = useState(getSize())

  const resizeListener = () => setSize(() => getSize())

  useEffect(() => {
    window.addEventListener('resize', resizeListener)

    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  return size
}
