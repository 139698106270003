import _ from 'lodash'
import { useConfig } from '@/config'

const addLocationProperties = (location) => {
  if (location.name && location.stateLong) return location
  return {
    ...location,
    name: location.url.replace(/_/g, ' '),
    stateLong: location.stateUrl.replace(/_/g, ' '),
  }
}

export const shortLabel = (location, options = {}) => {
  const { tenantConfig: tc } = useConfig()
  const showLocationPropsLabel = tc.search.location?.locationPropsLabel
  const { fields = ['name', 'state'] } = options

  if (!location) return

  let label = fields
    .map((f) => location?.[f])
    .filter((v) => !!v)
    .join(' ')

  console.log(showLocationPropsLabel)
  if (showLocationPropsLabel) {
    const loc = addLocationProperties(location)

    label = fields
      .map((f) => loc?.[f])
      .filter((v) => !!v)
      .join(' ')
  }

  return label
}

export const addState = (label, state) => {
  if (!label) return label

  const lsplit = label.match(/(.*)\s(\d{4})$/)
  if (!lsplit) return label

  const [lb, name, postcode] = lsplit
  return `${name} ${state} ${postcode}`
}

export const cleanLabel = (str) => {
  if (!str) return str

  // postcode

  // const pclen = leaveFullPostcode ? 4 : 3
  const pclen = 4
  const re = new RegExp(`^(.*)(\\s\\d{0,${pclen}})$`, 'i')
  const pcm = str.match(re)
  const r1 = pcm ? pcm[1] : str

  // state prefix

  const spm = r1.match(statePrefix)
  const r2 = spm ? spm[1] : r1

  return r2
}

const statePrefix = (() => {
  const states = ['qld', 'nsw', 'vic', 'tas', 'sa', 'wa', 'nt', 'act']

  const partials = _.chain(states)
    .map((s) => _.map([...Array(s.length).keys()], (i) => s.substr(0, i + 1)))
    .flatten()
    .sortBy()
    .sortedUniq()
    .value()

  return new RegExp(`(.*)\\s+(?:(${partials.join('|')}))$`, 'i')
})()
