import platform from 'platform'
import { logAnalytics } from '@/api/legacy'

const bundleMeta = (payload) => {
  const meta = payload?.meta
  if (!meta) return null

  return {
    rid: meta.rid,
    ts: meta.ts,
    tsIso: new Date(meta.ts).toISOString(),
  }
}

const bundleStat = (payload) => {
  return {
    ...payload,
    platform,
    meta: bundleMeta(payload),
  }
}

/**
 * `analytics` plugin for dumping extended data to elastic
 */
export const cieElastic = (userConfig) => {
  return {
    name: 'cie-elastic',

    config: {
      debug: userConfig?.debug || false,
      index: userConfig?.index,
      queue: [],
      updateInterval: userConfig?.updateInterval || 5000,
    },

    initialize: ({ config }) => {
      config.debug && console.log('initialize', config)

      setInterval(() => {
        if (config.queue.length === 0) return

        logAnalytics({
          index: config.index,
          payloads: config.queue,
        })
          .then((result) => config.debug && console.log('tx', config.queue))
          .catch((err) => config.debug && console.error(err))
          .finally(() => {
            config.queue = []
          })
      }, config.updateInterval)
    },

    page: ({ config, payload }) => {
      const page = bundleStat(payload)
      config.debug && console.log('page', page)
      config.queue.push(page)
    },

    track: ({ config, payload }) => {
      const track = bundleStat(payload)
      config.debug && console.log('track', track)
      config.queue.push(track)
    },

    identify: ({ config, payload }) => {
      const identify = bundleStat(payload)
      config.debug && console.log('identify', identify)
      config.queue.push(identify)
    },

    loaded: () => {
      return true
    },
  }
}

export default cieElastic
