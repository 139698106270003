import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { makeStyles } from '@mui/styles'
import { useSearchResults } from '@/api/search-query'
import FullCalendar from '@fullcalendar/react'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import { styled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'
import './Calendar.scss'
import moment from 'moment'
import { useConfig } from '@/config'
import { add } from 'lodash'

const useIconStyles = makeStyles(() => ({
  root: {},
  icon: {
    marginRight: 8,
  },
  distance: {
    display: 'flex',
  },
  openingHours: {
    display: 'flex',
  },
}))

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    // color: 'rgba(126, 99, 171, 0.9)',
    color: '#616161',
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid rgba(126, 99, 171, 0.9)',
  },
}))

function generateEvents(startDate, endDate, event) {
  let datesObj = []
  let recurrencePattern = 7

  if (event.recurrencePattern > 7) recurrencePattern = event.recurrencePattern

  if (event.type === 'event') startDate.add(recurrencePattern * -1, 'd')

  while (startDate < endDate) {
    const newPatterns = event.operatingDaysArray
      .filter((p) => p.dayOfWeek === startDate.day())
      .map((np) => ({
        resourceId: event.id,
        start: startDate.format(moment.HTML5_FMT.DATE) + 'T' + np.startTime,
        end: startDate.format(moment.HTML5_FMT.DATE) + 'T' + np.endTime,
        color: np.isDefault ? 'transparent' : 'rgba(126, 99, 171, 0.4)',
        borderColor: event.type === 'service' ? '#d14905' : '#0086ff',
        isDefault: np.isDefault ? true : false,
        // display: 'background',
      }))

    datesObj = datesObj.concat(newPatterns)

    if (datesObj.length > 0 && startDate.day() == 6) {
      startDate.date(startDate.date() + (recurrencePattern - 7 + 1))
    } else {
      startDate.date(startDate.date() + 1)
    }
  }

  return datesObj
}

const getMomentFromTimeString = (str, noSecond, isEnd) => {
  if (noSecond) {
    return moment(str).format('HH:mm')
  }
  if (isEnd) {
    if (moment(str).format('HH:mm:ss') === '00:00:00') {
      return moment(str).add(-1, 's').format('HH:mm:ss')
    }
  }
  return moment(str).format('HH:mm:ss')
}

const compareDistance = (a, b) => {
  if (a.distance < b.distance) {
    return -1
  }

  if (a.distance > b.distance) {
    return 1
  }

  return 0
}

const defaultOperatingDays = [
  {
    dayOfWeek: 1,
    dayOfWeekName: 'Monday',
    startTime: '09:00:00',
    endTime: '17:00:00',
    alwaysOpen: false,
    isDefault: true,
  },
  {
    dayOfWeek: 2,
    dayOfWeekName: 'Tuesday',
    startTime: '09:00:00',
    endTime: '17:00:00',
    alwaysOpen: false,
    isDefault: true,
  },
  {
    dayOfWeek: 3,
    dayOfWeekName: 'Wednesday',
    startTime: '09:00:00',
    endTime: '17:00:00',
    alwaysOpen: false,
    isDefault: true,
  },
  {
    dayOfWeek: 4,
    dayOfWeekName: 'Thursday',
    startTime: '09:00:00',
    endTime: '17:00:00',
    alwaysOpen: false,
    isDefault: true,
  },
  {
    dayOfWeek: 5,
    dayOfWeekName: 'Friday',
    startTime: '09:00:00',
    endTime: '17:00:00',
    alwaysOpen: false,
    isDefault: true,
  },
]

const AllDayEventsOperatingDays = [
  {
    dayOfWeek: 1,
    dayOfWeekName: 'Monday',
    startTime: '00:00:00',
    endTime: '23:59:59',
    alwaysOpen: false,
  },
  {
    dayOfWeek: 2,
    dayOfWeekName: 'Tuesday',
    startTime: '00:00:00',
    endTime: '23:59:59',
    alwaysOpen: false,
  },
  {
    dayOfWeek: 3,
    dayOfWeekName: 'Wednesday',
    startTime: '00:00:00',
    endTime: '23:59:59',
    alwaysOpen: false,
  },
  {
    dayOfWeek: 4,
    dayOfWeekName: 'Thursday',
    startTime: '00:00:00',
    endTime: '23:59:59',
    alwaysOpen: false,
  },
  {
    dayOfWeek: 5,
    dayOfWeekName: 'Friday',
    startTime: '00:00:00',
    endTime: '23:59:59',
    alwaysOpen: false,
  },
]

const DailyEventOpeningDays = (startAt, endAt) => {
  let openingHours = []
  let startMoment = moment(startAt)
  let endAtMomnet = moment(endAt)

  if (endAtMomnet.diff(startMoment, 'days') < 7) {
    while (startMoment < endAtMomnet) {
      openingHours.push({
        dayOfWeek: startMoment.day(),
        startTime: getMomentFromTimeString(startAt, false, false),
        endTime: getMomentFromTimeString(endAt, false, true),
        alwaysOpen: false,
      })
      startMoment.add(1, 'd')
    }
    return openingHours
  }

  for (var i = 0; i < 7; i++) {
    openingHours.push({
      dayOfWeek: i,
      // dayOfWeekName: 'Monday',
      startTime: getMomentFromTimeString(startAt, false, false),
      endTime: getMomentFromTimeString(endAt, false, true),
      alwaysOpen: false,
    })
  }
  return openingHours
}

function Calendar() {
  const classes = useIconStyles()
  const servicesResultsStore = useSearchResults('services')
  const eventsResultsStore = useSearchResults('events')
  const [servicesResults, setServicesResults] = useState(null)
  const [eventsResults, setEventsResults] = useState(null)
  const [events, setEvents] = useState([])
  const [resources, setResources] = useState([])
  const { appConfig } = useConfig()
  const directoryURL = appConfig.directoryURL
  const diaryURL = appConfig.diaryURL
  const calendarKey = appConfig.fullCalendarProductKey

  useEffect(() => {
    if (servicesResultsStore?.results && eventsResultsStore?.results) {
      setServicesResults(servicesResultsStore?.results?.flat)
      setEventsResults(eventsResultsStore?.results?.flat)
    } else {
      setEvents([])
      setResources([])
    }
  }, [servicesResultsStore?.results, eventsResultsStore?.results])

  useEffect(() => {
    let renderEvents = []
    const eventsResultO = eventsResults?.map((e) => {
      switch (e.repeats) {
        //daily
        case 0:
          return {
            ...e,
            operatingDaysArray: DailyEventOpeningDays(e.startAt, e.endAt),
            recurrencePattern: 1,
          }
        //weekly
        case 1:
          if (e.repeatOnDay !== null) {
            return {
              ...e,
              operatingDaysArray: e.repeatOnDay.split(',').map((d) => ({
                dayOfWeek: parseInt(d),
                startTime: getMomentFromTimeString(e.startAt, false, false),
                endTime: getMomentFromTimeString(e.endAt, false, true),
                alwaysOpen: false,
              })),
              recurrencePattern: 7 * e.repeatEvery,
            }
          }

          return {
            ...e,
            operatingDaysArray: AllDayEventsOperatingDays,
            recurrencePattern: 7 * e.repeatEvery,
          }
        //monthly
        case 2:
          return {
            ...e,
            operatingDaysArray: [
              {
                dayOfWeek: moment(e.startAt).day(),
                dayOfWeekName: 'Thursday',
                startTime: getMomentFromTimeString(e.startAt, false, false),
                endTime: getMomentFromTimeString(e.endAt, false, true),
                alwaysOpen: false,
              },
            ],
            recurrencePattern: 30,
          }
        //weeklyOnDay
        case 3:
          if (e.repeatOnDay === null) {
            if (e.isAllDay) {
              return {
                ...e,
                operatingDaysArray: AllDayEventsOperatingDays,
                recurrencePattern: 7,
              }
            }

            return {
              ...e,
              operatingDaysArray: [
                {
                  dayOfWeek: 1,
                  dayOfWeekName: 'Monday',
                  startTime: getMomentFromTimeString(e.startAt, false, false),
                  endTime: getMomentFromTimeString(e.endAt, false, true),
                  alwaysOpen: false,
                },
                {
                  dayOfWeek: 2,
                  dayOfWeekName: 'Tuesday',
                  startTime: getMomentFromTimeString(e.startAt, false, false),
                  endTime: getMomentFromTimeString(e.endAt, false, true),
                  alwaysOpen: false,
                },
                {
                  dayOfWeek: 3,
                  dayOfWeekName: 'Wednesday',
                  startTime: getMomentFromTimeString(e.startAt, false, false),
                  endTime: getMomentFromTimeString(e.endAt, false, true),
                  alwaysOpen: false,
                },
                {
                  dayOfWeek: 4,
                  dayOfWeekName: 'Thursday',
                  startTime: getMomentFromTimeString(e.startAt, false, false),
                  endTime: getMomentFromTimeString(e.endAt, false, true),
                  alwaysOpen: false,
                },
                {
                  dayOfWeek: 5,
                  dayOfWeekName: 'Friday',
                  startTime: getMomentFromTimeString(e.startAt, false, false),
                  endTime: getMomentFromTimeString(e.endAt, false, true),
                  alwaysOpen: false,
                },
              ],
              recurrencePattern: 7,
            }
          }

          return {
            ...e,
            recurrencePattern: 7,
            operatingDaysArray: e.repeatOnDay.split(',').map((d) => ({
              dayOfWeek: parseInt(d),
              // dayOfWeekName: 'Friday',
              startTime: getMomentFromTimeString(e.startAt, false, false),
              endTime: getMomentFromTimeString(e.endAt, false, true),
              alwaysOpen: false,
            })),
          }
        //one time event
        default:
          return {
            ...e,
            operatingDaysArray: [
              {
                dayOfWeek: moment(e.startAt).day(),
                // dayOfWeekName: moment(e.startAt).format('dddd'),
                startTime: getMomentFromTimeString(e.startAt, false, false),
                endTime: getMomentFromTimeString(e.endAt, false, true),
                alwaysOpen: false,
              },
            ],
            recurrencePattern: 60,
          }
      }
    })

    const combine = servicesResults?.concat(eventsResultO)
    combine?.map((e) => {
      if (e.operatingDaysArray.length === 0) {
        e.operatingDaysArray = defaultOperatingDays
      }

      renderEvents = renderEvents.concat(
        generateEvents(
          e.type === 'event' ? moment(e.startAt) : moment().add(-1, 'w'),
          //moment().add(-1, 'w'),
          e.type === 'event' &&
            moment(e.endAt).diff(moment(e.startAt), 'days') < 14
            ? moment(e.endAt)
            : moment()
                .add(2, 'w')
                .add(6 - moment().weekday(), 'd'),
          e
        )
      )
    })

    const calendarTitle = servicesResults
      ?.concat(
        eventsResults.filter(
          (r) => moment(r.startAt).diff(moment(), 'days') < 14
        )
      )
      .sort(compareDistance)
      .map((r) => ({
        title: r?.name,
        name: r?.name,
        description: r?.description,
        distanceLabel: r?.distanceLabel,
        id: r?.id,
        type: r?.type,
        dis: r?.distance,
        address: r?.address,
        category: r?.category,
        url: r?.url,
        lng: r?.lng,
        lat: r?.lat,
        time: r?.type === 'service' ? r.operatingDays : r.time,
        outletNickname:
          r?.type === 'service' ? r?.outletNickname : r?.locationTitle,
      }))

    setEvents(renderEvents)
    setResources(calendarTitle)
  }, [servicesResults, eventsResults])

  return (
    <Box sx={{ width: '100%', height: '100%', p: 3 }}>
      <FullCalendar
        validRange={function () {
          let before = moment().add(-7, 'd')
          let future = moment().add(2, 'w')

          return {
            start: moment()
              .add(before.weekday() * -1, 'd')
              .format(),
            end: moment()
              .add(2, 'w')
              .add(6 - future.weekday(), 'd')
              .format(),
          }
        }}
        schedulerLicenseKey={calendarKey}
        plugins={[resourceTimelinePlugin]}
        height="100%"
        initialView="customWeeks"
        resourceAreaHeaderContent=" "
        headerToolbar={{
          left: 'prev',
          center: 'title',
          right: 'next',
        }}
        views={{
          customWeeks: {
            type: 'resourceTimeline',
            duration: { weeks: 1 },
            slotLabelFormat: [
              { month: 'long', day: 'numeric', weekday: 'short' },
              function (date) {
                if (date.date.hour == 0) return 'AM'
                return 'PM'
              },
            ],
            slotDuration: '12:00:00',
            buttonText: 'Custom Week',
          },
        }}
        events={events}
        eventDidMount={(info) => {
          let isDefaultOpeningHour = info.event.extendedProps.isDefault

          let eventTooltip = document.createElement('div')
          eventTooltip.className = 'event-tooltip'

          info.el
            .querySelector('.fc-event-main')
            .parentNode.insertBefore(
              eventTooltip,
              info.el.querySelector('.fc-event-main').nextSibling
            )

          let displayTime = `${getMomentFromTimeString(
            info.event.start,
            true,
            false
          )} - ${getMomentFromTimeString(info.event.end, true, true)}`

          if (isDefaultOpeningHour) displayTime = displayTime + ' (uncomfirmed)'
          // if (info.event.end === '23:59') {
          //   displayTime = 'Appointment required'
          // }
          ReactDOM.render(
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">{displayTime}</Typography>
                </React.Fragment>
              }
            >
              <div className="event-tooltip-btn"></div>
            </HtmlTooltip>,
            eventTooltip
          )
        }}
        resources={resources}
        resourceAreaWidth="35%"
        resourceOrder="dis"
        resourceLabelDidMount={function (info) {
          let resourceProps = info.resource._resource.extendedProps
          let addressDiv = document.createElement('div')
          let outletNicknameDiv = document.createElement('div')
          let servcieCatDiv = document.createElement('div')
          let toolTipDiv = document.createElement('span')
          let url = ''

          addressDiv.className = 'service-address'
          addressDiv.innerText = resourceProps?.address

          outletNicknameDiv.className = 'outlet-nickname'
          outletNicknameDiv.innerText = resourceProps?.outletNickname
          servcieCatDiv.className = 'service-category'
          servcieCatDiv.innerText = resourceProps?.category

          toolTipDiv.className = 'tooltip'
          toolTipDiv.innerText = '  more...'

          if (resourceProps.type === 'service')
            url = directoryURL + resourceProps?.url
          else url = diaryURL + resourceProps?.url

          info.el
            .querySelector('.fc-datagrid-cell-main')
            .appendChild(toolTipDiv)

          info.el
            .querySelector('.fc-datagrid-cell-main')
            .parentNode.insertBefore(
              addressDiv,
              info.el.querySelector('.fc-datagrid-cell-main').nextSibling
            )

          if (resourceProps?.category) {
            info.el
              .querySelector('.fc-datagrid-cell-main')
              .parentNode.insertBefore(
                servcieCatDiv,
                info.el.querySelector('.fc-datagrid-cell-main').previousSibling
              )
          }
          if (resourceProps?.outletNickname !== resourceProps?.name) {
            info.el
              .querySelector('.fc-datagrid-cell-main')
              .parentNode.insertBefore(
                outletNicknameDiv,
                info.el.querySelector('.fc-datagrid-cell-main').nextSibling
              )
          }

          info.el.querySelector('.fc-datagrid-cell-main').onclick =
            function () {
              window.open(url, '_blank')
            }

          info.el.querySelector('.service-address').onclick = function () {
            window.open(
              `https://www.google.com/maps/search/?api=1&query=${resourceProps?.lat},${resourceProps?.lng}`,
              '_blank' // <- This is what makes it open in a new window.
            )
            // window.location.href = `https://www.google.com/maps/search/?api=1&query=${resourceProps?.lat},${resourceProps?.lng}`
          }

          const renderIn = info.el.querySelector('.tooltip')

          const Tooltip = () => {
            return (
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">
                      {`${info.fieldValue}`}
                    </Typography>
                    <div className={classes.root}>
                      <div className={classes.distance}>
                        <div className={classes.icon}>
                          <i className="fas fa-running" />
                        </div>
                        <div>{resourceProps?.distanceLabel}</div>
                      </div>
                      <div className={classes.openingHours}>
                        <div className={classes.icon}>
                          <i className="fas fa-clock " />
                        </div>
                        <div>
                          {resourceProps?.time === ''
                            ? '9AM - 5PM Mon-Fri '
                            : resourceProps?.time}
                        </div>
                      </div>
                    </div>
                    <div>{resourceProps?.description}</div>
                  </React.Fragment>
                }
              >
                <div className="tooltip-text">more...</div>
              </HtmlTooltip>
            )
          }

          ReactDOM.render(<Tooltip />, renderIn)
        }}
      />
    </Box>
  )
}

export default Calendar
