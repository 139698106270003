import React from 'react'

import { Box } from '@mui/material'
import { Mono, Json } from '@/ui/debug/debug-helper/Mono'

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: undefined }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error Boundary', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <Mono>
          <Box>Error Boundary</Box>
          <Json value={this.state.error} />
        </Mono>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
