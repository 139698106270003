/**
 * Direct Axios client requests.
 *
 * These functions are deprecated in favour of endpoints generated via OpenAPI schema.
 *
 * See `@ciss/cie-api-orval`
 *
 * @module @/api/legacy
 */
export * from './cie-api'
