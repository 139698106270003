import React from 'react'

import { makeStyles } from '@mui/styles'
import { useConfig } from '@/config'

import { TenantThemeProvider } from '@/ui/theme/TenantTheme'
import FooterSection from '@/ui/footer/FooterSection'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor:
        theme.palette.background.footer || theme.palette.grey[200],
      color: theme.palette.text.secondary,
      display: 'flex',
      flexDirection: 'column',
    },
  }),
  { name: 'AMSFooter' }
)

const Footer = () => {
  const classes = useStyles()
  const { tenantConfig: tc } = useConfig()
  const sections = tc.ui?.footer?.sections || []

  return (
    <footer className={classes.root}>
      {sections.map(({ theme, ...section }, i) => {
        if (!theme) return <FooterSection key={i} {...section} />

        return (
          <TenantThemeProvider key={i} theme={theme}>
            <FooterSection key={i} {...section} />
          </TenantThemeProvider>
        )
      })}
    </footer>
  )
}

export default Footer
