import React from 'react'

import { observer } from 'mobx-react-lite'

import { makeStyles } from '@mui/styles'
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
} from '@mui/material'

import { useRootStore } from '@/stores/root/store'
import LocationInput from '@/ui/location/LocationInput'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}))

const SurveyLocation = observer(({ label = null, defaultLocation = null }) => {
  const classes = useStyles()
  const { survey } = useRootStore()
  const { location } = survey

  // const

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const handleDefault = (e) => {
    survey.setLocation(e.target.checked ? defaultLocation : null)
  }

  const handleChange = (e, value) => {
    survey.setLocation(value)
  }

  return (
    <FormControl className={classes.root}>
      <FormLabel>{label}</FormLabel>
      <br />
      <LocationInput
        id="location-select-survey"
        disabled={location?.default}
        value={location}
        onChange={handleChange}
        showButtonLabel={!isMobile}
      />
      <FormControlLabel
        sx={{ mt: 2 }}
        control={
          <Checkbox
            checked={!!location?.default}
            onChange={handleDefault}
            name="default-location"
          />
        }
        label="I do not want to provide a location"
      />
    </FormControl>
  )
})

export default SurveyLocation
