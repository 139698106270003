import React, { useEffect } from 'react'

import _ from 'lodash'
import { toJS, computed } from 'mobx'
import { observer } from 'mobx-react-lite'

import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

import { neatJson } from '@/ui/vendor/neatjson'

import { useDebugStore } from '@/stores'
import { useRouteStore } from '@/stores'
import { useRootStore } from '@/stores/root/store'

const useStores = () => {
  const store = useDebugStore()
  const rootStore = useRootStore()
  const routeStore = useRouteStore()

  return {
    store,
    rootStore,
    routeStore,
  }
}

const Dump = styled('pre')`
  font-family: 'JetBrains Mono', monospace;
`

const Json = observer(() => {
  const store = useDebugStore()

  const jsonObj = store.objectForHelper()
  if (!jsonObj) return null

  const rendered = neatJson(jsonObj)

  return (
    <Box>
      <Dump>{rendered}</Dump>
    </Box>
  )
})

export default Json
