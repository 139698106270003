import Badge from './Badge'
import HealthEngineAppointment from './HealthEngineAppointment'
import NetworkMemberBadge from './NetworkMember'
import { useResultsControl } from './results-control'
import {
  Box,
  IconButton,
  Card,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material'
import grey from '@mui/material/colors/grey'
import { makeStyles, withStyles } from '@mui/styles'
import clsx from 'clsx'
import { useConfig } from '@/config'
import { useFavourite } from '@/api/search-query'
import { cloneDeep, merge } from 'lodash'
// import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useState, useEffect } from 'react'
import ErrorBoundary from '@/ui/components/atoms/ErrorBoundary'
import BadgeList from './BadgeList'
import IconText from '@/ui/components/atoms/IconText'
import { useCountRenders } from '@/ui/hooks/useCountRenders'
import { CieLink } from '@/ui/nav/Link'
import { useAnalytics } from 'use-analytics'

const styles = (theme) => ({
  root: {
    borderRadius: '4px',
    boxShadow: '1px 1px 3px 1px rgba(0, 0, 0, 0.07)',
    transform: 'none',
    cursor: 'pointer',
    fontSize: '0.937rem',
    margin: theme.spacing(2),
    [theme.breakpoints.down('ph')]: {
      borderRadius: 0,
      fontSize: '0.86rem',
      margin: theme.spacing(2, 0),
      boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.1)',
    },
  },
  selected: {
    transform: 'translate(-1px, -1px)',
    boxShadow: '1px 2px 4px 0 rgba(0, 0, 0, 0.3)',
    [theme.breakpoints.down('ph')]: {
      transform: 'translate(0, -1px)',
      boxShadow: '0px 2px 4px 0 rgba(0, 0, 0, 0.3)',
    },
  },
  separator: {
    margin: theme.spacing(2, 0),
    height: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  spacer: {
    height: theme.spacing(3),
  },
  topInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(2),
  },
  headerIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  mcdIcon: {
    width: '1.40625rem',
    height: '1.875rem',
  },
  datePill: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(0.7, 1),
    borderRadius: 3,
    fontSize: '1.125rem',
    fontWeight: 600,
  },
  datePillDiary: {
    fontSize: '1rem',
    fontWeight: 600,
    backgroundColor: '#417cbf',
    color: '#fff',
    padding: theme.spacing(0.7, 1),
    marginRight: theme.spacing(1),
    borderRadius: 3,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'stretch',
  },
  componentBlock: {
    width: '100%',
    margin: theme.spacing(2, 0),
  },
  actions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& i': {
      fontSize: '1.125rem',
    },
  },
  distance: {
    marginLeft: 'auto',
    fontSize: '0.93rem',
    fontWeight: 300,
    color: grey[200],
  },
  logo: {
    maxHeight: 70,
    maxWidth: 120,
  },
  logoSmall: {
    maxHeight: 40,
    maxWidth: 90,
  },
  titleBlock: {
    margin: theme.spacing(0, 0, 2),
  },
  title: {
    fontSize: '1.125rem',
    fontWeight: 400,
  },
  organisation: {
    fontSize: '0.93rem',
    margin: theme.spacing(1, 0),
    color: theme.palette.text.secondary,
  },
  category: {
    fontSize: '0.93rem',
    fontWeight: 300,
    color: theme.palette.text.secondary,
  },

  '@media print': {
    root: {
      breakInside: 'avoid',
    },
    separator: { display: 'none' },
    topInfo: {
      display: 'none',
    },
    actions: {
      display: 'none',
    },
  },
})

const useResultStyles = makeStyles(styles, { name: 'AMSSearchResult' })

// Mapped Components

const Separator = ({ classes }) => {
  return <div className={classes.separator} />
}

const Spacer = ({ classes }) => {
  return <div className={classes.spacer} />
}

const HeaderIcon = ({ classes, type, data }) => {
  const { tenantConfig } = useConfig()

  switch (type) {
    case 'directory':
      return (
        <div className={classes.headerIcon}>
          <img
            className={classes.mcdIcon}
            src={tenantConfig.assets.headerIcons.directory}
            alt="Directory Icon"
          />
        </div>
      )
    case 'diary':
      return (
        <div className={classes.headerIcon}>
          <div className={classes.datePillDiary}>
            {data.startDay} {data.startMonth}
          </div>
          <img
            className={classes.mcdIcon}
            src={tenantConfig.assets.headerIcons.diary}
            alt="Diary Icon"
          />
        </div>
      )
    default:
      return <div>{type}</div>
  }
}

const Header = ({ classes, config, data, variant }) => {
  const type = config?.options?.type
  const showSeparator = config?.options?.showSeparator
  const logoClass = variant === 'map' ? classes.logoSmall : classes.logo

  return (
    <>
      <div className={classes.topInfo}>
        {/* {data.type === 'event' && (
          <div>
            <div className={classes.datePill}>{data.startDay} {data.startMonth}</div>
          </div>
        )} */}
        {type !== 'logo' && (
          <HeaderIcon classes={classes} type={type} data={data} />
        )}
        {type === 'logo' && (
          <div>
            {data.logoUrl && (
              <img className={logoClass} src={data.logoUrl} alt="logo" />
            )}
          </div>
        )}
        <div className={classes.distance}>
          {data.distanceLabel && (
            <IconText iconClass="fas fa-running" text={data.distanceLabel} />
          )}
        </div>
      </div>
      {showSeparator && <Separator classes={classes} />}
    </>
  )
}

const Title = ({ classes, config, data, variant }) => {
  const { track } = useAnalytics()

  if (!data) return null

  const { name, organisationName, category: categoryValue } = data
  const { showCategory, showOrganisationName } = config?.options || {}

  const category = showCategory && categoryValue

  const organisation =
    showOrganisationName &&
    !name?.startsWith(organisationName) &&
    organisationName

  if (!(name || category || organisation)) return null

  const handleTitleClick = (e) => {
    e.stopPropagation()
    track(
      variant === 'map'
        ? 'searchResultMapTitleClick'
        : 'searchResultTitleClick',
      {
        category: 'SearchResults',
        label: data.name,
      }
    )
  }

  return (
    <Box className={classes.titleBlock}>
      <CieLink
        className={classes.title}
        type={data.type}
        path={data.url}
        // color="secondary"
        onClick={handleTitleClick}
      >
        {name}
      </CieLink>
      {organisation && (
        <Box className={classes.organisation}>{organisation}</Box>
      )}
      {category && <Box className={classes.category}>{category}</Box>}
    </Box>
  )
}

const Description = ({ classes, data }) => {
  return (
    <Box className={classes.componentBlock}>
      <Typography
        variant="body1"
        element="div"
        dangerouslySetInnerHTML={{ __html: data.description }}
      />
    </Box>
  )
}

const Details = ({ classes, config, data }) => {
  if (!config.fields) return null

  const items = config.fields
    .filter((f) => data[f.key] && data[f.key] !== '--withheld--')
    .map((f) => ({
      icon: f.icon,
      text: data[f.key],
    }))

  return (
    <Box className={classes.componentBlock}>
      {items.map((v, i) => (
        <IconText key={i} iconClass={v.icon} text={v.text} />
      ))}
    </Box>
  )
}

const AddressWithheld = ({ classes, data }) => {
  if (!data.addressWithheld) return null

  return (
    <Box className={classes.componentBlock}>
      <Typography
        variant="body1"
        // element="div"
        // dangerouslySetInnerHTML={{ __html: data.description }}
      >
        The maintainer of this service has elected to withhold the display of
        their location on a map.
      </Typography>
    </Box>
  )
}

const ServiceChanges = ({ classes, config, data }) => {
  if (!data.serviceChanges) return null

  return (
    <div className={classes.componentBlock}>
      <Separator classes={classes} />
      <IconText iconClass="fas fa-bullhorn" text="Service Changes" />
      <Details classes={classes} config={config} data={data.serviceChanges} />
    </div>
  )
}

const HealthEngine = ({ classes, data, ...otherProps }) => {
  if (data.healthEngineStatus !== 'A') return null

  // return <Box>{`${data.healthEngineId}, ${data.healthEngineStatus}`}</Box>

  return (
    <HealthEngineAppointment {...{ data, ...otherProps }} />
    // <Box className={classes.componentBlock}>
    /* <Separator classes={classes} /> */
    // </Box>
  )
}

const NetworkMember = ({ classes, config, data }) => {
  const { tenantConfig: tc } = useConfig()
  tc.assets.allianceMember

  return (
    <Box
      className={classes.componentBlock}
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <NetworkMemberBadge config={config} data={data} />
    </Box>
  )
}

const BadgeListWrapper = (props) => {
  const { classes, ...innerProps } = props
  return <BadgeList {...innerProps} />
}

const componentMap = {
  Separator,
  Spacer,
  Header,
  Title,
  Description,
  Details,
  AddressWithheld,
  ServiceChanges,
  HealthEngine,
  NetworkMember,
  BadgeList: BadgeListWrapper,
}

// Actions
// TODO: Add to config

const Actions = observer(({ classes, data, search }) => {
  const { tenantConfig: tc } = useConfig()

  const { track } = useAnalytics()
  const [confirmDeleteFav, setConfirmDeleteFav] = useState(false)

  const { isFavourite, toggleFavourite } = useFavourite(data)

  // const theme = useTheme()
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const icon = data.id.match(/_fav$/)
    ? 'fas fa-trash'
    : isFavourite
    ? 'fas fa-star'
    : 'far fa-star'

  const handleFavouriteToggle = (e) => {
    e.stopPropagation()

    if (isFavourite) {
      setConfirmDeleteFav(true)
    } else {
      track('searchResultAddFavourite', {
        category: 'SearchResults',
        label: data.name,
      })

      toggleFavourite()
    }
  }

  const handleClose = (e) => {
    e.stopPropagation()
    setConfirmDeleteFav(false)
  }

  const handleConfirm = (e) => {
    track('searchResultDeleteFavourite', {
      category: 'SearchResults',
      label: data.name,
    })

    e.stopPropagation()
    toggleFavourite()
    setConfirmDeleteFav(false)
  }

  if (!tc.search?.searches?.favourites) return null

  return (
    <div className={clsx(classes.componentBlock, classes.actions)}>
      <IconButton onClick={handleFavouriteToggle}>
        <i className={clsx(icon, 'fa-fw')} />
      </IconButton>

      <Dialog
        open={confirmDeleteFav}
        onClose={handleClose}
        // fullScreen={isMobile}
      >
        <DialogTitle>Remove Favourite</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove <strong>{data.name}</strong> from
            your favourites list?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="default"
            variant="contained"
            disableElevation
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            autoFocus
            disableElevation
            onClick={handleConfirm}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
})

// Result

const SearchResult = ({
  // config,
  classes,
  search,
  data,
  variant = 'list',
  selected = false,
  onClickResult = () => undefined,
}) => {
  // for optimising memoized renders

  // const classes = useResultStyles()

  // useCountRenders(data.id)

  // useEffect(() => {
  //   console.log(`effect[${data.id}]`)
  // }, [data, selected, onClickResult, search])

  const { tenantConfig: tc } = useConfig()
  const config = tc.ui?.search?.resultItem?.[data.type]

  if (!config) throw `Config for item type ${data.type} not found`

  const handleResultClick = useCallback(
    (e) => {
      e.stopPropagation()
      onClickResult(data)
    },
    [data, onClickResult]
  )

  const ResultContents = () => (
    <>
      {(config?.components || []).map((c, i) => {
        if (!(c.component in componentMap)) return null
        if (c.hideVariant === variant) return null
        const Component = componentMap[c.component]

        return (
          <Component
            key={i}
            classes={classes}
            config={c}
            search={search}
            data={data}
            variant={variant}
          />
        )
      })}
    </>
  )

  if (variant === 'map') return <ResultContents />

  return (
    <ErrorBoundary>
      <Card
        id={`result-${data.id}`}
        className={clsx(classes.root, selected && classes.selected)}
        onClick={handleResultClick}
      >
        <CardContent sx={{ p: 2 }}>
          {false && <Typography variant="body1">{data.id}</Typography>}

          <ResultContents />

          <Actions classes={classes} {...{ search, data }} />
        </CardContent>
      </Card>
    </ErrorBoundary>
  )
}

SearchResult.displayName = 'AMSSearchResult'

export default React.memo(
  withStyles(styles, { name: 'AMSSearchResult' })(SearchResult)
)
// export default SearchResult
// export default SearchResult2
