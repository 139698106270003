import React from 'react'

import _ from 'lodash'
import clsx from 'clsx'
// import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useAnalytics } from 'use-analytics'

import { makeStyles, withStyles } from '@mui/styles'
import { Button } from '@mui/material'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  // button: {
  //   marginBottom: theme.spacing(0.5),
  // },
  clearButton: {
    // marginTop: theme.spacing(1),
    justifyContent: 'flex-start',
  },
  clearIcon: {
    marginRight: theme.spacing(0.5),
  },
})

const CategoryButton = withStyles(
  () => ({
    root: {
      justifyContent: 'flex-start',
      textAlign: 'left',
      textTransform: 'none',
      fontWeight: 'normal',
    },
  }),
  { name: 'AMSCategoryButton' }
)(Button)

const CategoryButtons = observer(
  ({
    classes,
    selectMode = 'single',
    categories = [],
    selected = selectMode === 'single' ? null : [],
    onChange = () => undefined,
  }) => {
    const { track } = useAnalytics()

    const onClear = () => {
      track('categoriesClearSelected', {
        category: 'Categories',
        label: 'Clear',
      })

      onChange(selectMode === 'single' ? null : [])
    }

    const handleSelectCategory = id => () => {
      track('categoriesCategoryClick', {
        category: 'Categories',
        label: id,
      })

      if (selectMode === 'single') {
        const sc = id === selected ? null : id
        onChange(sc)
      }

      if (selectMode === 'multiple') {
        const oldSel = selected || []
        const newSel = oldSel.includes(id) ? oldSel.filter(c => c !== id) : [...oldSel, id]

        onChange(newSel)
      }
    }

    const isSelected = id => {
      if (selectMode === 'single') {
        return id === selected
      }

      if (selectMode === 'multiple') {
        if (!selected) return false

        return selected.includes(id)
      }
    }

    const isClearEnabled = selectMode === 'single' ? !!selected : selected && selected.length > 0

    return (
      <div className={classes.root}>
        {categories.map((c, i) => (
          <React.Fragment key={i}>
            <CategoryButton
              // key={i}
              className={classes.button}
              disableElevation
              // size="small"
              variant="contained"
              color={isSelected(c.id) ? 'primary' : 'default'}
              fullWidth
              onClick={handleSelectCategory(c.id)}
            >
              {c.title}
            </CategoryButton>
          </React.Fragment>
        ))}

        {isClearEnabled && (
          <Button className={classes.clearButton} onClick={onClear}>
            <i className={clsx('far fa-times fa-fw', classes.clearIcon)} />
            Clear
          </Button>
        )}
      </div>
    )
  }
)

export default withStyles(styles, { name: 'AMSCategoryButtons' })(CategoryButtons)
