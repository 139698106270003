import React, { useEffect } from 'react'

import { withStyles } from '@mui/styles'
import { Box, Typography, Button, Fade } from '@mui/material'

import { useGetHealthEngineNextAppointment } from '@ciss/cie-api-orval'
import { useConfig } from '@/config'
import { Mono, Json } from '@/ui/debug/debug-helper/Mono'
import { values } from 'lodash'
import { Separator } from './SearchResultsList'

const styles = (theme) => ({
  root: {},
})

const BookAppointmentButton = ({ classes, url, ...buttonProps }) => (
  <Button
    classes={{
      root: classes.healthEngineButton,
      label: classes.healthEngineButtonLabel,
    }}
    sx={{ mt: 0 }}
    variant="outlined"
    color="primary"
    href={url}
    target="_blank"
    disableElevation
    {...buttonProps}
  >
    Book Now
  </Button>
)

const NextAppointment = ({
  classes,
  nextAppointment,
  bookingUrl,
  ...buttonProps
}) => (
  <>
    <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
      Next Appointment
    </Typography>
    <Box sx={{ width: '100%', mt: 1, display: 'flex', alignItems: 'center' }}>
      <Box mr={2}>
        <i className="fas fa-calendar" />
      </Box>
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <Typography variant="body1">{nextAppointment}</Typography>
      </Box>
      <Button
        classes={{
          root: classes.healthEngineButton,
          label: classes.healthEngineButtonLabel,
        }}
        sx={{ mt: 0 }}
        variant="outlined"
        color="primary"
        href={bookingUrl}
        target="_blank"
        disableElevation
        {...buttonProps}
      >
        Book Now
      </Button>
    </Box>
  </>
)

const HealthEngineLogo = () => {
  const { tenantConfig: tc } = useConfig()
  return (
    <Box
      sx={{
        mt: 3,
        ml: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="body2" sx={{ mr: 1.5, whiteSpace: 'nowrap' }}>
        Powered by
      </Typography>
      <Box
        component="img"
        sx={{ width: 116, height: 24 }}
        src={tc.assets?.healthengine?.logo}
      />
    </Box>
  )
}

const HealthEngineAppointment = ({
  classes,
  data,
  onQueryChanged = () => undefined,
}) => {
  const { tenantConfig: tc, appConfig } = useConfig()

  const { outletId, serviceId, healthEngineId } = data

  const bookingUrl = `${appConfig.directoryURL}/redirect?action=healthengine&outletId=${outletId}`

  const query = useGetHealthEngineNextAppointment(
    outletId,
    serviceId,
    healthEngineId
  )

  const { data: heresponse, isLoading, isSuccess, isError } = query

  const nextAppointment = heresponse?.success && heresponse?.data

  if (isLoading) return <Mono>Loading</Mono>
  if (isError) return <Mono>Error</Mono>
  // if (isSuccess)
  //   return (
  //     <Mono>
  //       <Json value={heresponse} />
  //     </Mono>
  //   )

  if (isSuccess)
    return (
      <Box sx={{ my: 3, pt: 2, borderTop: '1px solid rgba(0, 0, 0, 0.1)' }}>
        {/* <Box className={classes.separator} /> */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          <NextAppointment {...{ classes, nextAppointment, bookingUrl }} />
          <HealthEngineLogo />
        </Box>
      </Box>
    )
}

export default withStyles(styles, { name: 'AMSHealthEngineAppointment' })(
  HealthEngineAppointment
)
