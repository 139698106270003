import React from 'react'

import { withStyles } from '@mui/styles'
import SimpleMarkdown from '@/ui/markdown/SimpleMarkdown'

import { Card, CardContent } from '@mui/material'

const styles = (theme) => ({
  root: {
    borderRadius: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.03)',

    margin: theme.spacing(1.5, 1.5, 0.75),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1, 1, 0.5),
    },
  },
})

const SearchResultsMarkdown = ({ classes, content = {} }) => {
  if (!content?.body) return null

  return (
    <Card variant="outlined" className={classes.root}>
      <CardContent>
        <SimpleMarkdown>{content.body}</SimpleMarkdown>
      </CardContent>
    </Card>
  )
}

export default withStyles(styles, { name: 'AMSSearchResultsMarkdown' })(
  SearchResultsMarkdown
)
