import React, { createContext, useContext, forwardRef } from 'react'

import _ from 'lodash'
import deepmerge from 'deepmerge'

import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles'

const loadThemes = () => {
  const tt = require(`../../tenants/${process.env.TENANT}/theme`)?.themes
  if (!tt?.base) return {}

  const base = createTheme(tt.base)
  const variants = _.mapValues(tt.variants || {}, (t) =>
    responsiveFontSizes(createTheme(deepmerge(tt.base, t)))
  )

  return { base, variants }
}

const themesContext = createContext(loadThemes())

export const useTenantThemes = () => useContext(themesContext)

export const TenantThemeProvider = ({ theme, children }) => {
  const tenantThemes = useTenantThemes()
  const tt = _.get(tenantThemes, theme)

  if (!children) return null
  if (!tt) {
    // console.warn(`Theme ${theme} not defined`)
    return <>{children}</>
  }

  return <ThemeProvider theme={tt}>{children}</ThemeProvider>
}

export const withTenantTheme = (theme) => (Component) =>
  forwardRef(function WithTenantTheme(props, ref) {
    return (
      <TenantThemeProvider theme={theme}>
        <Component ref={ref} {...props} />
      </TenantThemeProvider>
    )
  })
