import React from 'react'
import _ from 'lodash'

import { Box } from '@mui/material'

/**
 * Builds a delimited english list
 *
 * Use inside Typography
 */
export const EmphasisEnglishList = (props) => {
  const {
    component = 'span',
    items = [],
    delimiter = ', ',
    terminator = ' and ',
    boldItems = true,
    useTerminator = true,
    maxItems = 3,
    remainderText = (rem) => rem > 0 && `${rem} other${rem > 1 ? 's' : ''}`,
    ...innerProps
  } = props
  if (!_.isArray(items) || !items.length) return null

  const kept = maxItems < 0 ? items : _.take(items, maxItems)
  const remainder = remainderText?.(items.length - kept.length)

  const innerHTML = _.compact([...kept, remainder])
    .reduce((acc, item, index, arr) => {
      const delim =
        index === 0
          ? ''
          : index === arr.length - 1 && useTerminator
          ? terminator
          : delimiter

      return [...acc, delim, boldItems ? `<strong>${item}</strong>` : item]
    }, [])
    .join('')

  const typographyProps = {
    component,
    dangerouslySetInnerHTML: { __html: innerHTML },
    ...innerProps,
  }

  return <Box {...typographyProps} />
}
