import { types } from 'mobx-state-tree'
import { toLocalityL } from '@/api/legacy'

export const Locality = types
  .model({
    type: types.literal('locality'),
    id: 0,
    default: false,
    label: '',
    councilId: 0,
    state: '',
    postcode: '',
    lat: 0.0,
    lng: 0.0,
    url: '',
    councilUrl: '',
    stateUrl: '',
  })

  .views((self) => ({
    get geometry() {
      return {
        lat: self.lat,
        lng: self.lng,
      }
    },

    get name() {
      return self.url.replace(/_/g, ' ')
    },

    get stateLong() {
      return self.stateUrl.replace(/_/g, ' ')
    },

    get summary() {
      return `${this.name}, ${this.stateLong}`
    },

    get summaryArray() {
      return [this.name, this.stateLong]
    },

    get asLocality() {
      return self && toLocalityL(self)
    },
  }))
