// import isEqual from 'lodash/isEqual'

import _ from 'lodash'
import { toJS, isObservableArray } from 'mobx'
import { toJSDeep } from '../utils/mobx'
import { types, getRoot, getParent } from 'mobx-state-tree'

import { Locality } from './Locality'
import { Geography } from './Geography'

import { config, useConfig } from '@/config'
import { analytics } from '@/analytics'
import { toLocationL } from '@/api/legacy'

const { tenantConfig: tc } = config

const DateRange = types.model({
  // -2 custom, -1 anytime, 0+ days
  delta: -1,
  start: types.maybeNull(types.Date),
  end: types.maybeNull(types.Date),
})

export const SearchParams = types
  .model({
    pathway: 'self',
    query: '',
    radius: 75,
    location: types.maybeNull(types.union(Locality, Geography)),
    dateRange: types.optional(DateRange, {}),
    categories: types.array(types.string),
    selectedCategories: types.union(
      types.array(types.string),
      types.maybeNull(types.string)
    ),
    age: types.maybeNull(types.string),
    gender: types.maybeNull(types.string),
    sexuality: types.maybeNull(types.string),
    atsi: types.maybeNull(types.string),
  })

  .views((self) => ({
    isPreferredCategory(id) {
      return self.categories.includes(id)
    },

    get tenant() {
      return getRoot(self).tenant
    },

    get ui() {
      return getParent(self).ui
    },

    get visibleCategories() {
      if (!self.ui.showAllCategories && self.categories.length > 0) {
        return self.tenant.categories
          .filter((c) => c.show !== 'never')
          .filter((c) => c.show === 'always' || self.isPreferredCategory(c.id))
      }

      return self.tenant.categories
        .filter(
          (c) => !(c.show === 'preferred' && !self.isPreferredCategory(c.id))
        )
        .filter((c) => c.show !== 'never')
    },

    get selectedCategoriesMeta() {
      if (typeof self.selectedCategories === 'string') {
        const sc = self.tenant.metaCategoryMap[self.selectedCategories]
        return [sc]
      }

      if (isObservableArray(self.selectedCategories)) {
        const sc = self.selectedCategories.map(
          (sc) => getRoot(self).tenant.metaCategoryMap[sc]
        )
        return sc
      }

      return []
    },

    get routePathSource() {
      return {
        locality: self.location,
        categories: self.selectedCategoriesMeta,
      }
    },

    get isSearchCategorySelected() {
      return (
        self.selectedCategoriesMeta.length === 0 ||
        self.selectedCategoriesMeta.some((c) => c.search)
      )
    },

    isLocationParamsSelected(params) {
      // // no location
      // if (!params.locality && !self.location) return true

      // // restore location
      // if (!params.locality && self.location) return false

      // change location
      return (
        params?.state === self.location?.stateUrl &&
        params?.council === self.location?.councilUrl &&
        params?.locality === self.location?.url
      )
    },

    get urlParamsSource() {
      return {
        location: self.location,
        category: self.selectedCategoriesMeta,
      }
    },
  }))

  .actions((self) => ({
    set(key, value) {
      if (key === 'location') {
        self.setLocation(value)
        return
      }

      if (key in self) {
        self[key] = value
      }
    },

    setQuery(query) {
      self.query = query
    },
    setRadius(radius) {
      self.radius = radius
    },
    setDefaultLocation(location) {
      self.defaultLocation = location
    },
    setLocation(location) {
      self.location = location
    },

    setSelectedCategories(selected) {
      const s = toJS(selected)
      const mcm = getRoot(self).tenant.metaCategoryMap

      if (typeof s === 'string') {
        self.selectedCategories = s in mcm ? s : null
      } else if (Array.isArray(s)) {
        const sf = s.filter((s) => s in mcm)
        self.selectedCategories = sf.length > 0 ? sf : null
      } else {
        self.selectedCategories = null
      }
    },

    initLocation() {
      const { defaultLocation = null, initLocation = 'dialog' } =
        tc.search?.location

      if (self.location?.id === 0) {
        console.warn('Bad location, resetting')
        self.location = null
      }

      if (self.location) return

      if (initLocation === 'default' && defaultLocation && !self.location)
        self.location = defaultLocation
    },

    updateFromRouterHandler({ data }) {
      // console.log('updateFromRouterHandler', { data })

      const prepareLocation = (loc) => {
        if (!loc) return loc
        const sloc = toLocationL(loc)
        return sloc
      }

      const { locality, categories = [] } = data || {}

      self.location = prepareLocation(locality)
      // self.location = locality && locality.localityId ? toLocationL(location) : null

      self.setSelectedCategories((categories || []).map((c) => c.id))
    },

    updateFromSurvey(survey) {
      self.selectedCategories = null
      self.query = ''
      self.showAllCategories = false

      // console.log(toJSDeep(survey))

      _.keys(survey).forEach((k) => {
        if (_.has(self, k)) {
          self[k] = toJS(survey[k])
        }
      })

      // autoselect one category

      if (
        !self.selectedCategories &&
        tc.search?.survey?.autoSelectSingleCategory &&
        self.categories?.length === 1
      ) {
        const cid = self.categories[0]
        self.selectedCategories =
          tc.options.categoryMode === 'single' ? cid : [cid]
      }

      analytics.track('surveyImplUpdateParamsFromSurvey', {
        category: 'Survey',
        label: 'Update Params',
        // params
      })
    },
  }))
