import get from 'lodash/get'

import { config } from '@/config'

const { appConfig } = config

export const resolveImage = (image, assets) => {
  if (typeof image !== 'string') return undefined

  // bundle asset
  const asset = get(assets, image)
  if (asset)
    return {
      type: 'bundle',
      icon: asset,
    }

  // font-awesome icon
  if (image.match(/^fa[a-z]?\s/))
    return {
      type: 'fa',
      icon: image,
    }

  // cdn

  const cdnMatch = image.match(/^cdn:([\w\d/-_ ]+\.(png|svg))$/)
  if (cdnMatch && cdnMatch.length > 1)
    return {
      type: 'cdn',
      icon: `${appConfig.cdnURL}/${cdnMatch[1]}`,
    }
}
