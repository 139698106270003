import React, { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'

// import { inspect } from '@xstate/inspect'
// inspect({
//   url: 'https://statecharts.io/inspect',
//   iframe: false,
// })

import { toJS } from 'mobx'
import { toJSDeep } from '@/stores/root/utils/mobx'
import { observer } from 'mobx-react-lite'

import { useHistory, useRouteMatch, useLocation } from 'react-router-dom'
import { useInterpret, useActor } from '@xstate/react'
import { useQueryClient } from 'react-query'

import { searchRouteMachine } from '@/stores/route/search-route-machine'
import {
  cacheQueryResults,
  querySearchUrlParams,
} from '@/stores/route/parse-url-query'
import { getRouterInfo, fromQueryString } from '@/stores/route/path-utils'
import { getUrlHash } from '@/stores/root/utils/hash'

import { useConfig } from '@/config'
import { useRootStore } from '@/stores/root/store'
import { useRouteStore } from '@/stores'
import { debugStore, useDebugStore } from '@/stores'

import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { Mono, MonoFa, Json } from '@/ui/debug/debug-helper/Mono'

import { DebugStore } from '@/stores'

const Links = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 12px;
`

const contextKeys = ['current', 'router', 'store', 'redirect']
const dataKeys = [
  'browserUrl',
  'path',
  'url',
  'params',
  'query',
  'queryParams',
  'hash',
]

const getUrlInfo = ({ context }) => {
  return contextKeys.map((ck) =>
    _.fromPairs(dataKeys.map((dk) => [dk, context[ck]?.[dk]]))
  )
}

const useDebugLogging = ({ service, enabled = false }) => {
  const [debugData, setDebugData] = useState({})

  useEffect(() => {
    if (!enabled) return
    service.onTransition((state) => {
      const urlInfo = getUrlInfo(state)

      const dd = {
        ts: new Date().toISOString,
        urlInfo,
      }

      setDebugData(() => dd)
    })

    //return sub.unsubscribe()
  }, [service])

  return debugData
}

const useConsoleLogging = ({ service, enabled = false }) => {
  useEffect(() => {
    if (!enabled) return
    if (!service) return

    service
      ?.onTransition((state) => {
        if (!enabled) return
        const ststr = state.toStrings().join(', ')
        console.log(`%c% ${ststr}`, 'color: #e74;')
        const { context } = state
        console.log({ context: toJSDeep(context) })
      })
      .onEvent(({ type, ...payload }) => {
        if (!enabled) return
        console.log(`%c @${type}`, 'color: #59c;')
        console.log({ payload: toJSDeep(payload) })
      })

    // const usc = service.subscribe(state => {
    //   console.log(state)
    // })
  }, [service])
}

const useRouterHash = () => {
  const match = useRouteMatch()
  const location = useLocation()

  const [routerState, setRouterState] = useState(null)

  const rinfo = getRouterInfo({ match, location })
  // console.log('rinfo', rinfo.url, rinfo.query, rinfo.hash)

  useEffect(() => {
    setRouterState(rinfo)
  }, [rinfo.hash])

  return routerState
}

const SearchRouteHandler = observer(() => {
  const { appConfig, tenantConfig: tc } = useConfig()
  const searchRouteHandlerConfig = tc?.routeOptions?.searchRouteHandler || {}

  const routerInfo = useRouterHash()
  const history = useHistory()

  const queryClient = useQueryClient()

  const store = useRootStore()
  const route = useRouteStore()
  const debug = useDebugStore()

  const config = route.config.searchRouteHandler || {}
  const defaultUrl = config.defaultRoute?.path || '/'

  const storeInfo = route.path.searchStoreInfo || {}

  const service = useInterpret(searchRouteMachine, {
    context: {
      defaultUrl,
    },
    actions: {
      resetLocation: () => {
        store.search.params.setLocation(null)
        store.search.ui.setLocationDialogShown(false)
        return send('LOCATION_RESET')
      },
      redirect: ({ redirect }) => {
        if (!redirect) return
        // console.log(`redirect -> ${redirect.browserUrl}`)
        history.push(redirect.browserUrl)
      },
      cacheQueryResultsFromStore: ({ store }) => {
        const { parameterMap, params, source } = store
        const storeParamInfo = route.path.getQueryParamInfo({
          parameterMap,
          params,
        })
        cacheQueryResults({ storeParamInfo, params, source, queryClient })
      },
      writeQueryResultToStore: (c, { data = {} }) =>
        store.search.params.updateFromRouterHandler(data),
    },
    services: {
      queryRouteParams: async ({ router, store }) => {
        const parameterMap = toJSDeep(route.config?.parameterMaps?.search)

        const [routerParamInfo, storeParamInfo] = [router, store].map(
          ({ params }) => route.path.getQueryParamInfo({ parameterMap, params })
        )

        return await querySearchUrlParams({
          parameterMap,
          routerParamInfo,
          storeParamInfo,
          router,
          store,
          queryClient,
        })
      },
    },
  })

  const [state, send] = useActor(service)

  const enableConsoleLogging = toJS(debug.routeMachine.consoleLogging)
  useConsoleLogging({ service, enabled: enableConsoleLogging })

  const debugData = useDebugLogging({
    service,
    enabled: appConfig.debugHelpers,
  })

  useEffect(() => {
    const { urlInfo } = debugData || {}
    debug.routeMachine.setUrlInfo(urlInfo)
  }, [debugData, debugData?.ts])

  useEffect(() => {
    window.$srm = { state, send, service }
  }, [service])

  useEffect(() => {
    const { hash, url, query } = routerInfo || {}
    // console.log('routerState.hash', hash, url, query)
    send({ type: 'SET_ROUTER_INFO', urlInfo: routerInfo })
  }, [routerInfo?.hash])

  useEffect(() => {
    // console.log('storeInfo.hash', { urlInfo: toJSDeep(storeInfo) })
    if (!storeInfo) return
    send({ type: 'SET_STORE_INFO', urlInfo: toJSDeep(storeInfo) })
  }, [storeInfo?.hash])

  // return <></>
  return null
})

export default SearchRouteHandler
