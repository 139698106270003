import { grey, red, amber } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'
import { none } from 'ramda'

const colors = {
  primary: '#7e63ab',
  secondary: '#7e63ab',
  // red: '#ca2b2b',
  red: '#c44040',
  greyTextButton: 'rgba(0, 0, 0, 0.54)',
  text: {
    primary: grey[700],
  },
}

const dimensions = {
  parameters: 380,
  results: 450,
}

const breakpoints = {
  values: {
    xs: 0,
    vs: 400,
    sm: 600,
    ph: 740,
    md: 900,
    lg: 1200,
    tb: 1350,
    xl: 1536,
  },
}

const theme = createTheme({
  breakpoints,
})

export const themes = {
  base: {
    palette: {
      primary: {
        main: colors.primary,
        contrastText: 'white',
      },
      secondary: {
        main: colors.secondary,
        contrastText: 'white',
      },
      default: {
        // main: grey[300],
        main: '#eee',
        light: grey[200],
        dark: grey[400],
      },
      grey: {
        main: grey[700],
        light: grey[400],
        dark: grey[600],
      },
      greyTextButton: {
        main: colors.greyTextButton,
      },
      covidAlert: {
        main: colors.red,
        contrastText: 'white',
      },
      healthEngineBadge: {
        // main: grey[100],
        // main: '#01a4b7',
        main: '#316a85',
        contrastText: 'white',
      },
      background: {
        default: 'white',
        paper: 'white',
        sponsors: 'white',
        footer: grey[200],
        footerAcknowledgement: grey[300],
      },
      text: {
        primary: colors.text.primary,
      },
      landing: {
        // main: '#6252a7',
        paper: grey[100],
      },
    },
    breakpoints,
    typography: {
      fontSize: 14,
      fontFamily: [
        'Inter',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(', '),
    },
    components: {
      MuiCssBaseline: {
        '@global': {},
      },

      MuiTypography: {
        variants: [
          {
            props: { variant: 'h4', subvariant: 'landing' },
            style: {
              fontSize: '1.5rem',
              [theme.breakpoints.up('ph')]: {
                fontSize: '1.8rem',
              },
            },
          },
          {
            props: { variant: 'h5', subvariant: 'landing' },
            style: {
              fontSize: '1.3rem',
              [theme.breakpoints.up('ph')]: {
                fontSize: '1.6rem',
              },
            },
          },
          {
            props: { variant: 'body1', subvariant: 'landing' },
            style: {
              fontWeight: 300,
              fontSize: '1.125rem',
              [theme.breakpoints.up('ph')]: {
                fontSize: '1.3rem',
              },
            },
          },
          {
            props: { variant: 'body2', subvariant: 'landing' },
            style: {
              fontSize: '1rem',
            },
          },
        ],
      },

      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
          iconSizeSmall: {
            '& > i:nth-of-type(1)': {
              fontSize: '0.8rem',
              marginBotton: -2,
              padding: 2,
            },
          },
          iconSizeMedium: {
            '& > i:nth-of-type(1)': {
              fontSize: '1rem',
            },
          },
          iconSizeLarge: {
            '& > i:nth-of-type(1)': {
              fontSize: '1rem',
            },
          },
        },
      },

      AMSButton: {
        variants: [
          {
            props: { size: 'extraLarge' },
            style: {
              fontSize: '1.1rem',
              padding: theme.spacing(1, 3.5),
            },
          },
        ],
      },

      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
            },
          },
          input: {
            '&::-ms-clear': {
              display: 'none',
            },
            padding: theme.spacing(1.25),
          },
        },
      },

      MuiInput: {
        styleOverrides: {
          input: {
            '&::-ms-clear': {
              display: 'none',
            },
          },
        },
      },

      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: 42,
          },
        },
      },

      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontSize: '0.92rem',
            padding: theme.spacing(0, 0),
            minHeight: 46,
            [theme.breakpoints.up('sm')]: {
              minWidth: 140,
            },
          },
        },
      },

      // AppBar

      AMSAppBar: {
        props: {
          elevation: 0,
          position: 'static',
        },
        styleOverrides: {
          root: {
            backgroundColor: 'white',
            color: colors.text.primary,
            boxShadow: '0 0px 6px 0px rgba(0, 0, 0, 0.3)',
            flexShrink: 1,
          },
          row: {
            [theme.breakpoints.down('sm')]: {
              padding: theme.spacing(0, 1),
            },
          },
        },
      },

      AMSAppBarGroup: {
        styleOverrides: {
          root: {
            padding: theme.spacing(0.5, 1),
            [theme.breakpoints.down('sm')]: {
              padding: theme.spacing(0.5),
            },
          },
          left: {
            [theme.breakpoints.down('sm')]: {
              flex: '1 0',
            },
          },
          fill: {
            [theme.breakpoints.down('sm')]: {
              display: 'none',
            },
          },
          right: {
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
          },
        },
      },

      AMSMenuButton: {
        styleOverrides: {
          root: {
            [theme.breakpoints.down('sm')]: {
              fontSize: '0.8rem',
            },
          },
        },
      },

      AMSAppBarTitle: {
        styleOverrides: {
          logo: {
            width: 256,
            height: 32,
            [theme.breakpoints.down('sm')]: {
              width: 226,
              height: 28,
            },
          },
        },
      },

      // landing

      AMSLandingBlock: {
        variants: [
          // {
          //   props: { variant: 'landingBody1' },
          //   style: {
          //     fontSize: '1.4rem',
          //   },
          // },
        ],
      },

      // desktop search

      AMSDesktopSearch: {
        styleOverrides: {
          parameterDrawer: {
            minWidth: dimensions.parameters,
            maxWidth: dimensions.parameters,
          },
          parameters: {
            width: dimensions.parameters,
          },
          results: {
            width: dimensions.results,
            [theme.breakpoints.between('ph', 'tb')]: {
              minWidth: `min(calc(100vw / 2), ${dimensions.results})`,
              // width: 'calc(100vw / 2)',
              // minWidth: 'calc(100vw / 2)',
            },
          },
        },
      },

      // AMSDesktopSearchToolbar: {
      //   toolbarButton: {
      //     fontSize: '0.92rem',
      //   },
      // },
    },
  },

  variants: {
    appBar: {
      components: {
        AMSSponsors: {
          styleOverrides: {
            root: {
              height: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              margin: theme.spacing(0, 2),
              // [theme.breakpoints.down('tb')]: {
              //   marginRight: 0,
              // },
            },
            label: {
              padding: theme.spacing(0.5, 0),
              marginRight: theme.spacing(2),
              color: grey[600],
              fontSize: '0.86rem',
              fontWeight: 'light',
              whiteSpace: 'nowrap',
              '@media (max-width: 830px)': {
                // maxHeight:
                display: 'none',
              },
            },
            imageList: {
              height: '100%',
            },
            imageContainer: {
              // height: '100%',
              maxHeight: 36,
              maxWidth: 500,
              [theme.breakpoints.down('lg')]: {
                // maxHeight:
              },
            },
          },
        },
      },
    },

    searchResultsList: {
      components: {
        AMSSearchResultsList: {
          styleOverrides: {
            statusSponsorBlock: {
              [theme.breakpoints.up('ph')]: {
                display: 'none',
              },
            },
            footerBlock: {
              [theme.breakpoints.up('ph')]: {
                display: 'none',
              },
            },
          },
        },

        AMSAcknowledgement: {
          styleOverrides: {
            root: {
              margin: theme.spacing(1),
              padding: theme.spacing(1),
              color: '#888',
              justifyContent: 'center',
            },
            flags: {},
            flag: {
              maxHeight: 20,
            },
            text: {
              fontSize: 13,
              maxWidth: 400,
            },
          },
        },
      },
    },

    searchResultsHHS: {
      components: {
        AMSSponsors: {
          styleOverrides: {
            root: {
              padding: theme.spacing(1, 2, 4),
            },
            label: {
              color: '#888',
              fontSize: '0.86rem',
            },
            imageList: {
              justifyContent: 'flex-start',
              gap: 0,
            },
            imageContainer: {
              maxHeight: 45,
              maxWidth: 500,
              [theme.breakpoints.down('sm')]: {
                maxWidth: '98%',
                '& img': {
                  maxWidth: '100%',
                },
              },
            },
          },
        },
      },
    },

    searchResultsFooter: {
      components: {
        AMSSponsors: {
          styleOverrides: {
            root: {
              padding: theme.spacing(0, 1),
              [theme.breakpoints.down('sm')]: {
                marginBottom: theme.spacing(6),
              },
            },
            label: {
              color: '#888',
              fontSize: '0.86rem',
            },
            imageList: {
              padding: theme.spacing(2, 0),
              justifyContent: 'space-evenly',
              gap: 20,
            },
            imageContainer: {
              maxHeight: 40,
              maxWidth: 200,
            },
          },
        },
      },
    },

    mapFooter: {
      components: {
        AMSMapFooter: {
          styleOverrides: {
            root: {
              borderTop: '1px solid rgba(0, 0, 0, 0.12)',
              alignItems: 'stretch',
              [theme.breakpoints.down('ph')]: {
                display: 'none',
              },

              [theme.breakpoints.between('ph', 'tb')]: {
                maxWidth: `max(calc(100vw - ${dimensions.results}px), 50vw)`,
                // maxWidth: `calc(100vw - ${dimensions.results})`,
                // maxWidth: 450,
              },
            },
            section: {
              padding: theme.spacing(1, 2),
            },
          },
        },

        AMSHHSOutlets: {
          styleOverrides: {
            // item: {
            //   fontSize: 10,
            //   padding: '2px',
            // },
          },
        },

        AMSSponsors: {
          styleOverrides: {
            root: {},
            label: {
              color: '#666',
              fontSize: '0.86rem',
            },
            imageList: {
              justifyContent: 'center',
              // gap: 28,
            },
            imageContainer: {
              maxHeight: 40,
              maxWidth: 200,
              [theme.breakpoints.down('tb')]: {
                // maxHeight: 35,
              },
              [theme.breakpoints.down('md')]: {
                maxHeight: 35,
              },
            },
          },
        },

        AMSAcknowledgement: {
          styleOverrides: {
            root: {
              color: '#666',
              justifyContent: 'center',
              padding: theme.spacing(1, 2),
            },
            flag: {
              maxHeight: 20,
            },
            text: {
              fontSize: 13,
              maxWidth: 400,
            },
          },
        },
      },
    },

    footer: {
      styleOverrides: {},
    },

    footerSponsors: {
      components: {
        AMSFooterSection: {
          styleOverrides: {
            root: {
              backgroundColor: 'white',
              borderTop: '1px solid rgba(0, 0, 0, 0.12)',
            },
            block: {
              padding: theme.spacing(0, 2, 2),
            },
          },
        },

        AMSSponsors: {
          styleOverrides: {
            label: {
              padding: theme.spacing(0),
              marginBottom: theme.spacing(2),
            },
            imageList: {
              justifyContent: 'center',
              gap: 30,
            },
            imageContainer: {
              maxHeight: 60,
              [theme.breakpoints.down('lg')]: {
                maxHeight: 50,
              },
              [theme.breakpoints.down('sm')]: {
                maxHeight: 40,
              },
            },
          },
        },
      },
    },

    footerAtsi: {
      components: {
        AMSFooterSection: {
          styleOverrides: {
            root: {
              backgroundColor: '#ccc',
            },
          },
        },

        AMSAcknowledgement: {
          styleOverrides: {
            root: {
              alignItems: 'center',
              // justifyContent: 'center',
              [theme.breakpoints.down('sm')]: {
                alignItems: 'flex-start',
                flexDirection: 'column',
                // alignItems: 'flex-start',
              },
            },
            text: {
              fontSize: 14,
              color: '#333',
            },
            flag: {
              maxHeight: 25,
              [theme.breakpoints.down('md')]: {
                maxHeight: 20,
              },
            },
          },
        },
      },
    },
  },
}

// export default theme
