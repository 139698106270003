import React from 'react'

import { Box, List, ListItem, ListItemText } from '@mui/material'

import { useConfig } from '@/config'

const ItemText = (props) => {
  const { body } = props || {}
  const lines = body?.split('\n').map((l) => l.trim())

  return (
    <>
      {lines?.map((line, i) => (
        <Box key={i}>{line}</Box>
      ))}
    </>
  )
}

const LandingPoints = (props) => {
  const assets = useConfig()?.tenantConfig?.assets?.landing

  const {
    image = assets.testimonial1,
    alignImage = 'left',
    items,
  } = props || {}

  return (
    <Box
      sx={{
        margin: '0 auto',
        width: 1,
        maxWidth: 800,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: {
          xs: 'column',
          ph: alignImage === 'right' ? 'row-reverse' : 'row',
        },
        gap: { xs: 3, ph: 6 },
      }}
    >
      <Box
        sx={{
          flex: '0 1 200px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          as="img"
          src={image}
          sx={{
            // maxWidth: 200,
            objectFit: 'contain',
            width: 300,
            height: 1,
            borderRadius: 1,
          }}
        />
      </Box>
      <Box
        sx={{
          flex: '1 1 auto',
        }}
      >
        <List>
          {items?.map((item, i) => (
            <ListItem key={i}>
              <Box as="img" src={assets.arrow} sx={{ mr: 2 }} />
              <ListItemText
                primary={<ItemText body={item} />}
                primaryTypographyProps={{ noWrap: false }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  )
}

export default LandingPoints
