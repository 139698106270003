import { toJS, isObservable, isObservableArray, isObservableMap, isOb, isObservableObject } from 'mobx'

import _ from 'lodash'

export const toJSDeep = (obj) => {

  const getNode = (node) => {
    //if (isObservable(node)) return getNode(toJS(node))
    if (isObservableArray(node)) return toJS(node).map(getNode)
    if (isObservableObject(node)) return _.mapValues(toJS(node), getNode)

    if (_.isArray(node)) return node.map(getNode)
    if (_.isObject(node)) return _.mapValues(node, getNode)

    return node
  }

  const r = getNode(obj)
  return r
}
