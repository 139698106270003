import React from 'react'
import _ from 'lodash'

import { useConfig } from '@/config'

const ImageAsset = ({ srcId, ...props }) => {
  const { tenantConfig } = useConfig()

  const src = _.get(tenantConfig.assets, srcId)
  // const src = srcId in tenantConfig.assets ? tenantConfig.assets[srcId] : null
  return src ? <img src={src} {...props} alt={srcId} /> : null
}

export default ImageAsset
