import React from 'react'

import { useTheme } from '@mui/material/styles'
import { withStyles } from '@mui/styles'
import { Box, Typography, Link } from '@mui/material'

import { useConfig } from '@/config'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(4, 2),
  },
  logo: {
    maxWidth: 'min(300px, 100%)',
    opacity: 0.76,
    marginBottom: theme.spacing(1),
  },
  body1: {
    // fontSize: 'inherit',
    fontSize: '0.86rem',
    // fontSize: 14,
  },
})

/**
 * Displays Cie detals for footer
 */
const CieBlock = ({ classes }) => {
  const { tenantConfig: tc } = useConfig()
  return (
    <div className={classes.cie}>
      <Box as="img" className={classes.logo} src={tc.assets.cie.logo} />
      <Typography className={classes.body1} variant="body1">
        Powered by the{' '}
        <Link
          href="https://www.communityinformationexchange.com.au/"
          target="_blank"
          underline="none"
          sx={{ whiteSpace: 'nowrap' }}
        >
          Community Information Exchange
        </Link>
        <br />© {new Date().getFullYear()} Community Information Support
        Services
        <br />
        All rights reserved. ABN: 30 143 384 043
      </Typography>
    </div>
  )
}

export default withStyles(styles, { name: 'AMSCieBlock' })(CieBlock)
