import React from 'react'
import { makeStyles } from '@mui/styles'
import { Typography, Box } from '@mui/material'

const LandingTestimonials = props => {
  const { title, body } = props || {}

  const paragraphs = body?.split('\n').map(l => l.trim())

  return (
    <Box
      sx={{
        backgroundColor: 'landing.paper',
        p: { xs: 2, sm: 4 },
      }}
    >
      <Box
        sx={{
          margin: '0 auto',
          maxWidth: { xs: 1, md: 800 },
          textAlign: { md: 'center' },
        }}
      >
        {title && (
          <Typography
            variant="h4"
            subvariant="landing"
            // fontWeight="medium"
            color="primary"
            gutterBottom
          >
            {title}
          </Typography>
        )}

        {paragraphs?.map((text, i) => (
          <Typography key={i} variant="body1" subvariant="landing" gutterBottom>
            {text}
          </Typography>
        ))}
      </Box>
    </Box>
  )
}

export default LandingTestimonials
