import { SearchResult } from './types'
import { atom, useAtom } from 'jotai'
import { useAtomValue, useUpdateAtom, atomWithStorage } from 'jotai/utils'
import _ from 'lodash'
import { removeAt } from '@/utils/array'

import { config } from '@/config'

/**
 * Atom for storing favourites
 *
 * @category Atoms
 */
export const favouritesAtom = atomWithStorage<SearchResult[]>(
  `ams-${config.appConfig.tenant}-favourites`,
  []
)

/**
 * Readonly Atom used for quick lookup of favourites status by a Search id
 *
 * @category Atoms
 */
export const favouritesAtomLookup = atom<Record<string, number>>((get) =>
  _.fromPairs(get(favouritesAtom).map((f, i) => [f.id, i]))
)

/**
 * Gets an interface to favourites state for a given search result
 *
 * Uses {@link favouritesAtomLookup} to cache
 *
 * @category Hooks
 */
export const useFavourite = (result: SearchResult) => {
  const updateFavourites = useUpdateAtom(favouritesAtom)
  const lookup = useAtomValue(favouritesAtomLookup)

  // const index = _.has(lookup, result.id) ? lookup[result.id] || -1
  // const isFavourite = index >= 0

  const isFavourite = _.has(lookup, result.id)

  // console.log(result.id, isFavourite, lookup)

  const toggleFavourite = () =>
    updateFavourites((prev) => {
      // add
      if (!isFavourite) return [result, ...prev]

      // remove
      return removeAt(prev, lookup[result.id])
    })

  return {
    /**
     * ????????
     */
    isFavourite,
    toggleFavourite,
  }
}

/**
 * Gets the complete list of favourites
 *
 * Uses {@link favouritesAtom}
 *
 * @category Hooks
 */
export const useFavourites = () => useAtomValue(favouritesAtom)
