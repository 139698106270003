import React, { useState, useEffect } from 'react'

import clsx from 'clsx'
import { cloneDeep, merge } from 'lodash'

import { makeStyles } from '@mui/styles'

import { resolveImage } from '@/ui/utils/asset-utils'
import { useConfig } from '@/config'
import { useSearchResults } from '@/api/search-query'
import { useMergeOptions } from '@/ui/hooks/useMergeOptions'

const maskedParams = (r, mask) => {
  const rv = typeof r === 'number' ? `${r}px` : r
  return mask.map((m) => (m ? rv : 0)).join(' ')
}

// style based on wapha cs type badges

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: null,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    padding: 0,
    margin: theme.spacing(0.7, 0),
    cursor: 'default',
  },
  inactive: ({ inactiveBadges }) => {
    switch (inactiveBadges) {
      case 'hidden':
        return {
          display: 'none !important',
        }
      case 'dim':
        return {
          opacity: 0.3,
        }
      default:
        return null
    }
  },
  iconContainer: ({ style }) => ({
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: style?.backgroundColor,
    borderColor: style?.backgroundColor,
    borderRadius: maskedParams(style?.borderRadius || 4, [1, 0, 0, 1]),
  }),
  labelContainer: ({ style }) => ({
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 40,
    borderWidth: maskedParams(style.borderWidth || 2, [1, 1, 1, 0]),
    borderColor: style?.backgroundColor,
    borderStyle: 'solid',
    borderRadius: maskedParams(style?.borderRadius || 4, [0, 1, 1, 0]),
  }),
  faIcon: {
    marginRight: theme.spacing(1),
  },
  bundleIcon: {
    margin: theme.spacing(0.3, 1),
  },
  label: ({ style }) => ({
    color: style?.backgroundColor,
    fontSize: '1rem',
    fontWeight: 800,
    margin: theme.spacing(0, 1.5),
    maxWidth: '225px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
}))

const defaultBadgeOptions = {
  label: '',
  icon: '',
  active: true,
  // theme: 'wapha',
  inactiveBadges: 'inactive',
  styles: {
    default: {
      borderRadius: 4,
      color: '#fff',
      backgroundColor: '#666',
    },
    inactive: {
      backgroundColor: '#999',
    },
  },
}

const useBadgeOptions = (props) => {
  const configureOptions = () => {
    const om = merge(cloneDeep(defaultBadgeOptions), props)

    const { active, inactiveBadges, styles } = om

    const style =
      !active && inactiveBadges === 'inactive' && styles.inactive
        ? { ...styles.default, ...styles.inactive }
        : styles.default

    return { ...om, style }
  }

  const [opts, setOpts] = useState(configureOptions())

  useEffect(() => {
    setOpts(configureOptions())
  }, [props.active])

  return opts
}

const Badge = (props) => {
  const { tenantConfig } = useConfig()
  const opts = useBadgeOptions(props)
  const classes = useStyles(opts)

  const { active, label, icon: iconRsrc } = opts
  const icon = resolveImage(iconRsrc, tenantConfig.assets.badges)

  const Icon = ({ type, icon }) => {
    if (type === 'fa')
      return (
        <div className={classes.iconContainer}>
          <i className={clsx(icon, classes.faIcon)} />
        </div>
      )

    if (['bundle', 'cdn'].includes(type))
      return (
        <div className={classes.iconContainer}>
          {opts.extraIcon ? (
            <img
              className={classes.bundleIcon}
              height={30}
              src={opts.extraIcon}
            />
          ) : (
            <img className={classes.bundleIcon} height={30} src={icon} />
          )}
        </div>
      )

    return null
  }
  return (
    <div className={clsx(classes.root, !active && classes.inactive)}>
      <Icon {...icon} />
      <div className={classes.labelContainer}>
        <span className={classes.label}>{label}</span>
      </div>
    </div>
  )
}

export default Badge
