import axios from 'axios'
// import { appConfig } from '@/config'
import { config } from '@/config'
import _ from 'lodash'
import humps from 'lodash-humps'
import keyBy from 'lodash/keyBy'
import moment from 'moment'
import pLimit from 'p-limit'
import Qs from 'qs'
import { matchesTerms } from '@/ui/utils/object-filter'

const { appConfig } = config

// const { appConfig } = config

export const client = axios.create({
  baseURL: appConfig.apiURL,
  // baseURL: 'https://www.mcdapi.com/api/v1',
  // baseURL: 'http://qaapi.mycommunitycentral.com/api/v1',
  timeout: 30000,
  headers: { Authorization: `ApiKey ${appConfig.apiKey}` },
})

export const clienth = axios.create({
  baseURL: appConfig.apiURL,
  timeout: 30000,
  headers: { Authorization: `ApiKey ${appConfig.apiKey}` },
  transformRequest: humps,
})

// states

// ??
export const getState = async (state) => {
  const result = await clienth.get(`/system/state/${state}`)
  if (!result?.data) throw new Error('Not Found')

  return {
    state: result.data,
  }
}

export const getStates = async () => {
  const result = humps(await client.get(`/system/states`))
  if (!result?.data) throw new Error('Not Found')

  return {
    states: humps(result.data),
  }
}

// councils

export const getCouncilsForState = async ({ state }) => {
  // get the state

  const rstate = humps(await client.get(`/system/states/name/${state}`))
  if (!rstate.data) throw new Error('Not Found')

  // get councils
  const councils = humps(
    await client.get(`/system/councils/state/${rstate.data.shortUrl}`)
  )

  return {
    state: rstate.data,
    councils: councils.data || [],
  }
}

// localities

export const getLocalitiesForCouncil = async ({ state, council }) => {
  const [rstate, rcouncil, localities] = (
    await Promise.all([
      client.get(`/system/states/name/${state}`),
      client.get(`/system/councils/name/${state}/${council}`),
      client.get(`/system/localities/council/${state}/${council}`),
    ])
  ).map((r) => humps(r))

  if (!rstate.data || !rcouncil.data) throw new Error('Not Found')

  return {
    state: rstate.data,
    council: rcouncil.data,
    localities: localities.data?.data || [],
  }
}

export const getLocalityByParams = async ({ state, council, locality }) => {
  if (!state || !council || !locality) return null

  const result = await client.get(
    `/system/localities/name/${state}/${council}/${locality}`
  )
  const rdata = result?.data?.data

  return rdata ? toLocation(rdata) : null
}

// location

const toLocation = (loc) => ({
  type: 'locality',
  id: loc.LocalityID,
  label: loc.Name,
  name: loc.LocationName,
  councilId: loc.CouncilID,
  state: loc.State,
  postcode: loc.Postcode,
  lat: loc.Latitude,
  lng: loc.Longitude,
  url: loc.Url,
  councilUrl: loc.CouncilUrl,
  stateUrl: loc.StateUrl,
})

export const toLocationL = (loc) => ({
  type: 'locality',
  id: loc.localityId,
  label: loc.name,
  name: loc.locationName,
  councilId: loc.councilId,
  state: loc.state,
  postcode: loc.postcode,
  lat: loc.latitude,
  lng: loc.longitude,
  url: loc.url,
  councilUrl: loc.councilUrl,
  stateUrl: loc.stateUrl,
})

export const toLocalityL = (sloc) => ({
  localityId: sloc.id,
  name: sloc.label, // label: loc.name,
  locationName: sloc.name, //name: loc.locationName,
  councilId: sloc.councilId,
  state: sloc.state,
  postcode: sloc.postcode,
  latitude: sloc.lat,
  longitude: sloc.lng,
  url: sloc.url,
  councilUrl: sloc.councilUrl,
  stateUrl: sloc.stateUrl,
})

export const getLocation = async ({ lat, lng }) => {
  try {
    const result = await client.get(`/system/localities/${lat}/${lng}`)

    if (result.data) return toLocation(result.data)
  } catch (error) {
    console.error(error)
  }
}

export const getLocation2 = async ({ lat, lng }) => {
  if (!lat || !lng) return undefined

  const result = await client.get(`/system/localities/${lat}/${lng}`)

  if (result.data) return toLocation(result.data)

  return null
}

export const getLocationSuggestions = async ({
  searchTerm,
  councils,
  states,
}) => {
  // const filterFns = filterDefs.map(matchesTerms)
  // const filter = v => filterFns.every(f => f(v))

  if (!searchTerm || searchTerm.length < 3) return

  const queryParams = (() => {
    const qstr = Qs.stringify({
      ...(_.isArray(councils) && { limitToCouncils: councils.join('|') }),
      ...(_.isArray(states) && { limitToStates: states.join('|') }),
    })

    return qstr && `?${qstr}`
  })()

  const result = await client.post(`/system/localities/search${queryParams}`, {
    SearchTerm: searchTerm,
  })

  if (!result.data?.success) return

  const res = (result.data?.data || []).map(toLocation) // .filter(filter)

  return res
}

// directory

const convertServiceChanges = (sc) => {
  if (!sc || sc.length < 0) return null

  const now = new Date()

  const scv = humps(
    sc
      .sort((a, b) => a.id - b.id)
      .find((x) => new Date(x.StartDate) <= now && new Date(x.EndDate) >= now)
  )

  return scv
}

const toService = (s) => ({
  type: 'service',
  id: `${s.Service.ID}`,
  category: s.Category.Name,
  operatingDays: s.Service.OperatingDaysDisplay,
  name:
    s.Service.ServiceTitle && s.Service.ServiceTitle.length > 0
      ? s.Service.ServiceTitle
      : s.Outlet.Nickname,
  address: s.Outlet.DisplayAddress,
  phone: s.Outlet.Phone,
  description: s.Description,
  outletId: s.Outlet.OutletID,
  commissionedServiceId: s.Service.CommissionedServiceId,
  url: s.Url,
  logoUrl: s.Outlet.LogoUrl,
  healthEngineId: s.Outlet.HealthEngineID,
  healthEngineStatus: s.Outlet.HealthEngineStatus,
  healthEngineAppointment: s.HealthEngineAppointment,
  lat: s.Outlet.GeocodeLat,
  lng: s.Outlet.GeocodeLong,
  distance: parseFloat(s.Distance),
  serviceChanges: convertServiceChanges(s.Outlet.ServiceChanges),
  serviceTags: (s.Service.ServiceTags || []).map((t) => t.TagID),
  networks: humps(s.Networks),
})

export const searchDirectory = async (
  request,
  axiosConfig,
  url = '/directory/search'
) => {
  try {
    // console.log(JSON.stringify(request, null, 2))
    const res = await client.post(url, request, axiosConfig)

    console.log('searchDirectory', res)

    // console.log(humps(res.data.data))

    if (!res.data.success) throw new Error('search api failed')

    // console.log(res.data.total, res.data.data.length)

    // const oids = res.data.data.map(r => r.Outlet.OutletID)
    const heids = res.data.data
      .filter((r) => r.Outlet.HealthEngineStatus === 'A')
      .map((r) => ({
        outletId: r.Outlet.OutletID,
        serviceId: r.Service.ID,
        healthEngineId: r.Outlet.HealthEngineID,
      }))

    // parallel async secondary lookups

    const [ham] = await Promise.all([
      // lookupHolidayNotices(oids),
      lookupAppointments(heids),
    ])

    const fullResults = res.data.data.map((r) => ({
      ...r,
      // HolidayNotices: r.Outlet.OutletID in hnm ? humps(hnm[r.Outlet.OutletID]) : null,
      HealthEngineAppointment: r.Service.ID in ham ? ham[r.Service.ID] : null,
    }))

    return {
      total: res.data.total,
      results: fullResults.map(toService),
    }
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const searchDirectoryHealth = async (request) => {
  try {
    const url = '/listings/gethealthresults'
    const res = await client.get(url, { params: request })

    if (res.status !== 200) throw new Error(`search api failed ${res.status}`)

    const oids = res.data.Results.map((r) => r.Outlet.OutletID)
    const heids = res.data.Results.filter(
      (r) => r.Outlet.HealthEngineStatus === 'A'
    ).map((r) => ({
      outletId: r.Outlet.OutletID,
      serviceId: r.Service.ID,
      healthEngineId: r.Outlet.HealthEngineID,
    }))

    // parallel async secondary lookups

    const [hnm, ham] = await Promise.all([
      lookupHolidayNotices(oids),
      lookupAppointments(heids),
    ])

    const fullResults = res.data.Results.map((r) => ({
      ...r,
      HolidayNotices:
        r.Outlet.OutletID in hnm ? humps(hnm[r.Outlet.OutletID]) : null,
      HealthEngineAppointment: r.Service.ID in ham ? ham[r.Service.ID] : null,
    }))

    return {
      total: res.data.Total,
      results: fullResults.map(toService),
    }
  } catch (err) {
    console.error(err)
    throw err
  }
}

// diary

const toEvent = (e) => ({
  type: 'event',
  id: `${e.EventInstanceID}`,
  name: e.EventName,
  day: moment(e.StartDate).format('dddd'),
  startDay: e.InstanceStartDay,
  startMonth: e.InstanceStartMonth,
  time: e.IsAllDay ? 'All Day' : `${e.StartTime} - ${e.EndTime}`,
  address: [e.LocationTitle, e.LocationAddress].join(' ').trim(),
  phone: e.Phone1,
  description: e.TextOnlyDescription,
  url: e.InstanceUrl,
  lat: e.GeocodeLat,
  lng: e.GeocodeLong,
  distance: e.Distance,
  serviceChanges: e.HolidayNotices,
  appointmentRequired: e.IsAppointmentRequired,
  appointmentAvalable: e.AppointmentAvailability,
  referralRequired: e.IsReferralRequired,
  additionalInfo: e.AdditionalInfo,
  notes: e.Notes,
})

export const searchDiary = async (
  request,
  axiosConfig,
  url = '/search/diary/getresults'
) => {
  try {
    // console.log('searchDiary', url, request)
    const res = await client.get(url, { params: request }, axiosConfig)

    // console.log('res', res)

    const oids = res.data.map((r) => r.OwnerOutletID)
    const hnm = await lookupHolidayNotices(oids)

    const fullResults = res.data.map((r) => ({
      ...r,
      HolidayNotices: r.OwnerOutletID in hnm ? hnm[r.OwnerOutletID] : null,
    }))

    const frm = fullResults.map(toEvent)

    // console.log('fullResults', fullResults, frm)

    return {
      total: -1,
      results: frm,
    }
  } catch (err) {
    console.error(err)
    throw err
  }
}

// secondary lookups

export const lookupHolidayNotices = async (outletIds) => {
  try {
    const hn = await client.get('/manage/locations/holidaynotices', {
      params: { outletId: outletIds },
      paramsSerializer: (params) => Qs.stringify(params, { indices: false }),
    })

    if (!hn.data.data) return {}

    const hnm = keyBy(hn.data.data, 'OutletID')

    return hnm
  } catch (err) {
    console.error(err)
    return {}
  }
}

export const lookupAppointments = async (heids) => {
  const nextAppt = async ({ outletId, serviceId, healthEngineId }) => {
    try {
      const na = await client.get(
        `/healthengine/nextappointment/${outletId}/${serviceId}/${healthEngineId}`
      )
      return na.data.data ? { serviceId, nextAppointment: na.data.data } : null
    } catch (err) {
      console.error(err)
      return null
    }
  }

  const limit = pLimit(10)
  const batch = heids.map((args) => limit(() => nextAppt(args)))
  const heres = await Promise.all(batch)

  return heres
    .filter((r) => r)
    .reduce((obj, val) => {
      obj[val.serviceId] = val.nextAppointment
      return obj
    }, {})
}

// support ticket

export const submitSupportTicket = async (ticket) => {
  // return false
  try {
    const res = await client.post('/email/support', ticket)
    return !!res?.data?.success
  } catch (err) {
    console.error(err)
    return false
  }
}

// analytics

export const logAnalytics = async ({ index, payloads }) => {
  const res = await client.post('/analytics/ams/log', { index, payloads })
  return res
}

export const getPromotions = async ({ tenant, councilId }) => {
  if (!councilId) return

  const response = humps(await client.get(`/promotions/${tenant}/${councilId}`))
  return response?.data
}

export const getCommissionedServicesByOutlet = async (outletId) => {
  const result = await client.get(`/commissionedservices/outlet/${outletId}`)

  if (!result.data?.data || !result.data?.success) return null

  return humps(result.data.data)
}

const toGeography = ({ member, ...gm }) => {
  return {
    type: 'geography',
    id: gm.geographyId,
    label: gm.title,
    dataset: gm.dataset,
    lat: gm.centralLatitude,
    lng: gm.centralLongitude,
    area: gm.area,
    kmlUrl: gm.kmlLocation,
    lgas: gm.lgas,

    // sponsors (.../geographies/members/...)
    ...(member && { member }),
  }
}

export const getGeographyById = async (id) => {
  const result = humps(await client.get(`/system/geographies/id/${id}`)).data

  if (!result.data || !result.success) return null

  return toGeography(result.data)
}

export const getGeographyByDataset = async (dataset) => {
  const geog = humps(await client.get(`/system/geographies/${dataset}`)).data

  if (!geog.data || !geog.success) return null

  const lgas = (
    await Promise.all(
      geog.data.map((g) =>
        client.get(`/system/geographies/intersections/LGA/id/${g.geographyId}`)
      )
    )
  )
    .map(humps)
    .map((gis) => gis.data?.data.map((gi) => gi.otherGeographyId))

  const res = _.zip(geog.data, lgas)
    .map((g) => ({ ...g[0], lgas: g[1] }))
    .map(toGeography)

  return res
}

export const getGeographyMembers = async ({ id, lat, lng }) => {
  if (!id) return []

  const result = await client.get(`/system/geographies/members/${lat}/${lng}`)

  if (!result.data?.data || !result.data?.success) return null

  return humps(result.data.data).map(toGeography)
}

// select

const selectFetches = {
  unmapped: async (source) => {
    console.error('unmaped selectFetch', source)
  },
  geography: async (source) => {
    const res = await getGeographyById(source.id)
    return [
      {
        ...res,
        label: source.label || res.label,
        default: source.default,
      },
    ]
  },
  dataset: async (source) => {
    const res = await getGeographyByDataset(source.id)
    const ress = _.orderBy(res, ['label'], ['asc'])
    return ress
  },
}

export const getGeographySelectOptions = async (paramConfig) => {
  const sourceFetches = paramConfig.sources.map((s) => {
    const fn =
      s.type in selectFetches ? selectFetches[s.type] : selectFetches.unmapped
    return fn(s)
  })

  const allResults = _.flatten(
    (await Promise.all(sourceFetches)).filter((x) => x)
  )
  return allResults
}

export const getOutletsByOrgId = async (id) => {
  const result = await client.get(`/system/outlets/orgId/${id}`)
  if (!result.data?.data || !result.data?.success) return null
  return result.data
}
