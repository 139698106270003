import React, { useState, useEffect, useRef } from 'react'

import { observer } from 'mobx-react-lite'
import { useAnalytics } from 'use-analytics'

import { makeStyles, useTheme } from '@mui/styles'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useMediaQuery,
} from '@mui/material'

import LocationInput from '@/ui/location/LocationInput'

import { useConfig } from '@/config'
import { useRootStore } from '@/stores/root/store'

import { shortLabel } from '@/ui/utils/location-utils'

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      minWidth: 500,
    },
  },
}))

const LocationDialog = observer(({ open, onClose = () => undefined }) => {
  const classes = useStyles()
  const { track } = useAnalytics()
  const store = useRootStore()
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('sm'))

  const [location, setLocation] = useState(null)

  const { tenantConfig: tc } = useConfig()
  const cfg = tc?.search?.location || {}

  const defaultLocation = cfg.defaultLocation
  const defaultLocationLabel = shortLabel(
    defaultLocation,
    cfg.shortLocationFields
  )

  useEffect(() => {
    setLocation(open ? store.search.params.location : null)
  }, [open])

  const handleLocationChange = (event, value) => {
    setLocation(value)

    const { type, code } = event
    if (type === 'keydown' && code === 'Enter') {
      saveAndClose(value, 'searchLocationEnter')
    }
  }

  const handleOk = () => saveAndClose(location, 'searchLocationButtonOk')

  const handleDefault = () =>
    saveAndClose(defaultLocation, 'searchLocationButtonDefault')

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') return
    onClose()
  }

  const saveAndClose = (value, trackEvent) => {
    track(trackEvent || 'searchLocationSaveClose', {
      category: 'Search',
      label: 'Ok',
    })

    store.search.params.setLocation(value)
    onClose()
  }

  return (
    <Dialog
      classes={{ paper: classes.root }}
      fullScreen={sm}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{'Where are you?'}</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          To search the directory, you need to provide a location.
        </Typography>
        {defaultLocation && (
          <Typography variant="body1" gutterBottom>
            If you do not wish to provide one, the default location (
            {defaultLocationLabel}) will be selected.
          </Typography>
        )}
        <Box my={4}>
          <LocationInput
            id="location-select-dialog"
            value={location}
            onChange={handleLocationChange}
            showButtonLabel={!sm}
            autoFocus
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button
          onClick={onClose}
          color="default"
          variant="contained"
          disableElevation
          sx={{ minWidth: 80 }}
        >
          Cancel
        </Button>

        {defaultLocation && (
          <Button
            onClick={handleDefault}
            color="primary"
            variant="contained"
            disableElevation
            sx={{ minWidth: 80 }}
          >
            Use Default
          </Button>
        )}

        <Button
          onClick={handleOk}
          disabled={location === null}
          color="primary"
          variant="contained"
          disableElevation
          sx={{ minWidth: 80 }}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
})

export default LocationDialog
