import React from 'react'

import clsx from 'clsx'
import { styled } from '@mui/system'
import { Icon as Icon, useThemeProps } from '@mui/material'

const FontAwesomeIconRoot = styled(Icon, {
  name: 'AMSFontAwesomeIcon',
  shouldForwardProp: (prop) => prop !== 'fontSize',
})(({ theme, fontSize = 'inherit' }) => ({
  boxSizing: 'content-box',
  fontSize:
    {
      inherit: 'inherit',
      small: '0.86rem',
      medium: '1.05rem',
      large: '1.5rem',
    }[fontSize] || fontSize,
  padding:
    {
      large: '0.4rem',
    }[fontSize] || '0.2rem',
}))

export const FontAwesomeIcon = React.forwardRef(function FontAwesomeIcon(
  props,
  ref
) {
  const { fa, ...other } = props

  // const classes = fa.split
  const className = clsx(fa, 'fw')

  return (
    <FontAwesomeIconRoot
      //
      as="i"
      baseClassName=""
      className={className}
      ref={ref}
      {...other}
    />
  )
})

export default FontAwesomeIcon
