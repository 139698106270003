import { useState, useEffect } from 'react'
import _, { transform } from 'lodash'
import { removeNulls } from '@/utils/types'

import { QueryKey, InfiniteData, hashQueryKey } from 'react-query'
import { ApiResponse } from '.'
import { ServiceResult, EventResult } from '..'

// import { ModelForApiListResponse } from '..'

/**
 * Transforms response from CieApi search to UI models
 *
 * Data is returned in pages, and this uses intenal state
 * to render only new pages.
 *
 * @category Hooks
 * @template TResponse The response returned by CieApi
 * @template TParams The body type sent to CieApi
 * @template TModel The internal Model (i.e. response.data)
 * @param props.queryKey QueryKey (used to hash to detect
 * change of queries)
 * @param props.data Paged query results from CieApi
 * @param props.transformer Function to convert to UI model
 */
export const useInfiniteResultsTransform = <
  TResponse extends { data?: TModel[] },
  TResult extends ServiceResult | EventResult,
  TModel = unknown
>(props: {
  queryKey: QueryKey
  data?: InfiniteData<TResponse>
  // select?: (response: TResponse) => TModel[] | undefined
  transformer: (data: TModel) => TResult | undefined
}) => {
  const {
    queryKey,
    data,
    // select = response => response,
    transformer,
  } = props

  const queryKeyHash = hashQueryKey(queryKey)

  const [history, setHistory] = useState<{
    queryKeyHash: string
    pages?: TResult[][]
  }>({
    queryKeyHash,
  })

  const isNewQuery = queryKeyHash !== history.queryKeyHash

  const newPages = data?.pages.slice(history.pages?.length)

  // preserves empty pages for diffing

  const selected = newPages?.map(
    (response) => response.data || []
    // select inner data of api responses
    // (<{ data?: TModel[] }>response).data
    // naked response data
    // <TModel[]>response
  )

  // removes empty items from results (i.e. soft failed transforms)

  const newResults = selected?.map(
    (page) => page.map(transformer).filter(removeNulls) || []
  )

  const results = {
    queryKeyHash,
    pages: [...((!isNewQuery && history.pages) || []), ...(newResults || [])],
  }

  if (isNewQuery || newResults?.length) {
    setHistory(results)
  }

  return results
}
