import React from 'react'

import { Link as RouterLink } from 'react-router-dom'

import { withStyles } from '@mui/styles'
import { Box, Typography, Link as MuiLink } from '@mui/material'

import { asArray } from '@/utils'
import { useConfig } from '@/config'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textDecoration: 'none',
    // width: 'auto',
    // width: 350,
  },
  logo: {
    height: 32,
    // maxHeight: '100%',
    marginRight: theme.spacing(1),
  },
  title: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    // whiteSpace: 'nowrap',
    // color: theme.palette.secondary,
  },
})

const AppBarTitle = ({
  classes,
  logo = 'logo',
  title,
  disableLogo = false,
  disableTextTitle = false,
  href,
  to,
  ...otherProps
}) => {
  const { tenantConfig: tc } = useConfig()

  const titleText = title || tc.options?.title

  const logoSrc = tc.assets?.[logo]

  const linkProps = { href, to }

  // const rootProps =
  //   href ? ({ ...r})

  const Root = href || to ? MuiLink : Box

  // const rootProps
  //   = href ? ({ href })
  //   : to ? ({ to, component: RouterLink })
  //   : null

  const rootProps = {
    className: classes.root,
    href,
    to,
    ...(to && { component: RouterLink }),
    ...otherProps,
  }

  // const Root =
  //   rootProps.href ? MuiLink :
  //   rootProps.to ? RouterLink :
  //   'div'

  const TitleLogo = ({ src }) => {
    if (!src) return null
    return <img className={classes.logo} src={src} alt="Logo" />
  }

  const TitleText = ({ titleText }) => {
    if (!titleText) return null

    const contents = asArray(titleText).join('<br />')

    return (
      <Typography
        variant="h6"
        className={classes.title}
        dangerouslySetInnerHTML={{ __html: contents }}
      />
    )
  }

  return (
    <Root className={classes.root} {...rootProps}>
      {!disableLogo && <TitleLogo src={logoSrc} />}
      {!disableTextTitle && <TitleText titleText={titleText} />}
    </Root>
  )
}

export default withStyles(styles, { name: 'AMSAppBarTitle' })(AppBarTitle)
