import React from 'react'

import { Loader, LoaderOptions } from '@googlemaps/js-api-loader'
import { useConfig } from '@/config'
import { usePlatform } from '@/ui/hooks/usePlatform'

export const useLoadGoogleMaps = (): {
  isLoaded: boolean
  isError: boolean
} => {
  const { appConfig } = useConfig()
  const apiKey = appConfig.googleMapsApiKey as string

  const platform = usePlatform()
  const version = platform.ieVersion ? '3.45' : 'quarterly'

  const [isLoaded, setIsLoaded] = React.useState(false)
  const [isError, setIsError] = React.useState(false)

  const options: LoaderOptions = {
    apiKey,
    version,
    id: 'ams-gm-loader',
    retries: 2,
  }

  React.useEffect(() => {
    const loader = new Loader(options)
    loader.load().then(
      () => setIsLoaded(true),
      () => setIsError(true)
    )
  }, [])

  return { isLoaded, isError }
}
