import { makeAutoObservable, toJS } from 'mobx'

import _ from 'lodash'
import { generatePath } from 'react-router'
import Qs from 'qs'

import { toJSDeep } from '../root/utils/mobx'
import { getUrlHash } from '@/stores/root/utils/hash'
import { asArray } from '@/utils'

// generate route from pathType
// use pathType to get path and and parameterMap
// use path and parameterMap to explicitly set the mapping
// tries to map using parameterMap (source[sourceKey][fieldKey])
// or maps parameters directly parameterMap.keys -> params

// failedMatches 'warn', 'tag'

// delimiter() {
//   return this._store.config.pathOptions?.delimiter || '+'
// }

export const getRouterInfo = ({ match, location }) => {
  const { path, url, params } = match
  const { search } = location
  const { query, queryParams } = fromQueryString(search) || {}
  const hash = getUrlHash({ url, query })

  return { path, url, params, query, queryParams, hash }
}

export const fromQueryString = (str) => {
  if (!str || !_.isString(str)) return null
  const query = str.replace(/^\?/, '')
  const queryParams = Qs.parse(query)
  return { query, queryParams }
}

export const splitMultipleParam = (param, { delimiter = '+' } = {}) => {
  if (!param) return []
  return param.split(delimiter)
}

export const joinMultipleParams = (params = [], { delimiter = '+' } = {}) => {
  if (!params || params.length === 0) return
  return params.join(delimiter)
}

export const toUrlParam = (
  param,
  { delimiter = '+', whiteSpace = '_' } = {}
) => {
  const strip = (p) => p.replace(/[\s&,/]+/g, whiteSpace)
  if (_.isString(param)) return strip(param)
  if (_.isArray(param)) return param.map(strip).join(delimiter)
}

export const generateUrlFromRouteInfo = ({ pathType, routeInfo }, options) => {
  const { params } = routeInfo
  return generateUrl({ pathType, params }, options)
}

const mapParams1 = ({ params, source, parameterMap }, options) => {
  const mapParam = ({ src, skey, pkey }) => {
    if (!src) return

    const msrc = src[skey] || src
    // console.log({ msrc, src, skey, pkey })

    if (!msrc) return

    if (_.isArray(msrc)) return msrc.map((s) => s[pkey])

    return msrc[pkey]
  }

  const mparams = _.chain(parameterMap)
    .mapValues((m, pkey) => {
      const [skey, fkey] = m

      if (source) {
        const r = mapParam({ src: source, skey, pkey: fkey })

        // console.log({ r, source })
        if (r) return r
      }

      const r = mapParam({ src: params, pkey })

      // console.log({ r, params })
      return r
    })
    // .tap(t => console.log(t))
    .mapValues((v) => toUrlParam(v, options))
    .value()

  return {
    params: mparams,
    isCanonical: false,
  }
}

const mapParams2 = ({ params, source, parameterMap }, options) => {
  const mapSourceMultiple = ({ src, map, multiple }) => {
    const psrc = asArray(src)
    const pmap = psrc.length === 1 ? map || multiple : multiple

    const sparams = psrc.map((src) => mapSourceSingle({ src, map: pmap }))

    return {
      param: joinMultipleParams(
        sparams.map((sp) => sp.param),
        options
      ),
      multipleParamsInfo: sparams,
      isCanonical: true,
    }
  }

  const mapSourceSingle = ({ src, map }) => {
    return {
      param: toUrlParam(src[map[0]], options),
      isCanonical: true,
    }
  }

  const mapParam = ({ params, pkey }) => {
    return {
      param: params?.[pkey],
      isCanonical: false,
    }
  }

  const paramsInfo = _.mapValues(parameterMap, (pm, pkey) => {
    const { source: skey, map, multiple } = pm || {}
    const src = skey && source?.[skey]

    // console.log('v2 param', pkey, skey, toJSDeep({ map, multiple, src }))

    if (src && multiple) {
      return mapSourceMultiple({ src, map, multiple })
    }

    if (src && map) {
      return mapSourceSingle({ src, map })
    }

    return mapParam({ params, pkey })
  })

  const isCanonical = _.every(_.map(paramsInfo, (pi) => pi.isCanonical))

  return {
    params: _.mapValues(paramsInfo, (mp) => mp.param),
    info: paramsInfo,
    isCanonical,
  }
}

export const generateUrl = (args, options) => {
  if (!args) return null

  const { pathType: pt, path: p, parameterMap: pm, source, params } = args || {}

  // console.log('GENERATE', { pathType: pt, path: p, parameterMap: pm, source, params })

  if (!source && !params) {
    // console.warn('Invalid sources, need one with a parameterMap', { source, params })
    return
  }

  const pathType = pt

  const path = pathType?.path || p
  const parameterMap = pathType?.parameterMap || pm

  // pathType with canonical route (fixed)
  const fcan = pathType?.canonical
  if (fcan) {
    return {
      url: fcan,
      hash: getUrlHash({ url: fcan }),
      isCanonical: true,
    }
  }

  if (!path || !parameterMap) {
    // console.warn('Invalid path, parameterMap', { pathType: pt, parameterMap })
    return null
  }

  // const { v1, v2 } = parameterMap
  // const pmv1 = v1 || parameterMap
  // console.log(toJSDeep({ parameterMap, v1, v2, pmv1 }))

  // const mapParams = _.isArray(parameterMap) ? mapParams1 : mapParams2
  const paramsInfo = mapParams2({ params, source, parameterMap }, options)

  const gen = ({ path, params }) => {
    try {
      const url = generatePath(path, params)
      return { url }
    } catch (e) {
      return {
        generatePathError: e,
      }
    }
  }

  const { params: mparams } = paramsInfo

  const generated = gen({ path, params: mparams })
  const { url } = generated

  const hash = url && getUrlHash({ url, params: mparams })

  const res = {
    ...generated,
    hash,
    path: path,
    params: mparams,
    paramsInfo,
    parameterMap,
  }

  // console.log('GENERATE', { ...res })

  return res
}
