/**
 * Predicate for filters on arrays which narrows type
 *
 * ```typescript
 * const arr = ['one', 2, undefined, 'four']
 *
 * arr.filter(x => !!x)
 * // returns (string | number | undefined)[]
 *
 * arr.filter(removeNulls)
 * // returns (string | number)[]
 * ```
 */
export const removeNulls = <S>(value: S | undefined): value is S =>
  value != null
