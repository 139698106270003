import React, { useState } from 'react'

import _ from 'lodash'

import { getLocation2 as getLocation } from '@/api/legacy'

import { matchesTerms } from '@/ui/utils/object-filter'

const filterMap = {
  councils: 'councilId',
  states: 'state',
}

export const useCurrentLocation = (props = {}) => {
  const {
    filters: localityFilters = {},
    onReset = () => undefined,
    onSuccess = () => undefined,
    onError = (error) => error,
  } = props

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [location, setLocation] = useState(null)

  const reset = () => {
    setLoading(false)
    setError(null)
    setLocation(null)
    onReset()
  }

  const raiseError = (error) => {
    // console.warn('in raise')
    const {
      type = 'unknown',
      message = 'Your location could not be determined.',
      ...err
    } = error
    const e = {
      type,
      message,
      ...err,
    }

    // console.warn(e)
    setError(onError(e))
  }

  const locate = () =>
    (async () => {
      try {
        if (loading) return

        reset()
        setLoading(true)

        const coords = await getCurrentPosition()
        const loc = await getLocation(coords)

        const filterDefs =
          _.map(localityFilters, (v, k) => ({ [filterMap[k] || k]: v })) || []
        const filterFns = filterDefs.map(matchesTerms)
        const filter = (v) => filterFns.every((f) => f(v))

        // console.log({ loc, filtered: filter(loc) })

        if (!loc) throw { type: 'NoLocalityForCoords', coords }

        if (!filter(loc)) throw { type: 'LocationFiltered', location: loc }

        var uloc = { ...loc, ...coords }

        setLocation(uloc)
        onSuccess(uloc)
      } catch (e) {
        // console.warn('in catch')
        // console.warn(e)
        raiseError(e)
      } finally {
        setLoading(false)
      }
    })()

  return {
    locate,
    reset,
    loading,
    location,
    error,
  }
}

export const getCurrentLocation = async () => {
  try {
    const coords = await getCurrentPosition()
    const loc = await getLocation(coords)

    if (!loc) throw { type: 'No location for coords' }

    return { ...loc, ...coords }
  } catch (e) {
    console.error(e)
    throw {
      ...e,
      message: e.message || 'Your location could not be determined.',
    }
  }
}

export const getCurrentPosition = async () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (pos) => resolve({ lat: pos.coords.latitude, lng: pos.coords.longitude }),
      (error) =>
        reject({
          type: typeof error,
          error,
        }),
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }
    )
  })
}
