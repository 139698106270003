export function neatJson (value, opts) {
  return neatJSON(value, {
    indent: '  ',
    // wrap: false,
    wrap: 120,
    sort: true,
    // aligned: true,
    objectPadding: 1,
    afterComma: 1,
    afterColon1: 1,
    aroundColonN: 1,
    ...opts,
  })
}

export function neatJSON(value, opts) {
  opts = opts || {}
  if (!('wrap' in opts)) opts.wrap = 80
  if (opts.wrap == true) opts.wrap = -1
  if (!('indent' in opts)) opts.indent = '  '
  if (!('arrayPadding' in opts)) opts.arrayPadding = 'padding' in opts ? opts.padding : 0
  if (!('objectPadding' in opts)) opts.objectPadding = 'padding' in opts ? opts.padding : 0
  if (!('beforeComma' in opts)) opts.beforeComma = 'aroundComma' in opts ? opts.aroundComma : 0
  if (!('afterComma' in opts)) opts.afterComma = 'aroundComma' in opts ? opts.aroundComma : 0
  if (!('beforeColon' in opts)) opts.beforeColon = 'aroundColon' in opts ? opts.aroundColon : 0
  if (!('afterColon' in opts)) opts.afterColon = 'aroundColon' in opts ? opts.aroundColon : 0
  if (!('beforeColon1' in opts))
    opts.beforeColon1 =
      'aroundColon1' in opts ? opts.aroundColon1 : 'beforeColon' in opts ? opts.beforeColon : 0
  if (!('afterColon1' in opts))
    opts.afterColon1 =
      'aroundColon1' in opts ? opts.aroundColon1 : 'afterColon' in opts ? opts.afterColon : 0
  if (!('beforeColonN' in opts))
    opts.beforeColonN =
      'aroundColonN' in opts ? opts.aroundColonN : 'beforeColon' in opts ? opts.beforeColon : 0
  if (!('afterColonN' in opts))
    opts.afterColonN =
      'aroundColonN' in opts ? opts.aroundColonN : 'afterColon' in opts ? opts.afterColon : 0

  let apad = repeat(' ', opts.arrayPadding),
    opad = repeat(' ', opts.objectPadding),
    comma = repeat(' ', opts.beforeComma) + ',' + repeat(' ', opts.afterComma),
    colon1 = repeat(' ', opts.beforeColon1) + ':' + repeat(' ', opts.afterColon1),
    colonN = repeat(' ', opts.beforeColonN) + ':' + repeat(' ', opts.afterColonN)

  let build = memoize()

  let visited = new WeakSet()

  return build(value, '')

  function memoize() {
    let memo = new Map()
    return function (o, indent) {
      let byIndent = memo.get(o)
      if (!byIndent) memo.set(o, (byIndent = {}))
      if (!byIndent[indent]) byIndent[indent] = rawBuild(o, indent)
      return byIndent[indent]
    }
  }

  function rawBuild(o, indent) {
    if (o === null || o === undefined) return indent + 'null'
    else {
      if (typeof o === 'number') {
        if (o === Infinity) {
          return indent + '9e9999'
        } else if (o === -Infinity) {
          return indent + '-9e9999'
        } else if (Number.isNaN(o)) {
          return indent + 'NaN'
        } else {
          let isFloat = o === +o && o !== (o | 0)
          return indent + (isFloat && 'decimals' in opts ? o.toFixed(opts.decimals) : o + '')
        }
      } else if (o instanceof Array) {
        if (!o.length) return indent + '[]'
        let pieces = o.map(function (v) {
          return build(v, '')
        })
        let oneLine = indent + '[' + apad + pieces.join(comma) + apad + ']'
        if (opts.wrap === false || oneLine.length <= opts.wrap) return oneLine
        if (opts.short) {
          let indent2 = indent + ' ' + apad
          pieces = o.map(function (v) {
            return build(v, indent2)
          })
          pieces[0] = pieces[0].replace(indent2, indent + '[' + apad)
          pieces[pieces.length - 1] = pieces[pieces.length - 1] + apad + ']'
          return pieces.join(',\n')
        } else {
          let indent2 = indent + opts.indent
          return (
            indent +
            '[\n' +
            o
              .map(function (v) {
                return build(v, indent2)
              })
              .join(',\n') +
            '\n' +
            (opts.indentLast ? indent2 : indent) +
            ']'
          )
        }
      } else if (o instanceof Object) {
        // if (visited.has(o)) {
        //   console.log(o)
        //   return '[$circ_ref]'
        // }
        // visited.add(o)

        let sortedKV = [],
          i = 0
        let sort = opts.sort || opts.sorted
        for (let k in o) {
          let kv = (sortedKV[i++] = [k, o[k]])
          if (sort === true) kv[2] = k
          else if (typeof sort === 'function') kv[2] = sort(k, o[k], o)
        }
        if (!sortedKV.length) return indent + '{}'
        if (sort)
          sortedKV = sortedKV.sort(function (a, b) {
            a = a[2]
            b = b[2]
            return a < b ? -1 : a > b ? 1 : 0
          })
        let keyvals = sortedKV
          .map(function (kv) {
            // return [JSON.stringify(kv[0]), build(kv[1], '')].join(colon1)
            return [kv[0], build(kv[1], '')].join(colon1)
          })
          .join(comma)
        let oneLine = indent + '{' + opad + keyvals + opad + '}'
        if (opts.wrap === false || oneLine.length < opts.wrap) return oneLine
        if (opts.short) {
          keyvals = sortedKV.map(function (kv) {
            return [indent + ' ' + opad + kv[0], kv[1]]
          })
          keyvals[0][0] = keyvals[0][0].replace(indent + ' ', indent + '{')
          if (opts.aligned) {
            let longest = 0
            for (let i = keyvals.length; i--; )
              if (keyvals[i][0].length > longest) longest = keyvals[i][0].length
            let padding = repeat(' ', longest)
            for (let i = keyvals.length; i--; ) keyvals[i][0] = padRight(padding, keyvals[i][0])
          }
          for (let i = keyvals.length; i--; ) {
            let k = keyvals[i][0],
              v = keyvals[i][1]
            let indent2 = repeat(' ', (k + colonN).length)
            let oneLine = k + colonN + build(v, '')
            keyvals[i] =
              opts.wrap === false || oneLine.length <= opts.wrap || !v || typeof v != 'object'
                ? oneLine
                : k + colonN + build(v, indent2).replace(/^\s+/, '')
          }
          return keyvals.join(',\n') + opad + '}'
        } else {
          let keyvals = sortedKV.map(function (kvs) {
            kvs[0] = indent + opts.indent + kvs[0] // JSON.stringify(kvs[0])
            return kvs
          })
          if (opts.aligned) {
            let longest = 0
            for (let i = keyvals.length; i--; )
              if (keyvals[i][0].length > longest) longest = keyvals[i][0].length
            let padding = repeat(' ', longest)
            for (let i = keyvals.length; i--; ) keyvals[i][0] = padRight(padding, keyvals[i][0])
          }
          let indent2 = indent + opts.indent
          for (let i = keyvals.length; i--; ) {
            let k = keyvals[i][0],
              v = keyvals[i][1]
            let oneLine = k + colonN + build(v, '')
            keyvals[i] =
              opts.wrap === false || oneLine.length <= opts.wrap || !v || typeof v != 'object'
                ? oneLine
                : k + colonN + build(v, indent2).replace(/^\s+/, '')
          }
          return (
            indent +
            '{\n' +
            keyvals.join(',\n') +
            '\n' +
            (opts.indentLast ? indent2 : indent) +
            '}'
          )
        }
      } else {
        const x = JSON.stringify(o).replace(/(^"|"$)/g, '\'')
        return indent + x
      }
    }
  }

  function repeat(str, times) {
    // http://stackoverflow.com/a/17800645/405017
    let result = ''
    while (true) {
      if (times & 1) result += str
      times >>= 1
      if (times) str += str
      else break
    }
    return result
  }

  function padRight(pad, str) {
    return (str + pad).substring(0, pad.length)
  }
}
