import _ from 'lodash'
import { camelCase } from 'change-case-all'

export const loadImgAssets = (rctx) => {
  return rctx.keys().reduce((acc, curr) => {
    const base = curr.match(/^(.\/)?(.*)\.(png|webp|jpe?g|svg)$/)

    if (base.length < 4) {
      console.warn(`Image path failed to parse ${curr}`)
      return acc
    }

    _.set(acc, base[2].split('/').map(camelCase).join('.'), rctx(curr).default)

    return acc
  }, {})
}

const images = loadImgAssets(
  require.context('./_assets', true, /\.(png|webp|jpe?g|svg)$/)
)

const assets = {
  images,

  defaults: {
    cie: images.cie,
    flags: images.flags,
    healthengine: images.healthengine,
    headerIcons: {
      directory: images.cissIcons.directory.transparent,
      diary: images.cissIcons.diary.transparent,
    },
    mapMarkers: {
      services: images.markers.directory,
      events: images.markers.diary,
    },
    mapMarkersSvg: {
      services: images.markersSvg.directory,
      events: images.markers.diary,
    },
  },
}

export default assets
