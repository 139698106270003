import _ from 'lodash'
import deepmerge from 'deepmerge'

import { makeAutoObservable } from 'mobx'

import {
  generateUrl,
  generateUrlFromRouteInfo,
  fromQueryString,
  toUrlParam,
  splitMultipleParam,
} from './path-utils'

import { getUrlHash } from '@/stores/root/utils/hash'

// const searchPathTypes = {
//   'false,false': 'root',
//   'false,true': 'searchCategories',
//   'true,false': 'searchLocality',
//   'true,true': 'searchLocalityCategories',
// }

export class RoutePath {
  _store = null
  _param

  constructor(store) {
    makeAutoObservable(this, {
      _param: false,
    })

    this._store = store
    this._param = new generateUrl(this._store)
  }

  get helpers() {
    return {
      _debugLinks: {
        type: 'TestLinks',
        links: [
          '/',
          '/search/Queensland/Brisbane/Fortitude_Valley',
          '/search/Queensland/Brisbane/Fortitude_Valley/categories/Allied+Ageing',
          '/search/Queensland/Moreton_Bay/Mango_Hill',
          '/search/Queensland/Maranoa/Roma',
          '/search/categories/Allied+Ageing',
          '/search/categories/Allied+Ageing?rloc=true',
          '/search/categories/Allied+Ageing?womble=orinoco',
          '/categories/General',
        ],
      },
      json: [
        'pathTypes',
        'pathConfig',
        'pathSources',
        'searchPathSources',
        'searchStoreInfo',
        'generated',
        'info',
      ],
    }
  }

  get rootStore() {
    return this._store.rootStore
  }

  get paramOptions() {
    return {
      delimiter: this._store.config.pathOptions?.delimiter || '+',
      whitespace: '_',
    }
  }

  get pathTypes() {
    // const pathType = this.store.config?.generatedPaths?.[pathTypeName]
    return _.mapValues(this._store.config.generatedPaths, (pathType, k) => {
      const { parameterMaps: pm, ...pt } = pathType

      const pmaps = pm && pm.map((pn) => this._store.config.parameterMaps[pn])
      const mpmaps =
        pmaps && pmaps.reduce((prev, curr) => ({ ...prev, ...curr }), {})

      return {
        ...pt,
        parameterMap: mpmaps,
      }
    })
  }

  get searchPathTypesParamLookup() {
    return {
      'false,false':
        this._store.config.searchRouteHandler?.defaultRoute?.pathTypeName ||
        'root',
      'false,true': 'searchCategories',
      'true,false': 'searchLocality',
      'true,true': 'searchLocalityCategories',
    }
  }

  get pathTypeReverse() {
    const p = _.map(this._store.config.generatedPaths, (v, k) => [v.path, k])
    return _.fromPairs(p)
  }

  get pathConfig() {
    const matchPath = this._store.match?.path
    const pathConfig = this._store.matchedRoute?.pathConfig

    if (!matchPath || !pathConfig) return null

    const pathType = this.pathTypeReverse[matchPath]
    const cfgKeys = ['default', pathType]

    const cfgs = _.compact(cfgKeys.map((k) => pathConfig[k]))
    if (!cfgs || cfgs.length === 0) return null

    return deepmerge.all([{ pathType }, ...cfgs])
  }

  generateUrl(args) {
    return generateUrl(args, this.paramOptions)
  }

  pathType({ pathTypeName }) {
    const pathType = this._store.config?.generatedPaths?.[pathTypeName]
    if (!pathType) return null

    const { parameterMaps, ...ptype } = pathType
    const parameterMap =
      parameterMaps && this.parameterMaps({ parameterMapNames: parameterMaps })

    return {
      ...ptype,
      parameterMap,
    }
  }

  parameterMaps({ parameterMapNames = [] }) {
    const pmaps = parameterMapNames.map(
      (pn) => this._store.config.parameterMaps[pn]
    )
    const mpmaps = pmaps.reduce((prev, curr) => ({ ...prev, ...curr }), {})
    return mpmaps
  }

  // gets extra reverse info for search route params
  // used by cacheQueryResults

  getQueryParamInfo({ parameterMap = {}, params = {} }) {
    return _.reduce(
      parameterMap,
      (info, pmap, paramKey) => {
        const param = params[paramKey]
        const sourceKey = pmap.source

        _.set(info.bySource, [sourceKey, paramKey], param)

        if (paramKey === 'categories')
          _.set(info.byParam, [paramKey], {
            multiple: splitMultipleParam(param, this.paramOptions),
          })

        return info
      },
      { bySource: {}, byParam: {} }
    )
  }

  // general route prerequisites (generate canonical from params)

  get pathSources() {
    if (!this._store.match) return null
    // const rid = this._store.matchedRoute?.id
    // if (rid === 'search') return this.searchPathSources

    // const { path, url, params } = this._store.match
    // const { match, pathConfig } = this.pathConfig || {}
    const pathTypeName = this?.pathConfig?.pathType
    const pathType = this.pathTypes[pathTypeName]

    const { path, url, params } = this._store.match
    const { query, queryParams } =
      fromQueryString(this._store?.location?.search) || {}
    const hash = getUrlHash({ url, params, query })

    return {
      pathTypeName,
      pathType,
      routeInfo: { path, url, params, query, queryParams, hash },
    }
  }

  get pathInfo() {
    if (!this.pathSources) return null
    // const { pathTypeName, pathType, source } = this.pathSources
    const gen = generateUrlFromRouteInfo(this.pathSources, this.paramOptions)

    const { source: s, routeInfo: ri } = this.pathSources

    const isCanonical = gen?.isCanonical || gen?.paramsInfo?.isCanonical
    const canonical = isCanonical ? gen.url : null
    // const url = canonical || ri.url

    const info = {
      ...this.pathSources,
      generated: gen,
      isCanonical,
      canonical,
      url: gen?.url,
    }

    return info
  }

  // path type and sources from the current search params

  get searchPathSources() {
    return this.getSearchPathSources()
  }

  getSearchPathSources(options) {
    const { source: optSource } = options || {}

    const mergedSource = {
      ...this.rootStore.search.params.routePathSource,
      ...optSource,
    }

    const pathKey = [
      !!mergedSource.locality,
      mergedSource.categories.length > 0,
    ].toString()
    const pathTypeName = this.searchPathTypesParamLookup[pathKey]
    const pathType = this.pathTypes[pathTypeName]

    return {
      // pathKey,
      pathTypeName,
      pathType,
      source: {
        locality: mergedSource.locality?.asLocality,
        categories: mergedSource.categories,
      },
    }
  }

  // path info for search route machine
  // uses source (locality/categories) from store.search.params by default

  get searchStoreInfo() {
    return this.getSearchStoreInfo()
  }

  getSearchStoreInfo(options) {
    const pathSources = this.getSearchPathSources(options)

    const { pathTypeName, pathType, source } = pathSources
    const { path, url, params, parameterMap, paramsInfo } =
      this.generateUrl(pathSources) || {}

    const { query, queryParams } =
      fromQueryString(this._store?.location?.search) || {}
    const hash = getUrlHash({ url, query })

    // const hash = getUrlHash({ url, params })
    const isCanonical = !!paramsInfo?.isCanonical
    const canonical = isCanonical ? url : null

    return {
      pathTypeName,
      pathType,
      path,
      url,
      params,
      paramsInfo,
      query,
      queryParams,
      source,
      parameterMap,
      hash,
      isCanonical,
      canonical,
    }
  }

  get generated() {
    return this.generateUrl(this.pathSources) || {}
  }

  get info() {
    if (!this._store.match || !this._store.matchedRoute) return null

    const rid = this._store.matchedRoute?.id
    if (rid === 'search') return this.searchStoreInfo

    return this.pathInfo

    // const generated = this.generated
    // const match = this._store.match

    // const ready = !!generated && !!match

    // return {
    //   ready,
    //   ...this.generated,
    // }
  }
}
