/* eslint-disable no-undef */

export const loadMarkdown = () => {
  const res = require.context(
    `../../../tenants/${process.env.TENANT}/content`,
    true,
    /\.md$/
  )

  return res.keys().map((key) => ({
    id: key.match(/\/(.+)\.md$/)[1],
    type: 'md',
    ...res(key),
  }))
}
