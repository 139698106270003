import React from 'react'

import { Typography, Box } from '@mui/material'

import Button from '@/ui/components/buttons/Button'

const LandingRegAndList = (props) => {
  return (
    <Box
      sx={{
        textAlign: 'center',
        margin: '0 auto',
        maxWidth: 800,
      }}
    >
      <Typography variant="body1" subvariant="landing">
        Are you a service provider and want to list your services on Access My
        Healthcare? You can do this by registering your information on My
        Community Directory.
      </Typography>

      <Button
        sx={{ my: 4 }}
        variant="contained"
        // size="large"
        size="extraLarge"
        href="https://www.mycommunitydirectory.com.au/Manage/dashboard"
        // disableElevation
        rounded
      >
        Register and List
      </Button>
    </Box>
  )
}

export default LandingRegAndList
