import React from 'react'

import { styled } from '@mui/system'
import { Button as MuiButton } from '@mui/material'

export const Button = styled(MuiButton, {
  name: 'AMSButton',
  shouldForwardProp: (prop) => prop !== 'rounded',
})(({ rounded, size, theme }) => ({
  ...(rounded && {
    borderRadius: 9999,
    padding: {
      large: theme.spacing(1, 3),
    }[size],
  }),
}))

export default Button
