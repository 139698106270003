import _ from 'lodash'
import { types, getParent } from 'mobx-state-tree'
// import { useConfig } from '@/config'

export const SearchUI = types
  .model({
    locationDialogShown: false,
    showAllCategories: false,
    selectedTab: 0,
    covidAlertShown: false,
  })

  .volatile((self) => ({
    paramsPortal: null,
  }))

  .views((self) => ({
    get params() {
      return getParent(self).params
    },
  }))

  .actions((self) => ({
    setShowAllCategories(val) {
      self.showAllCategories = val
    },
    setSelectedTab(val) {
      self.selectedTab = val
    },
    setLocationDialogShown(val) {
      self.locationDialogShown = val
    },
    setCovidAlertShown(val) {
      self.covidAlertShown = val
    },
    setParamsPortal(val) {
      self.paramsPortal = val
    },

    clear() {
      self.showAllCategories = false
      self.selectedTab = 0
    },

    updateFromSurvey(survey) {
      // console.log(survey.pathwayConfig)

      const config = survey.pathwayConfig?.ui
      if (!config) return

      _.assign(self, config)
    },
  }))
