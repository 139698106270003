import _ from 'lodash'

import { migrations } from './storage-migrations'
import { loadMarkdown } from './markdown'

import { config } from '@/config'
import deepmerge from 'deepmerge'
const { appConfig, tenantConfig: tc } = config

// preload markdown content

const markdownContent = _.keyBy(
  loadMarkdown({ tenant: appConfig.tenant }),
  'id'
)

// initial user data

const defaultUserData = {
  version: appConfig.version || 0,
  params: {},
  survey: {},
  ui: {},
}

const loadDefaultUserData = () =>
  deepmerge(defaultUserData, tc.defaultUserData || {})

// load from store

const loadStoredUserData = () => {
  const loadFromLocal = () => {
    try {
      const json = window.localStorage.getItem(
        `ams-userstore-${appConfig.tenant || 'default'}`
      )
      return json && JSON.parse(json)
    } catch {
      //
    }
  }

  const storedUserData = loadFromLocal()
  if (!storedUserData) return null

  // run migrations

  const prev = storedUserData.version || 0
  const curr = appConfig.version || 0

  const migrated =
    migrations
      .filter(
        (m) => m.version === -1 || (m.version > prev && m.version <= curr)
      )
      .reduce((data, curr) => {
        // console.log(`Migrating: ${curr.info}`)
        return curr.migrate({ userData: data }) || data
      }, storedUserData) || loadDefaultUserData()

  const versioned = _.cloneDeep({
    ...migrated,
    version: curr,
  })

  // console.log({ iud })

  return versioned
}

const buildStoreConfig = (userData) => {
  const {
    search: { searches },
    categories,
    options,
  } = tc

  const { params, ui, survey, favourites } = userData

  return {
    search: {
      searches: _.merge(
        { ...searches },
        searches?.favourites ? { favourites: { results: favourites } } : null
      ),
      params,
      ui,
    },
    survey,
    tenant: {
      categories,
      options,
      content: {
        items: markdownContent,
      },
    },
  }
}

export const initStoreConfigs = () => {
  const storedUserData = loadStoredUserData()

  return {
    defaultConfig: buildStoreConfig(_.cloneDeep(loadDefaultUserData())),
    userConfig: storedUserData ? buildStoreConfig(storedUserData) : null,
  }
}
