import React, { useRef } from 'react'

import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import { useAnalytics } from 'use-analytics'

import { withStyles } from '@mui/styles'
import { Box, Tabs, Tab, Button, useMediaQuery } from '@mui/material'
import ReactToPrint, { useReactToPrint } from 'react-to-print'

import { useConfig } from '@/config'
import { useRootStore } from '@/stores/root/store'
import _ from 'lodash'

const styles = (theme) => ({
  root: {
    flex: '0 1 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },
  tabsWrapper: {
    flex: '0 1 auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  hidden: {
    display: 'none !important',
  },
  toolbarButton: {
    margin: theme.spacing(0, 1),
  },
  tabs: {},
  tabLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    '& > i': {},
  },
})

const DesktopSearchToolbar = observer((props) => {
  const { classes, showSearch, onClickSearch = () => undefined, tabs } = props

  const { tenantConfig: tc } = useConfig()
  const cfg = tc.ui?.search?.desktop || {}
  const print = tc.ui?.print || {}

  const { track } = useAnalytics()
  const history = useHistory()

  const store = useRootStore()
  const tab = store.search.ui.selectedTab

  const homeButton = tc.ui?.search?.desktop?.homeButton

  const searchButton = useMediaQuery((theme) =>
    theme.breakpoints.down(cfg.paramsDrawer?.breakpoint || 'tb')
  )

  const componentRef = useRef()

  const handleClickHome = () => {
    track('searchToolbarButtonClick', {
      category: 'Navigation',
      label: 'Home',
    })

    history.push('/')
  }

  const handleClickToggleSearch = () => {
    track('searchToolbarButtonClick', {
      category: 'Search',
      label: 'Toggle Search Params',
    })

    onClickSearch(!showSearch)
  }

  const handleTabChange = (event, value) => {
    const tabc = tabs[value]

    track('searchToolbarSelectTab', {
      category: 'Search',
      label: tabc.title,
    })

    store.search.ui.setSelectedTab(value)
  }

  const isTabAvailableToPrint = () => {
    const availableTabIds = new Set(['services', 'events', 'favourites'])
    const selectedTabId = tabs[tab]?.id

    return availableTabIds.has(selectedTabId)
  }

  const handlePrint = useReactToPrint({
    content: () => {
      const selectedTabPanel = document.getElementById(
        `desktop-search-tabpanel-${tab}`
      )
      const searchResults = selectedTabPanel.getElementsByClassName(
        'search-results-print'
      )
      componentRef.current = searchResults[0]

      return componentRef.current
    },
  })

  return (
    <Box className={classes.root}>
      <Box className={classes.tabsWrapper}>
        {(searchButton || homeButton?.show) && (
          <Box sx={{ ml: 2, mr: 3 }}>
            {searchButton && (
              <Button
                className={classes.toolbarButton}
                color="primary"
                fontSize="inherit"
                onClick={handleClickToggleSearch}
              >
                {/* <Box component="i" sx={{ mr: 0.5 }} className="far fa-chevron-double-right fa-fw" /> */}
                <Box
                  component="i"
                  sx={{ mr: 1 }}
                  className="fas fa-search fa-fw"
                />
                Search
              </Button>
            )}

            {homeButton?.show && (
              <Button
                className={classes.toolbarButton}
                color="primary"
                fontSize="inherit"
                href={homeButton.path}
              >
                {/* <i className="fas fa-home-alt fa-fw" /> */}
                <Box
                  component="i"
                  sx={{ mr: 1 }}
                  className="fas fa-home fa-fw"
                />
                Home
              </Button>
            )}
          </Box>
        )}

        <Tabs
          className={classes.tabs}
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          // centered
        >
          {tabs.map((t, i) => (
            <Tab
              key={i}
              label={
                <div className={classes.tabLabel}>
                  {/* <i className={t.icon} /> */}
                  <Box component="i" sx={{ mr: 1 }} className={t.icon} />
                  {t.title}
                </div>
              }
            />
          ))}
        </Tabs>
      </Box>

      {print?.isShowPrintButton && isTabAvailableToPrint() && (
        <Button sx={{ mr: 3 }} onClick={handlePrint}>
          <Box component="i" sx={{ mr: 1 }} className={'fas fa-print'} />
          {'Print'}
        </Button>
      )}
    </Box>
  )
})

export default withStyles(styles, { name: 'AMSDesktopSearchToolbar' })(
  DesktopSearchToolbar
)
