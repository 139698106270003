import { buildFilter } from '../utils/filter-builder'
import { buildQuery } from '../utils/query-builder'
// import { Event } from './Event'
// import { Service } from './Service'
import { config } from '@/config'
import { analytics } from '@/analytics'
import _ from 'lodash'
import { types, flow, getRoot } from 'mobx-state-tree'
import { toJSDeep } from '../utils/mobx'

const { tenantConfig: tc } = config

// map of parameter mappings for each search

const queries = _.keyBy(tc.search.queries, 'id')

// map of build/search functions to search definition

const searchInterfaces = {
  directory: {
    buildParams: buildQuery,
  },
  diary: {
    buildParams: buildQuery,
  },
  favourites: {
    buildParams: null,
  },
}

// default location for map

const australia = {
  center: { lat: -28.6, lng: 134.0 },
  zoom: 5,
  selected: null,
}

const australial = {
  geometry: { lat: -28.6, lng: 134.0 },
  zoom: 5,
}

// export const Result = types.union(Service, Event)

export const Search = types
  .model({
    id: '',
    type: types.enumeration(Object.keys(searchInterfaces)),
    description: '',
    filterMode: types.optional(
      types.enumeration('FilterMode', [
        'filters',
        'categories',
        'tags',
        'commissioned-services',
      ]),
      'filters'
    ),
    loading: false,
    error: false,
    queryTotal: 0,
    total: 0,
    lastResultsCount: 0,
    pageSize: 30,
    // results: types.array(Result),
    showSiteSupport: false,
    resultsHeader: types.maybeNull(
      types.model({
        type: '',
        prefix: '',
      }),
      null
    ),
  })

  .volatile(() => ({
    defaultFilter: {},
    apiParams: {},
    // resultsMap: {},
  }))

  .views((self) => ({
    get helpers() {
      return {
        json: [
          'provider',
          'combinedParams',
          'apiParams',
          'filterMap',
          'activeFilters',
          'mapParams',
        ],
      }
    },

    get control() {
      return getRoot(self).search
    },

    get tenant() {
      return getRoot(self).tenant
    },

    get provider() {
      return searchInterfaces[self.type]
    },

    get query() {
      return queries[self.type]
    },

    get category() {
      return self.control.selectedInformationCategory
    },

    get paramOverrides() {
      return self.category?.searchOptions?.overrides?.queries?.[self.id]?.params
    },

    get combinedParams() {
      const combineMode = _.camelCase(self.tenant.options.combineMode)
      if (!combineMode) throw `Invalid tenant.options.combineMode`

      if (combineMode === 'filters') return { filters: self.activeFilters }

      // old style combined params (categories, tags etc.)

      const combinedParams = self.tenant.combinedParamArray(
        self.control.activeCategories,
        (mc) => mc[combineMode]
      )

      if (combinedParams?.[0] === -1)
        console.warn(
          'Combined params fell back to default, no results will be returned',
          {
            combinedParams,
          }
        )

      return { [combineMode]: combinedParams }
    },

    filter(category) {
      const so = self.tenant.metaCategoryMap[category]?.searchOptions
      if (!so) return null

      // all or single search filter
      const filter = so.filter || so[self.id]?.filter
      if (filter) return buildFilter({ builder: self.type, filter })

      // raw filter
      return so[self.id]?.rawFilter
    },

    get filterMap() {
      const res = _.mapValues(self.tenant.metaCategoryMap, (v, k) =>
        self.filter(k)
      )
      return res
    },

    get activeFilters() {
      const cats = self.control.params.selectedCategoriesMeta

      // single category select override || active categories
      const filters = (
        cats.length === 1 &&
        cats[0]?.searchOptions?.overrides?.filters?.categories === 'all'
          ? _.values(self.filterMap)
          : self.control.activeCategoriesArray.map((c) => self.filter(c))
      ).filter((f) => !!f)

      return filters
    },

    get canLoadMore() {
      return !!self.provider.canLoadMore?.(self)
    },

    hasResult(id) {
      return id in self.resultsMap
    },

    get resultsMap() {
      return _.keyBy(self.results, 'id')
    },

    get canAddFavourite() {
      return !!self.control.favouritesSearch
    },

    get locationZoomLevel() {
      const mapConfig = tc.ui?.search?.map || {}
      const location = self.control.params.location
      const defaultZoom = mapConfig?.locationZoom || 15

      if (!location || !mapConfig.zoomLevels) return defaultZoom

      const zmap = mapConfig.zoomLevels[location.type]
      if (!zmap) return defaultZoom

      return zmap[location.id] || defaultZoom
    },

    get defaultMapParams() {
      const location = self.control.params.location
      const defaultLocation = tc.search?.location?.defaultLocation

      const { geometry } = location || defaultLocation || australial

      return {
        center: geometry,
        zoom: 15,
      }
    },

    get mapParams() {
      const location = self.control.params.location
      const defaultLocation = tc.search?.location?.defaultLocation
      const selectedResult = self.control.selectedResult

      // no location

      if (!location) {
        return defaultLocation
          ? {
              center: { lat: defaultLocation.lat, lng: defaultLocation.lng },
              zoom: 15,
              selected: null,
            }
          : australia
      }

      // selected result

      if (
        selectedResult &&
        selectedResult.result.search === self.id &&
        selectedResult.result.address !== '--withheld--'
      ) {
        return {
          center: {
            lat: selectedResult.result.lat,
            lng: selectedResult.result.lng,
          },
          zoom: 17,
          selected: selectedResult.result,
        }
      }

      // default

      return {
        center: { lat: location.lat, lng: location.lng },
        zoom: self.locationZoomLevel,
        selected: null,
      }
    },

    get mapResults() {
      return self.results.filter((r) => r.address !== '--withheld--')
    },

    get combinedRawFilters() {
      const catFilters = self.control.activeCategoriesArray
        .map((c) => self.tenant.metaCategoryMap[c])
        .map((cm) => cm.searchOptions?.[self.id]?.rawFilter)
        .filter((f) => !!f)

      return [
        {
          bool: {
            should: [self.defaultFilter, ...catFilters],
            minimum_should_match: 1,
          },
        },
      ]
    },

    get queryProps() {
      // console.log('getQueryOptions', self.type)

      const topts = self.tenant.options

      const location = self.control.params.location

      const provider = self.provider || {}

      // info only categories don't have searches

      const isInformationOnly =
        topts.categoryMode === 'single' &&
        topts.informationCategories &&
        !self.category.search

      const enabled =
        self.id !== 'favourites' &&
        !isInformationOnly &&
        _.isFunction(provider.buildParams) &&
        !!location

      const allParams =
        location &&
        provider.buildParams?.({
          id: self.id,
          query: queries[self.id],
          params: {
            pageSize: self.pageSize,
            ...self.control.params,
            ...self.combinedParams,
          },
          paramOverrides: self.paramOverrides,
        })

      const pageParam = _.pick(allParams, provider.pageParamKey)
      const params = _.omit(allParams, provider.pageParam)

      return {
        searchId: self.id,
        searchType: self.type,
        querySummary: self.control.querySummary,
        pageSize: self.pageSize,
        enabled,
        isInformationOnly,
        location,
        pageParam,
        params,
      }
    },
  }))

  .actions((self) => ({
    setDefaultFilter(filter) {
      self.defaultFilter = filter
    },

    setResults(results) {
      self.results = results
    },

    setLoading(loading) {
      self.loading = loading
    },

    setError(error) {
      self.error = error
    },

    setQueryTotal(total) {
      self.queryTotal = total
    },

    /**
     * @deprecated
     */
    clear() {
      self.loading = false
      self.error = false
      // self.clearResults()
      // self.resultsMap.clear()
      self.total = 0
    },

    // favourites

    /**
     * @deprecated
     */
    insert(result) {
      if (self.hasResult(result.id)) return false

      self.results = [result, ...self.results]
      // self.resultsMap[result.id] = result

      return true
    },

    removeById(id) {
      if (!self.hasResult(id)) return false

      //
      if (
        self.control.selectedResult &&
        self.control.selectedResult.id === id
      ) {
        self.control.setSelected(null)
      }

      const ri = self.results.findIndex((r) => r.id === id)
      self.results.splice(ri, 1)
      // delete self.resultsMap[id]

      return true
    },

    initSearch() {
      analytics.track('searchImplInit', {
        category: 'SearchImpl',
        label: self.id,
      })

      if (self.type === 'favourites') return

      self.clear()

      const topts = self.tenant.options

      if (
        topts.categoryMode === 'single' &&
        topts.informationCategories &&
        !self.category.search
      )
        return

      if (!self.provider.buildParams) return
      if (!self.control.params.location) return

      const query = queries[self.id]
      if (!query) return

      const buildParams = self.provider.buildParams || buildQuery

      // console.log({ combinedParams: self.combinedParams })

      self.apiParams = buildParams({
        id: self.id,
        query: queries[self.id],
        params: {
          pageSize: self.pageSize,
          ...self.control.params,
          ...self.combinedParams,
        },
        paramOverrides: self.paramOverrides,
      })

      // self.performSearch()
    },

    setSearchResultsFromReact(res) {
      if (!res) {
        self.clea
      }
      const resUnique = res.results.map((r) => ({
        ...r,
        id: `${self.id}_${r.id}`,
        search: self.id,
      }))

      self.results = [...self.results, ...resUnique]

      analytics.track('searchImplPerformSearchResults', {
        category: 'SearchImpl',
        label: self.id,
        value: res.total,
      })

      if (res.total > 0) {
        self.total = res.total
      }

      self.lastResultsCount = res.results.length
    },

    tryAgain() {
      analytics.track('searchImplTryAgain', {
        category: 'SearchImpl',
        label: self.id,
      })

      if (self.type === 'favourites') return

      self.control.initSearch()
    },

    loadMore() {
      analytics.track('searchImplLoadMore', {
        category: 'SearchImpl',
        label: self.id,
      })

      if (self.type === 'favourites') return
      if (!self.provider.search) return
      if (!self.canLoadMore) return

      if (self.provider.nextPage) self.apiParams = self.provider.nextPage(self)

      // else {
      //   self.apiParams = {
      //     ...self.apiParams,
      //     // PageSize: self.pageSize,
      //     pageNo: self.apiParams.pageNo + 1,
      //     // PageNumber: self.results.length / self.pageSize + 1,
      //   }
      // }

      // self.performSearch()
    },
    // }))
    // .actions(self => ({

    updateResults(queryResults) {
      const { result = [], total = 0 } = queryResults
      self.results = result
      self.total = total
    },

    performSearch: flow(function* performSearch() {
      analytics.track('searchImplPerformSearch', {
        category: 'SearchImpl',
        label: self.id,
        searchParams: self.apiParams,
      })

      try {
        self.setLoading(true)

        const apiParams = self.apiParamsObs

        const res = yield self.provider.search(self.apiParams)

        const resUnique = res.results.map((r) => ({
          ...r,
          id: `${self.id}_${r.id}`,
          search: self.id,
        }))

        self.results = [...self.results, ...resUnique]

        analytics.track('searchImplPerformSearchResults', {
          category: 'SearchImpl',
          label: self.id,
          value: res.total,
        })

        if (res.total > 0) {
          self.total = res.total
        }

        self.lastResultsCount = res.results.length
      } catch (error) {
        console.error(error)
        self.setError(true)
      } finally {
        self.setLoading(false)
      }
    }),
  }))
