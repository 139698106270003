import _ from 'lodash'

import {
  ApiOutletModel,
  ApiServiceChangeModel,
  ApiOutletModelAddressSummary,
} from '@ciss/cie-api-orval'

import { removeNulls } from '@/utils/types'

/**
 * Converts distance in m to friendly
 *
 * @param distance Distance in meters
 * @category Utils
 */
export const formatDistance = (distance: number) => {
  if (distance < 0.1) return '< 100 m'
  if (distance < 1.0) return `${(distance * 1000).toFixed(0)} m`
  return `${distance.toFixed(2)} Km`
}

/**
 * Converts full address sumary to multi-line address
 *
 * Not implemented yet
 *
 * @category Utils
 * @param addressSummary Address Fields from Outlet
 */
export const formatAddress = (
  addressSummary?: ApiOutletModelAddressSummary
) => {
  if (!addressSummary) return

  const { buildingAddress, address1, address2, suburb, state, postcode } =
    addressSummary

  const lines = [
    [buildingAddress, address1].join(', '),
    address2 || '',
    [suburb, state, postcode].join(' '),
  ].filter((l) => l.length || removeNulls(l))

  return lines
}

/**
 * Selects the first valid service changes
 *
 * @category Utils
 * @param serviceChanges All service changes for an Outlet
 */
export const convertServiceChanges = (
  serviceChanges?: ApiServiceChangeModel[]
) => {
  if (!serviceChanges) return
  if (!serviceChanges || serviceChanges.length < 0) return

  const now = new Date()

  const svc = serviceChanges
    .map((s) => s as Required<ApiServiceChangeModel>)
    .sort((a, b) => a.id - b.id)
    .find((x) => new Date(x.startDate) <= now && new Date(x.endDate) >= now)

  return svc
}
