import _ from 'lodash'
import { toJS, observe, computed } from 'mobx'
import deepmerge from 'deepmerge'
import { Liquid } from 'liquidjs'

import { config } from '@/config'
import { useLocation, matchPath } from 'react-router-dom'

const liquid = new Liquid()

// top level route config

export const defaultMetaConfig = {
  templates: {
    robots: 'all',
    // canonical: 'default',
    titlePrefix: '{{ tenant.options.title }}',
    title: '',
    description: '{{ tenant.options.title }} Description.',
  },
}

const tkeys = _.keys(defaultMetaConfig.templates)

const migrateTemplates = (route) => {
  const { meta, ...other } = route || {}
  const { templates } = meta || {}
  if (templates) return route

  return {
    ...other,
    meta: {
      templates: _.pick(meta, tkeys),
      ..._.omit(meta, tkeys),
    },
  }
}

const explicitMatchParams = (route) => {
  return {
    ...route,
    exact: !!route.exact,
    strict: !!route.strict,
  }
}

export const prepareRoutes = (routes) => {
  if (!_.isArray(routes)) return

  return routes.map(migrateTemplates).map(explicitMatchParams)
}

export const loadRouteConfig = (config) => {
  const { tenantConfig: tc } = config

  const { routes: r, meta: m, routeOptions } = tc

  const routes = prepareRoutes(tc?.routes)
  const meta = deepmerge(defaultMetaConfig, tc?.meta)

  return {
    routes,
    meta,
    templateKeys: tkeys,
    ...routeOptions,
  }
}

// route utils

export const matchRoute = (routes, location) => {
  if (!location) return null
  for (let index = 0; index < routes.length; ++index) {
    const routeConfig = routes[index]
    const { path, exact, strict } = routeConfig

    const match = matchPath(location.pathname, { path, exact, strict })
    if (match) return { index, config: routeConfig, match, location }
  }
}

export const generatePath = (item) => {
  const { pathType, paths, source, params, queryParams } = item
  if (!pathType || !params) return

  const pathOpts = paths[pathType]
  if (!pathOpts) return

  // this is a hack, needs to be futher upstream
  const substituteParams = ({ params }) => {
    if (!pathOpts.canonical || !source) return params

    return _.mapValues(params, (v, k) => {
      const ck = pathOpts.canonical?.[k]
      const cv = ck && source[ck]
      return cv || v
    })
  }

  const sp = substituteParams({ params })

  const path = _.compact([generatePath(pathOpts.path, sp), queryParams]).join(
    '?'
  )

  return {
    to: path,
    linkType: pathOpts.linkType,
  }
}

// meta config utils

// deep merge meta configs
// configs are applied from l to r, from: defaults -> tenantConfig.meta -> routeConfig.meta
// templates can also include template overrides keyed by specific paths where multiple paths
// are specified for a route

export const mergeMetaConfigs = (metaConfigs = [], options) => {
  const { templateKeys = tkeys, path } = options

  const templates = _.chain(metaConfigs)
    .map((c) => [c?.templates, c?.templates?.[path]])
    .flatten()
    .compact()
    .thru(deepmerge.all)
    .pick(templateKeys)
    .value()

  const otherOptions = _.chain(metaConfigs)
    .map((c) => _.omit(c, ['templates']))
    .compact()
    .thru(deepmerge.all)
    .value()

  return {
    ...otherOptions,
    templates,
  }
}

// meta templating utils

export const buildMetaItems = ({
  renderedTemplates,
  matchedRoute,
  templateView,
  pathInfo,
}) => {
  // console.log({ pathInfo })
  return {
    ...(pathInfo.canonical && {
      canonical: {
        component: 'link',
        rel: 'canonical',
        // TODO: reread docs and migrate previous builder below
        href: pathInfo.canonical,
      },
    }),
    robots: {
      component: 'meta',
      name: 'robots',
      content: renderedTemplates.robots,
    },
    title: {
      component: 'title',
      innerContent: [renderedTemplates.titlePrefix, renderedTemplates.title]
        .filter((s) => !!s)
        .join(' - '),
    },
    description: {
      component: 'meta',
      name: 'description',
      content: renderedTemplates.description,
    },
  }
}
