import range from 'lodash/range'

// calculate summary of kessler distress scales k-5 & k-10

export const evalKessler = (question, values, scores) => {
  const total = values.reduce((acc, cur, i, src) => {
    return acc + (
      !cur ? 0
      : question.questions[i].followPrevious && src[i - 1] === 1 ? 1
      : cur
    )
  }, 0)

  const score = total in scores ? scores[total].id : 'none'

  return {
    score,
    total,
  }
}

// fast lookup of kessler scoring

export const cacheScores = scores => {
  return scores.reduce((acc, cur) => {
    range(cur.score[0], cur.score[1] + 1)
     .forEach(s => acc[s] = cur)
    return acc
  }, {})
}
