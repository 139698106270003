import React, { useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import { Box, Typography, Button } from '@mui/material'

const NotFound = () => {
  // const history = useHistory()
  // console.log(history)

  return (
    <Box textAlign="center">
      <Box my={4}>
        <Typography variant="h3">404</Typography>
        <Typography variant="body1">Oops! We could not find the requested page.</Typography>
      </Box>

      <Button variant="outlined" color="primary" href="/">
        <i className="fas fa-home" />
        &nbsp;&nbsp;Home
      </Button>
    </Box>
  )
}

export default NotFound
