import React, { useEffect, useState } from 'react'
// import { useConfig } from 'config/config'
import { useRootStore } from '@/stores/root/store'
import { useQuery } from 'react-query'
import { getGeographyMembers, getOutletsByOrgId } from '@/api/legacy/cie-api'
import { observer } from 'mobx-react-lite'
import _ from 'lodash'
import { makeStyles, withStyles } from '@mui/styles'
import { Collapse, Fade, Button } from '@mui/material'

import clsx from 'clsx'

// import { getGeographySelectOptions } from 'data/api/geography'

import { Select, MenuItem } from '@mui/material'

const style = {
  wrapper: {
    display: 'flex',
  },
  title: {
    marginBottom: '5px',
  },
}

const CategoryButton = withStyles(
  () => ({
    root: {
      justifyContent: 'flex-start',
      textAlign: 'left',
      textTransform: 'none',
      fontWeight: 'normal',
      marginBottom: '4px',
    },
  }),
  { name: 'AMSCategoryButton' }
)(Button)

// eslint-disable-next-line react/display-name
const LocationsDisplay = observer(() => {
  const store = useRootStore()
  const location = store.search.params.location
  const qparam = _.pick(location, ['id', 'lat', 'lng'])

  const [hhs, setHHS] = useState(null)
  const [hhsOutlets, setHHSOutlets] = useState([])
  const [isCollapse, setIsCollapse] = useState(true)

  useEffect(() => {
    const dataRsp = async () => {
      const rsp = await getGeographyMembers(qparam)
      const hhsdata = rsp.filter((x) => x.dataset == 'HHS')
      setHHS(hhsdata[0])
      // console.log('hhsdata: ', hhsdata)
      if (hhsdata.length > 0 && hhsdata[0]?.member != undefined) {
        const outletData = await getOutletsByOrgId(
          hhsdata[0]?.member?.organisationId
        )
        setHHSOutlets(outletData.data)
      }
    }

    dataRsp()
  }, [location])

  const redirectToCalendar = (url) => (e) => {
    e.preventDefault()
    window.location.href = window.location.origin + `/outleteventcalendar${url}`
  }

  const handleClick = () => {
    setIsCollapse(!isCollapse)
  }

  const icon = isCollapse ? '-' : '+'

  return (
    <div>
      {hhs?.member != undefined && hhsOutlets.length > 0 && (
        <>
          <div style={style.wrapper}>
            <div style={style.title}>
              {hhs && `Locations in ${hhs?.member?.preferredName}:`}
            </div>
            <Button color="primary" size="big" onClick={handleClick}>
              <b>{icon}</b>
            </Button>
          </div>

          <Collapse in={isCollapse}>
            {hhsOutlets.map((outlet, i) => (
              <React.Fragment key={i}>
                <CategoryButton
                  // key={i}
                  className="button"
                  disableElevation
                  // size="small"
                  variant="contained"
                  color="default"
                  fullWidth
                  onClick={redirectToCalendar(outlet.Url)}
                >
                  {outlet.Name}
                </CategoryButton>
              </React.Fragment>
            ))}
          </Collapse>
        </>
      )}
    </div>
  )
})

export default LocationsDisplay
