import React from 'react'

import _ from 'lodash'
import clsx from 'clsx'
import { Link as RouterLink } from 'react-router-dom'

import { withStyles } from '@mui/styles'
import {
  Container,
  Typography,
  Divider,
  Grid,
  Link as MuiLink,
} from '@mui/material'

import Markdown from '@/ui/markdown/Markdown'

import { useConfig } from '@/config'
import { useAnalytics } from 'use-analytics'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    margin: theme.spacing(0.5, 0),
    fontSize: '0.86rem',
  },
  icon: {
    marginRight: theme.spacing(1.5),
  },
  link: {
    color: theme.palette.text.primary,
  },
})

const StaticMenuItem = ({ classes, icon, label, route, href }) => {
  const { track } = useAnalytics()

  const handleClick = () => {
    track('footerMenuItemClick', {
      category: 'Navigation',
      label: label,
    })
  }

  const linkProps = route
    ? {
        to: route,
        component: RouterLink,
        onClick: handleClick,
      }
    : href
    ? {
        href,
        target: '_blank',
      }
    : null

  const innerItem = (
    <div className={classes.item}>
      <div className={classes.icon}>
        <i className={clsx(icon, 'fa-fw')} />
      </div>
      <div>
        {label.split('\n').map((l, i) => (
          <div key={i}>{l}</div>
        ))}
      </div>
    </div>
  )

  return linkProps ? (
    <MuiLink {...linkProps} underline="none" className={classes.link}>
      {innerItem}
    </MuiLink>
  ) : (
    innerItem
  )
}

const StaticMenu = ({ classes, menu: menuId, hide }) => {
  const { tenantConfig: tc } = useConfig()
  const menu = tc.ui?.menus?.[menuId]
  if (!menu) return null

  const items = (menu.items || [])
    .filter((i) => !(i.hide || []).includes(hide))
    .map((item, i) => <StaticMenuItem key={i} classes={classes} {...item} />)

  return <div className={classes.root}>{items}</div>
}

export default withStyles(styles, { name: 'AMSStaticMenu' })(StaticMenu)
