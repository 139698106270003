import { useResultsControl } from './results-control'
import {
  Box,
  CircularProgress,
  LinearProgress,
  Button,
  Typography,
  Divider,
  useMediaQuery,
  Fade,
  linearProgressClasses,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTheme, withStyles } from '@mui/styles'
import clsx from 'clsx'
import { useConfig } from '@/config'
import {
  SearchQueryHost,
  useSearchResults,
  SearchQueryDebug,
} from '@/api/search-query'
import { useRootStore, useStoreSearch } from '@/stores/root/store'
import { toEnglishList } from '@/utils'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useCallback, forwardRef } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import scrollIntoView from 'scroll-into-view-if-needed'
import { EmphasisEnglishList } from '@/ui/components/text/EmphasisEnglishList'
import Acknowledgement from '@/ui/footer/Acknowledgement'
import Sponsors from '@/ui/footer/Sponsors'
import { useParentMutationObservers } from '@/ui/hooks/useParentMutationObservers'
// import { useCountRenders } from '@/ui/hooks/useCountRenders'
import CovidAlert from './CovidAlert'
import Promotions from './Promotions'
import SearchResultItem from './SearchResult'
import SearchResultsMarkdown from './SearchResultsMarkdown'
import { TenantThemeProvider } from '@/ui/theme/TenantTheme'
import { useAnalytics } from 'use-analytics'
import ErrorBoundary from '@/ui/components/atoms/ErrorBoundary'
import { useInView } from 'react-intersection-observer'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    paddingBottom: theme.spacing(2),
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    // justifyContent: 'center',
    padding: theme.spacing(2),
  },
  navLink: {
    textDecoration: 'none',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
  },
  alertBlock: {},
  initBlock: {},
  statusSponsorBlock: {},
  statusHeaderBlock: {},
  resultsBlock: {},
  statusBlock: {
    padding: theme.spacing(4, 0, 4),
  },
  footerBlock: {},
  // inner results
  results: {
    backgroundColor: '#fafafa',
    borderTop: '1px solid rgba(0, 0, 0, 0.07)',
    // boxShadow: '1px 2px 3px -1px rgba(0, 0, 0, 0.2) inset', // [shadow, shadow, noShadow, noShadow],
    [theme.breakpoints.down('ph')]: {
      // boxShadow: 'none',
      // borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    },
  },
})

/**
 * Container for controls like  etc.
 *
 * Uses `data-nosnippet` (googlebot ignore)
 *
 * @param props.sx {object} Mui sx (hack)
 * @param props.children {object} Wrapped components
 * @param [...] (object) Additional spread props passed to container Box
 */
const ActionContainer = ({ sx, children, ...props }) => {
  return (
    <Box
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        ...sx,
      }}
      {...props}
      data-nosnippet
    >
      {children}
    </Box>
  )
}

/**
 * Fallback component displayed when `store.search.params.location === null`
 *
 * This populates props by default from `store.search._searches[searchId].welcome`
 *
 * @param props.store {object} store
 * @param props.title {string} Title text, e.g. 'Welcome to...'
 * @param props.body {string} Usually instructions to select location
 * @param props.componentProps {object} See config
 */
const Welcome = observer((props) => {
  const { store } = props
  const w = store.search.welcome

  const { title = w.title, body = w.body, componentProps = {} } = props

  const {
    container: containerProps,
    root: rootProps,
    title: titleProps,
    body: bodyProps,
  } = componentProps

  if (store.search.params.location) return null

  return (
    <ActionContainer sx={{ mt: 4, alignItems: 'stretch' }} {...containerProps}>
      <Box sx={{ textAlign: 'center' }} {...rootProps}>
        <Typography
          variant="h6"
          sx={{ mb: 1, fontSize: '1rem' }}
          {...titleProps}
        >
          {title}
        </Typography>

        <Typography variant="body1" {...bodyProps}>
          {body}
        </Typography>
      </Box>
    </ActionContainer>
  )
})

/**
 * List of SearchResult items
 *
 * @param props.classes {object} Mui classes
 * @param props.search {object} store.search._searches[searchId]
 * @param props.params {object} store.search.searchParams
 */
const SearchResults = observer(({ classes, search, store }) => {
  const { track } = useAnalytics()

  const renderResults = true

  const query = useSearchResults(search.id)
  const results = query?.results?.flat
  // const results = _.flatten(query?.data?.pages) ||

  const { selectedResult, setSelectedResult } = useResultsControl({
    subscriber: { searchId: search.id, control: 'results' },
  })

  const handleClickResult = useCallback(
    (result) => {
      track('searchResultsResultClick', {
        category: 'SearchResults',
        label: result.name || 'unknown',
      })

      setSelectedResult(result)
    },
    [store.search, track]
  )

  if (!results || !results.length) return null

  return (
    <Box className={classes.results + ' search-results-print'} sx={{ py: 0.5 }}>
      {renderResults &&
        results.map((data, i) => (
          <Fade key={i} timeout={1000} in={true}>
            <div>
              <SearchResultItem
                // search={search}
                data={data}
                selected={data.id === selectedResult?.result?.id}
                onClickResult={handleClickResult}
                variant="list"
              />
            </div>
          </Fade>
        ))}
    </Box>
  )
})

/**
 * Displays text results, formatted (in theory) for SEO
 *
 * Usually in statusHeaderBlock
 *
 * Previously this showed 'Searching for', however with cached react-query results this
 * results in blipping between a queryKey change, and the cached results rendering
 *
 * @param props.search {object} store.search._searches[searchId]
 */
const StatusHeader = observer(({ search }) => {
  const query = useSearchResults(search.id)

  const count = query?.results?.flat.length

  const {
    location,
    categories,
    maxVerboseCategories = 3,
  } = query?.querySummary || {}

  if (!count || !location || !categories) return null

  const Line = (props) => (
    <Typography
      component="div"
      variant="body1"
      fontSize="0.937rem"
      {...props}
    />
  )

  return (
    <Box sx={{ p: 2 }}>
      <Line>
        {`Top `}
        <strong>{`${count}`}</strong>
        {' results for'}
      </Line>
      <Line sx={{ my: 1 }}>
        <EmphasisEnglishList
          items={categories}
          maxItems={maxVerboseCategories}
        />
      </Line>
      <Line sx={{ my: 1 }}>
        {'in '}
        <EmphasisEnglishList items={location} useTerminator={false} />
      </Line>
    </Box>
  )
})

/**
 * Styled LinearProgress
 */
const GreyPrimaryLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: 2,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 2,
  },
}))

/**
 * Bottom status of search query.
 *
 * @param props.classes {object} Mui classes
 * @param props.search {object} store.search._searches[searchId]
 * @param props.params {object} store.search.searchParams
 */
const StatusFooter = observer(({ classes, search, params }) => {
  const query = useSearchResults(search.id)
  const { ref, inView, entry } = useInView({})

  useEffect(() => {
    if (inView && query.hasNextPage) {
      query.fetchNextPage()
    }
  }, [inView])

  if (!params.location) return null
  if (!query) return null

  const results = query.results?.flat

  const StatusFooterInner = () => {
    if (query.isLoading || query.isFetching || query.isFetchingNextPage)
      return (
        <Box sx={{ p: 4 }}>
          <GreyPrimaryLinearProgress />
        </Box>
      )

    if (query.isError)
      return (
        <ActionContainer>
          <Typography variant="body1">
            There was an error fetching results.
          </Typography>
          <br />

          <Button
            className={classes.loadMore}
            variant="outlined"
            onClick={query.refetch}
          >
            Try Again
          </Button>
        </ActionContainer>
      )

    if (query.hasNextPage)
      return (
        <ActionContainer>
          <Button
            className={classes.loadMore}
            variant="outlined"
            onClick={query.fetchNextPage}
          >
            Load More
          </Button>
        </ActionContainer>
      )

    if (results)
      return (
        <ActionContainer>
          <Typography variant="body1">
            {results.length} Result{results.length !== 1 && 's'} Found
          </Typography>
        </ActionContainer>
      )

    return null
  }

  return (
    // intersection observer ref
    <Box ref={ref}>
      <StatusFooterInner />
    </Box>
  )
})

/**
 * Common Site Support Button
 *
 * @param props.search {object} store.search._searches[searchId]
 */
const SiteSupport = observer(({ search }) => {
  const { track } = useAnalytics()

  const handleClickSiteSupport = useCallback(() => {
    track('searchResultsButtonClick', {
      category: 'SearchResults',
      label: 'Site Support',
    })
  }, [track])

  if (search.loading) return null

  return (
    <ActionContainer>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Can&apos;t find what your looking for?
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        to="/support"
        component={ReactRouterLink}
        onClick={handleClickSiteSupport}
      >
        <Box mr={1}>
          <i className="fas fa-user-headset" />
        </Box>
        Contact Site Support
      </Button>
    </ActionContainer>
  )
})

/**
 * Helper status for Favourites
 *
 * Displays instructions if favourites is empty
 */
const FavouritesStatus = observer(({ classes, search }) => {
  const query = useSearchResults(search.id)

  if (!query?.results) return null
  const resultsCount = query.results.flat.length

  if (resultsCount === 0)
    return (
      <div className={classes.actionContainer} data-nosnippet>
        <Typography variant="body1" align="center">
          You have no results in your favourites list.
          <br />
          Add items by clicking the <i className="far fa-star" /> icon in the
          search results.
        </Typography>
      </div>
    )

  return (
    <ActionContainer sx={{ alignItems: 'stretch' }}>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="body1">
          {`${resultsCount}`} Result{resultsCount > 1 && 's'} Found
        </Typography>
      </Box>
    </ActionContainer>
  )
})

// Internal wrappers filter out {classes} from props as they have their own styles

const DividerWrapper = ({ classes, ...innerProps }) => (
  <Box px={1}>
    <Divider {...innerProps} />
  </Box>
)

const CovidAlertWrapper = ({ classes, ...innerProps }) => (
  <CovidAlert {...innerProps} />
)

const SponsorsWrapper = ({ classes, ...innerProps }) => (
  <Sponsors {...innerProps} variant="ie11" />
)

const AcknowledgementWrapper = ({ classes, ...innerProps }) => (
  <Acknowledgement {...innerProps} />
)

/**
 * Wrapper for <SearchResultsMarkdown />
 *
 * Loads markdown for contentPrefix from store
 */
const SearchResultsMarkdownWrapper = observer(({ search, contentPrefix }) => {
  const { content } = search.control.getSelectedInformation(contentPrefix) || {}
  return <SearchResultsMarkdown content={content} />
})

/**
 * Component map for mapping component name (string) to Component
 */
const componentMap = {
  Welcome,
  Results: SearchResults,
  StatusHeader,
  Status: StatusFooter,
  FavouritesStatus,
  SiteSupport,
  Divider: DividerWrapper,
  CovidAlert: CovidAlertWrapper,
  Promotions,
  Sponsors: SponsorsWrapper,
  Acknowledgement: AcknowledgementWrapper,
  SearchResultsMarkdown: SearchResultsMarkdownWrapper,
}

/**
 * Renders a list of SearchResultSection components (aka blocks)
 *
 * Sections support a nested 'theme' variant to configure responsive styling of Sponsors
 *
 * @param props.sections {object} section config
 * @param props.commonProps {object} Props passed to most children,
 * i.e.{ classes, store, search, params }
 */
const SearchResultsSections = ({ sections, commonProps }) => {
  const { classes } = commonProps

  return (
    <ErrorBoundary>
      {sections.map(({ type, items }, key) => {
        const sectionItems = items
          .map(({ component, theme, props }, key) => {
            const cprops = { ...commonProps, ...props }
            const C = componentMap[component]
            if (!C) {
              console.warn(`Invalid component ${component}`)
              return null
            }

            if (!theme) return <C key={key} {...cprops} />

            return (
              <TenantThemeProvider key={key} theme={theme}>
                <C {...cprops} />
              </TenantThemeProvider>
            )
          })
          .filter((c) => !!c)

        return (
          <Box key={key} className={clsx(classes.section, classes[type])}>
            {sectionItems}
          </Box>
        )
      })}
    </ErrorBoundary>
  )
}

/**
 * Main component for rendering search results
 *
 * Handles scrolling events and SearchResult selection via useResultsControl
 * Renders <SearchResultSections />
 *
 * @param props.classes Mui styles
 * @param props.searchId {string} Search (Tab)
 */
const SearchResultsListInner = observer(({ classes, searchId }) => {
  const { track } = useAnalytics()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('ph'))

  const store = useRootStore()
  const { params } = store.search
  const search = store.search.searches.get(searchId)

  const query = useSearchResults(searchId)

  const { tenantConfig: tc } = useConfig()
  const srl = tc.ui?.search?.resultsList
  const cfg = srl?.[searchId] || srl?.default

  const rootRef = React.useRef()

  const { selectedResult, setSelectedResult } = useResultsControl({
    subscriber: { searchId, control: 'results' },
  })

  // scroll to top callback

  const scrollToTop = React.useCallback(() => {
    const el = document.getElementById(`search-results-top-${searchId}`)

    scrollIntoView(el, {
      scrollMode: 'always',
      block: 'start',
    })
  }, [])

  // scroll to selected callback

  const scrollToSelected = React.useCallback(() => {
    const { subscriber, result } = selectedResult
    if (subscriber?.control === 'results') return
    if (!result) return

    const el = document.getElementById(`result-${result?.id}`)
    if (!el) return

    scrollIntoView(el, {
      scollMode: 'always',
      behavior: 'smooth',
      block: 'start',
    })
  }, [selectedResult])

  const scrollToSelectedCallbackRef = React.useRef({ callback: undefined })

  useEffect(() => {
    scrollToSelectedCallbackRef.current.callback = scrollToSelected
  }, [scrollToSelected, scrollToSelectedCallbackRef])

  // scroll to top on new search

  useEffect(() => {
    scrollToTop()
  }, [store.search.searchCount, searchId, scrollToTop])

  // scroll to selected on selection change

  useEffect(() => {
    scrollToSelected()
  }, [selectedResult, scrollToSelected])

  // scroll to selection on parent mutation (display: none)

  useParentMutationObservers({
    element: rootRef.current,
    mutationObservers: _.compact([
      {
        selector: 'div[role="tabpanel"]',
        onMutation: (...args) =>
          scrollToSelectedCallbackRef?.current?.callback(...args),
      },
      isMobile && {
        selector: 'div[role="resultslist"]',
        onMutation: (...args) =>
          scrollToSelectedCallbackRef?.current?.callback(...args),
      },
    ]),
  })

  // click outside

  const handleClickOutside = useCallback(
    (e) => {
      track('searchResultsResultClickOutside', {
        category: 'SearchResults',
        label: 'Click Outside',
      })

      e.stopPropagation()
      setSelectedResult()
    },
    [store.search, track]
  )

  const sections = cfg?.sections || []
  const commonProps = { classes, store, search, params }

  return (
    <div ref={rootRef} className={classes.root} onClick={handleClickOutside}>
      <SearchResultsSections
        searchId={searchId}
        commonProps={commonProps}
        sections={sections}
      />
    </div>
  )
})

/**
 * Entry for SearchResultsList
 *
 * Searches are performed by SearchQueryHost
 *
 * React Query results (or Favourites) are available via useSearchResults
 *
 * @param props.classes {object} Mui classes
 * @param props.searchId {string} Search (Tab)
 */
const SearchResultsList = observer((props) => {
  const { classes, searchId } = props

  const search = useStoreSearch(searchId)
  const queryProps = search.queryProps

  // console.log({ searchId, queryProps })

  return (
    <>
      <div id={`search-results-top-${searchId}`}></div>

      <SearchQueryHost queryProps={queryProps} />
      {/* <SearchQueryDebug queryProps={queryProps} /> */}

      <SearchResultsListInner {...props} />
    </>
  )
})

export default withStyles(styles, { name: 'AMSSearchResultsList' })(
  SearchResultsList
)
