import React from 'react'

import { Link as ReactRouterLink } from 'react-router-dom'

// import { makeStyles } from '@mui/material/styles'
import { Link as MuiLink } from '@mui/material'

import { useConfig } from '@/config'

// const { appConfig } = useConfig()

export const BaseLink = ({ ...linkProps }) => {
  const mergeProps = {
    variant: 'body1',
    color: 'primary',
    underline: 'none',
    ...linkProps,
  }

  return <MuiLink {...mergeProps} />
}

export const RouterLink = ({ ...linkProps }) => {
  return <BaseLink component={ReactRouterLink} {...linkProps} />
}

export const CieLink = ({ type, path, ...linkProps }) => {
  const { appConfig } = useConfig()

  const bases = {
    service: appConfig.directoryURL,
    event: appConfig.diaryURL,
  }

  const href =
    type && path && type in bases
      ? `${bases[type]}${path}`
      : 'https://www.mycommunitydirectory.com.au/404'

  // const preventDefault = event => {
  //   event.preventDefault()
  //   window.open(href, '_blank', 'noopener,noreferrer')
  // }

  return (
    <BaseLink
      // href="#"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      {...linkProps}
      // onClick={preventDefault}
    />
  )
}
