import React from 'react'
import { observer } from 'mobx-react-lite'

// import Typography from '@mui/material/Typography'
// import Divider from '@mui/material/Divider'

import SimpleMarkdown from './SimpleMarkdown'

import { useRootStore } from '@/stores/root/store'
import { useRouteStore } from '@/stores'

const StaticMarkdown = observer((props) => {
  const store = useRootStore()
  const route = useRouteStore()

  const content = store.search.getStaticContent(props.contentId)

  return (
    <div>
      <SimpleMarkdown>{content.body}</SimpleMarkdown>
    </div>
  )
})

export default StaticMarkdown
