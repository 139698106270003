import { atom, useAtom } from 'jotai'
import { atomFamily, useAtomValue } from 'jotai/utils'
import { useInfiniteResultsTransform } from './infinite-results-transform'
import { serviceResultTransformer, eventResultTransformer } from '../models'
import {
  ApiResponse,
  SearchQueryProps,
  SearchResult,
  SearchInnerQueryReturnType,
  SearchResultsBase,
  SearchResultsReturnType,
} from './types'
import {
  searchDirectory,
  searchDiary,
  ApiSharedSearchResponseOfApiSearchResultModel,
  ApiSharedSearchResponseOfEventDataViewModel,
  ApiSearchResultModel,
  SearchRequestModelBody,
  getSearchDirectoryQueryKey,
  getSearchDiaryQueryKey,
  ApiRequestModel,
  EventDataViewModel,
} from '@ciss/cie-api-orval'
import _ from 'lodash'
import { useEffect } from 'react'
import {
  hashQueryKey,
  useInfiniteQuery,
  QueryFunction,
  GetNextPageParamFunction,
  QueryKey,
  InfiniteData,
} from 'react-query'
import { useFavourites } from './favourites'

// type R = ApiSharedSearchResponseOfApiSearchResultModel

// type ModelForApiListResponse<T extends { data?: unknown[] }> = NonNullable<
//   T['data']
// >[number]

// type DResp = ModelForApiListResponse<R>

/**
 * Atom family containing search query results
 *
 * @category Atoms
 * @param searchId Id of the search (tab)
 */
export const searchResultsAtomFamily = atomFamily((searchId: string) =>
  atom<SearchResultsReturnType | undefined>(undefined)
)

/**
 * Hook to get readonly contents of {@link searchResultsAtomFamily}
 *
 * @category Hooks
 * @param searchId Id of the search (tab)
 */
export const useSearchResults = (searchId: string) => {
  // console.log(searchQueryAtomFamily)
  return useAtomValue(searchResultsAtomFamily(searchId))
}

/**
 * Read/Write hook used by {@link useUpdateSearchResultsAtom} to diff
 * & update search results
 *
 * @category Hooks
 * @param searchId Id of the search (tab)
 */
export const useSearchResultsAtom = (searchId: string) =>
  useAtom(searchResultsAtomFamily(searchId))

/**
 * Forwards search results to atom for use by {@link useSearchResults}
 *
 * @category Hooks
 * @internal
 */
const useUpdateSearchResultsAtom = (
  searchId: string,
  results: SearchResultsReturnType
) => {
  const [, updateResults] = useSearchResultsAtom(searchId)

  const base = results as SearchResultsBase
  const query = results as SearchInnerQueryReturnType

  const effectArray =
    'queryKey' in results
      ? [
          hashQueryKey(results.queryKey),
          query.status,
          query.hasNextPage,
          query.isFetchingNextPage,
        ]
      : [results.results?.flat.map((r) => r.id).join(',')]

  useEffect(() => {
    // console.log('updating atom', { searchId, results })
    updateResults(results)
  }, effectArray)
}

/**
 * Generic wrapper for {@link useDirectorySearch} & {@link useDiarySearch}
 *
 * @category Hooks
 * @internal
 * @template TResponse The response returned by CieApi
 * @template TModel The internal Model (i.e. response.data)
 * @template TParams Body request
 * @param props.queryKeyFn Orval queryKey generator function
 * @param props.cieApiFn Orval api function
 * @param props.nextPageParam i.e. pageNumber or pageNo
 * @param props.transformer transformer used by {@link useInfiniteResultsTransform}
 */
export const useCieInfiniteSearch = <
  TResponse extends { data?: TModel[] },
  TModel = unknown,
  TParams = unknown
  // TResult extends ServiceResult | EventResult,
  // TModel = unknown
>(
  props: SearchQueryProps<TParams> & {
    queryKeyFn: (params: TParams) => QueryKey
    cieApiFn: (params: TParams) => Promise<TResponse>
    nextPageParam: string
    transformer: (model: TModel) => SearchResult | undefined
  }
): SearchResultsReturnType<TResponse, unknown> | undefined => {
  const {
    queryKeyFn,
    cieApiFn,
    nextPageParam,
    transformer,
    searchType,
    searchId,
    querySummary,
    enabled,
    params: startParams,
  } = props

  const queryKey = queryKeyFn(startParams)

  const queryFn: QueryFunction<TResponse> = ({ queryKey, pageParam }) => {
    const params = queryKey[1] as TParams
    return cieApiFn({ ...params, ...pageParam })
  }

  const getNextPageParam: GetNextPageParamFunction<TResponse> = (
    lastPage,
    allPages
  ) => {
    if (!lastPage.data?.length) return
    return { [nextPageParam]: allPages.length + 1 }
  }

  const query = useInfiniteQuery({
    enabled,
    queryKey,
    queryFn,
    getNextPageParam,
  })

  const transformed = useInfiniteResultsTransform({
    queryKey,
    data: query.data,
    transformer,
  })

  const results: SearchResultsBase = {
    searchType,
    searchId,
    querySummary,
    results: query?.data &&
      transformed && {
        pageParams: query.data.pageParams,
        pages: transformed.pages,
        flat: _.flatten(transformed.pages),
      },
  }

  const inner: SearchInnerQueryReturnType<TResponse, unknown> = {
    queryKey,
    ...query,
  }

  const ret: SearchResultsReturnType<TResponse, unknown> = {
    ...results,
    ...inner,
  }

  useUpdateSearchResultsAtom(searchId, ret)

  return ret
  // return undefined
}

/**
 * Static props for {@link useSearchDirectory}
 *
 * @category Props
 */
export type DirectorySearchProps = SearchQueryProps<ApiRequestModel>

/**
 * Directory implementation of @{useCieInfiniteSearch}
 *
 * @category Hooks
 * @param props Props generated by search store (search.queryProps)
 */
export const useDirectorySearch = (props: DirectorySearchProps) =>
  useCieInfiniteSearch<
    ApiSharedSearchResponseOfApiSearchResultModel,
    ApiSearchResultModel,
    ApiRequestModel
  >({
    ...props,
    queryKeyFn: getSearchDirectoryQueryKey,
    cieApiFn: searchDirectory,
    nextPageParam: 'pageNumber',
    transformer: serviceResultTransformer,
  })

/**
 * Static props for {@link useDiarySearch}
 *
 * @category Props
 */
export type DiarySearchProps = SearchQueryProps<SearchRequestModelBody>

/**
 * Diary implementation of @{useCieInfiniteSearch}
 *
 * @category Hooks
 * @param props Props generated by search store (search.queryProps)
 */
export const useDiarySearch = (props: DiarySearchProps) =>
  useCieInfiniteSearch<
    ApiSharedSearchResponseOfEventDataViewModel,
    EventDataViewModel,
    SearchRequestModelBody
  >({
    ...props,
    queryKeyFn: getSearchDiaryQueryKey,
    cieApiFn: searchDiary,
    nextPageParam: 'pageNo',
    transformer: eventResultTransformer,
  })

/**
 * Static props for {@link useFavouritesSearch}
 *
 * @category Props
 */
export type FavouritesSearchProps = SearchQueryProps<any>

/**
 * Favourites wrapper for ResultsList
 *
 * Uses favourites atoms
 *
 * @category Hooks
 */
export const useFavouritesSearch = (
  props: FavouritesSearchProps
): SearchResultsBase | undefined => {
  const { searchType, searchId } = props

  const favourites = useFavourites()

  const ids = favourites.map((f) => f.id).join(',')
  // console.log({ ids })

  const results: SearchResultsBase = {
    searchType,
    searchId,
    results: {
      pageParams: [],
      pages: [favourites],
      flat: favourites,
    },
  }

  useUpdateSearchResultsAtom(searchId, results)

  return results
}
