import React from 'react'

export type ParentMutationObserver = {
  selector?: string
  options?: MutationObserverInit
  filter?: (value: MutationRecord) => boolean
  onMutation?: (element: HTMLElement, mutation: MutationRecord) => void
}

export type ParentMutationObserversOptions = {
  element: HTMLElement | undefined
  mutationObservers: Array<ParentMutationObserver> | undefined
}

export const useParentMutationObservers = (options: ParentMutationObserversOptions): void => {
  const [observers, setObservers] = React.useState<Array<MutationObserver | undefined> | null>(null)

  const { element, mutationObservers } = options

  const clearObservers = () => {
    observers?.forEach(o => o?.disconnect())
    setObservers(null)
  }

  React.useEffect(() => {
    if (!element || !mutationObservers) {
      clearObservers()
      return
    }

    const obs = mutationObservers
      .map(
        ({
          selector,
          options = { attributes: true },
          filter = value => value.attributeName === 'class',
          onMutation,
        }) => {
          if (!selector) {
            console.warn(`Invalid selector`)
            return
          }

          const parent = element.closest(selector) as HTMLElement

          if (!parent) {
            console.warn(`No parent found for selector "${selector}"`)
            return
          }

          const o = new MutationObserver(mutations => {
            const mf = mutations.filter(filter)
            if (!mf.length || !onMutation) return

            mf.forEach(m => onMutation(parent, m))
          })

          o.observe(parent, options)

          return o
        }
      )
      .filter(o => !!o)

    setObservers(obs)

    return clearObservers
  }, [element, mutationObservers?.length])
}
