import React, { useState, useEffect } from 'react'

import _ from 'lodash'
import { toJS } from 'mobx'
import { useQuery } from 'react-query'
import axios from 'axios'
import humps from 'lodash-humps'

import { config } from '@/config'
import { rootStore as rootStore } from '@/stores/root/store'

const { appConfig } = config

import { cieQueryHelpers as helpers } from './query-helpers'

export const queryClient = axios.create({
  baseURL: appConfig.apiURL,
  timeout: 10000,
  headers: { Authorization: `ApiKey ${appConfig.apiKey}` },
  // transformResponse: humps,
  transformResponse: [].concat(axios.defaults.transformResponse, humps),
})

export const cieQueries = {
  states: {
    queryId: 'cie-geography-states',
    client: 'axios',
    request: () => ({
      method: 'get',
      url: `/system/states`,
    }),
    select: ({ data }) => data,
  },

  state: {
    queryId: 'cie-geography-state',
    alias: 'state',
    client: 'axios',
    paramKeys: ['state'],
    request: ({ state }) => ({
      method: 'get',
      url: `/system/states/name/${state}`,
    }),
    select: ({ data }) => data,
  },

  councils: {
    queryId: 'cie-geography-councils',
    client: 'axios',
    request: ({ state }) => ({
      method: 'get',
      url: `/system/councils/state/${state}`,
    }),
    select: ({ data }) =>
      data && data.map((d) => ({ ...d, longUrl: d.url?.match(/[^/]*?$/)[0] })),
  },

  council: {
    queryId: 'cie-geography-council',
    client: 'axios',
    request: ({ state, council }) => ({
      method: 'get',
      url: `/system/councils/name/${state}/${council}`,
    }),
    select: ({ data }) => ({ ...data, longUrl: data.url?.match(/[^/]*?$/)[0] }),
  },

  localities: {
    queryId: 'cie-geography-localities',
    client: 'axios',
    request: ({ state, council }) => ({
      method: 'get',
      url: `/system/localities/council/${state}/${council}`,
    }),
    select: ({ data }) => {
      return data?.data
    },
  },

  locality: {
    queryId: 'cie-geography-locality',
    client: 'axios',
    // client: 'testFail',
    request: ({ state, council, locality }) => ({
      method: 'get',
      url: `/system/localities/name/${state}/${council}/${locality}`,
    }),
    select: ({ data }) => data?.data,
  },

  categories: {
    queryId: 'ams-categories',
    client: 'local',
    request: ({ categories }) => {
      const cats = rootStore.tenant.categoriesFromParams2({
        category: categories,
      })
      return cats
    },
  },

  pathLinkItems: {
    queryId: 'ams-path-link-items',
    client: 'local',
    request: helpers.pathLinkItems,
  },
}

const clients = {
  dummy: async (query, params) =>
    new Promise((accept, reject) => {
      setTimeout(() => accept({ result: 'bang!' }), 200)
    }),
  testFail: async (query, paarams) =>
    new Promise((accept, reject) => {
      setTimeout(() => reject({ error: 'Always fails!' }), 200)
    }),
  axios: async (query, params) => {
    const res = await queryClient(query.request(params))
    return res
    // return res.data?.data || res.data
  },
  local: async (query, params) => {
    return new Promise((accept, reject) => {
      const { view } = query
      const res = query.request(params, view)
      accept(res)
    })
  },
}

export const paramQueryFn =
  (queryDef) =>
  async ({ queryKey }) => {
    const [queryId, params] = queryKey

    const client = clients[queryDef.client]
    if (!client) throw `Invalid client ${queryDef.client}`

    const res = await client(queryDef, params)
    // console.log({res})
    return res
  }

export const getCieQueryDef = (queryConfig = {}, params, view) => {
  const name = _.isString(queryConfig) ? queryConfig : queryConfig['query']
  const queryDef = cieQueries[name] || {}
  const { queryId } = queryDef || {}

  if (!queryDef || !queryId)
    throw {
      message: `Invalid queryDef`,
      items: { cieQueries, queryConfig, name, queryDef, queryId },
    }

  const { select } = queryDef || {}

  const queryFnArgs = { ...queryDef, view }

  return {
    query: queryConfig,
    queryKey: [queryId, params],
    queryFn: paramQueryFn(queryFnArgs),
    select,
    // view,
  }
}
