import React, { useState, useEffect } from 'react'

import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

import { makeStyles } from '@mui/styles'
import { Box, Typography, Divider, Button } from '@mui/material'

import SimpleMarkdown from '@/ui/markdown/SimpleMarkdown'
import { TenantThemeProvider } from '@/ui/theme/TenantTheme'

import { useConfig } from '@/config'
import { useRootStore } from '@/stores/root/store'

const useStyles = makeStyles((theme) => ({
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 26,
    },
  },
  hints: {
    marginTop: theme.spacing(5),
    // padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  hintLabel: {
    // marginRight: theme.spacing(2),
    fontSize: 16,
  },
  hintButton: {
    margin: theme.spacing(0, 1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}))

const Information = observer(() => {
  const classes = useStyles()

  const { tenantConfig: tc } = useConfig()
  const tabs = tc.ui.search.tabs

  const store = useRootStore()
  const { category, content } = store.search.getSelectedInformation()

  const rootRef = React.useRef()

  // const category = store.search.selectedInformationCategory

  const [hints, setHints] = useState(null)

  // scroll to top

  React.useEffect(() => {
    rootRef.current?.closest('.scrollbar-container')?.scrollTo(0, 0)
  }, [category?.id])

  // tab hints

  useEffect(() => {
    const thints = !category?.search
      ? null
      : tabs
          .map((tab, index) => ({ tab, index }))
          .filter((t) => t.tab.options?.tabHint)

    setHints(thints)
  }, [category?.search, tabs])

  const handleHintButtonClick = (tab) => {
    store.search.ui.setSelectedTab(tab)
  }

  const HintButtons = ({ hints }) => {
    return (
      <div className={classes.hints}>
        <Typography className={classes.hintLabel} variant="body2">
          {/* <i className={clsx('fal fa-lightbulb', classes.icon)} /> */}
          Search for
        </Typography>
        {hints.map((h, i) => (
          <Button
            className={classes.hintButton}
            key={i}
            variant="outlined"
            color="primary"
            size="small"
            disableElevation
            onClick={() => handleHintButtonClick(h.index)}
          >
            <i className={clsx(h.tab.icon, classes.icon)} />
            {h.tab.title}
          </Button>
        ))}
        <Typography className={classes.hintLabel} variant="body2">
          in {category.id === 'default' ? 'all categories' : 'this category'}.
        </Typography>
      </div>
    )
  }

  if (!category || !content) return null

  const { title = 'Unknown Category' } = category
  const { body = '' } = content

  return (
    <TenantThemeProvider theme="variants.searchInformation">
      <Box ref={rootRef}>
        <Typography className={classes.title} variant="h4" gutterBottom>
          {title}
        </Typography>
        <Divider />
        {body && (
          <Box sx={{ mt: 2 }}>
            <SimpleMarkdown>{body}</SimpleMarkdown>
          </Box>
        )}
        {/* <br /> */}
        {hints && <HintButtons hints={hints} />}
      </Box>
    </TenantThemeProvider>
  )
})

export default Information
