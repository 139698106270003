import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { withStyles } from '@mui/styles'
import { Typography } from '@mui/material'
import { grey, purple } from '@mui/material/colors'
import { observer } from 'mobx-react-lite'

import { useRootStore } from '@/stores/root/store'
import { getGeographyMembers, getOutletsByOrgId } from '@/api/legacy/cie-api'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu'
import '@/tenants/shared/styles/scrolling-menu.scss'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    overflowX: 'auto',
  },
  title: {
    // fontSize: 13,
    padding: theme.spacing(1, 0),
    color: grey[600],
    fontSize: '0.92rem',
    fontWeight: 'light',
  },
  scrollMenu: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '1050px',
    // overflow: 'auto',
    // whitespace: 'nowrap',
  },
  item: {
    display: 'flex',
    // textalign: 'center',
    fontSize: 11,
    padding: '8px',
    minWidth: '100px',
    color: grey[600],
    maxHeight: '100px',
    // textdecoration: 'none',
    // maxheight: '50px',
    // minwidth: '200px',
  },
})

const copy = `
We acknowledge the traditional owners of the land and pay respects to the elders past, present and future.
`
const HHSOutlets = observer(({ classes }) => {
  const store = useRootStore()
  const location = store.search.params.location
  const qparam = _.pick(location, ['id', 'lat', 'lng'])

  const [hhs, setHHS] = useState(null)
  const [hhsOutlets, setHHSOutlets] = useState([])

  useEffect(() => {
    const dataRsp = async () => {
      const rsp = await getGeographyMembers(qparam)
      const hhsdata = rsp.filter((x) => x.dataset == 'HHS')
      setHHS(hhsdata[0])
      if (hhsdata.length > 0 && hhsdata[0]?.member != undefined) {
        const outletData = await getOutletsByOrgId(
          hhsdata[0]?.member?.organisationId
        )
        setHHSOutlets(outletData.data)
      }
    }

    dataRsp()
  }, [location])

  return (
    <div className={classes.root}>
      {hhs?.member != undefined && hhsOutlets.length > 0 && (
        <>
          <div className={classes.title}>
            {hhs && `Locations in ${hhs?.member?.preferredName}:`}
          </div>
          <ScrollMenu>
            {hhsOutlets.map((outlet, i) => {
              return (
                <a
                  href={
                    window.location.origin + `/outleteventcalendar${outlet.Url}`
                  }
                  key={i}
                  className={classes.item}
                  style={{ textDecoration: 'none' }}
                >
                  {outlet.Name}
                </a>
              )
            })}
          </ScrollMenu>
        </>
      )}
    </div>
  )
})

export default withStyles(styles, { name: 'AMSHHSOutlets' })(HHSOutlets)
