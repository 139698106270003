import React, { useState, useEffect, useCallback } from 'react'

import _ from 'lodash'

import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import { useResizeDetect } from './useResizeDetect'

export const useBreakpoint = () => {
  const { breakpoints } = useTheme()
  const { width } = useResizeDetect()

  const findBreakpoint = () => _.findLast(breakpoints.keys, k => width >= breakpoints.values[k])

  const [current, setCurrent] = useState(findBreakpoint())

  useEffect(() => {
    const c = findBreakpoint()
    if (c !== current) setCurrent(c)
  }, [width])

  return { current, breakpoints, width }
}

const transformMediaProps = (root, breakpoint) => {
  const { breakpoints, width } = breakpoint

  return (function walkNode(node) {
    if (!node) return node
    if (_.isArray(node)) return node.map(walkNode)
    if (!_.isObject(node)) return node

    const valueKeys = _.keys(node)
    const mediaKeys = _.intersection(breakpoints.keys, valueKeys)

    // normal object
    if (mediaKeys.length == 0) return _.mapValues(node, walkNode)

    // keys mismatch
    if (mediaKeys.length !== valueKeys.length) {
      const unknownKeys = _.difference(valueKeys, breakpoints.keys)
      throw `Invalid media keys (${unknownKeys.join(', ')})`
    }

    const matchedKey = _.findLast(mediaKeys, k => width >= breakpoints.values[k])
    const matchedValue = matchedKey ? node[matchedKey] : undefined

    return walkNode(matchedValue)
  })(root)
}

export const useMediaProps = props => {
  const breakpoint = useBreakpoint()

  const updateTransformedProps = () => transformMediaProps(props, breakpoint)

  const [transformedProps, setTransformedProps] = useState(updateTransformedProps)

  useEffect(() => {
    setTransformedProps(updateTransformedProps)
  }, [breakpoint.current])

  return transformedProps
}
