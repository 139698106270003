import React from 'react'

import { createDevTools } from '@redux-devtools/core'

import { LogMonitor } from '@redux-devtools/log-monitor'
import { DockMonitor } from '@redux-devtools/dock-monitor'

export const ReduxDevtoolsMonitors = createDevTools(
  <DockMonitor
    toggleVisibilityKey="ctrl-h"
    changePositionKey="ctrl-q"
    defaultIsVisible={true}
  >
    <LogMonitor theme="tomorrow" />
  </DockMonitor>
)
