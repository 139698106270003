// import React from 'react'
import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import cieElastic from './analytics-plugin-cie-elastic'

import { config } from '@/config'
const { tenantConfig: tc } = config

const providers = {
  'google-analytics': {
    provider: googleAnalytics,
  },
  'cie-elastic': {
    provider: cieElastic,
  },
}

const plugins = (tc?.analytics?.plugins || []).map((p) =>
  providers[p.provider].provider(p)
)

export const analytics = Analytics({
  app: tc?.analytics?.app || 'ams',
  // debug: true,
  plugins,
})
