import React from 'react'

import _ from 'lodash'

import { Typography, Box, Link } from '@mui/material'

// import Button from '@/ui/components/buttons/Button'
import SiteSupportButton from '@/ui/components/buttons/SiteSupportButton'
import FAIcon from '@/ui/components/icon/FontAwesomeIcon'

import { useConfig } from '@/config'

const Contact = (props) => {
  const { id, label, icon, href } = props || {}
  return (
    <Link
      sx={{ display: 'flex', gap: 1, textDecoration: 'none', my: 1 }}
      href={href}
    >
      <FAIcon fa={icon} />
      <Box>{label}</Box>
    </Link>
  )
}

const LandingContactUs = (props) => {
  const { tenantConfig: tc } = useConfig()

  const contactKeys = ['email', 'phone']
  const menuItems = _.keyBy(tc.ui.menus.contact?.items || [], 'id')
  const contactItems = _.values(_.pick(menuItems, contactKeys))

  return (
    <Box
      sx={{
        backgroundColor: 'landing.paper',
        p: { xs: 2, sm: 4 },
      }}
    >
      <Box
        sx={{
          margin: '0 auto',
          maxWidth: 800,
        }}
      >
        <Typography
          variant="h6"
          subvariant="landing"
          color="primary"
          gutterBottom
        >
          Need some help, or would you like to know more?
        </Typography>
        <Typography variant="body1" gutterBottom>
          Our support desk is open Monday to Friday 8.00am to 7.00pm AEST.
        </Typography>

        <Box sx={{ my: 2 }}>
          {contactItems.map((item, i) => (
            <Contact key={i} {...item} />
          ))}
        </Box>

        <Typography variant="body1" sx={{ mb: 2 }}>
          Or click the button below:
        </Typography>
        <SiteSupportButton />
      </Box>
    </Box>
  )
}

export default LandingContactUs
