import React, { useRef, useEffect } from 'react'

import _ from 'lodash'
import clsx from 'clsx'

import { useAtom } from 'jotai'
// import { useRecoilState, useSetRecoilState } from 'recoil'
// import { paramsPortalState } from '@/ui/state/search'

import { withStyles } from '@mui/styles'
import { AppBar as MuiAppBar, Box, IconButton } from '@mui/material'

import { TenantThemeProvider } from '@/ui/theme/TenantTheme'
import ComponentMap from '@/ui/components/atoms/ComponentMap'
import { paramsPortalAtom } from '@/ui/app/app-params-portal'
import AppBarTitle from './AppBarTitle'
import AppBarButton from './AppBarButton'
import Sponsors from '@/ui/footer/Sponsors'

import { useConfig } from '@/config'
// import { withVariantStyles } from '@/ui/hooks/theme-hooks'

// const { tenantConfig } = useConfig()

const menuButtonStyles = (theme) => ({
  root: {
    padding: theme.spacing(1),
    minWidth: 36,
    minHeight: 36,
  },
  icon: {
    fontSize: '1rem',
  },
})

const MenuButton = withStyles(menuButtonStyles, { name: 'AMSMenuButton' })(
  ({ classes, icon = 'far fa-bars', ...iconButtonProps }) => {
    return (
      <IconButton
        className={classes.root}
        edge="start"
        color="inherit"
        aria-label="menu"
        {...iconButtonProps}
      >
        {/* <MenuIcon /> */}
        <i className={clsx(icon, 'fa-fw', classes.icon)} />
      </IconButton>
    )
  }
)

const portalStyles = (theme) => ({
  root: {
    // border: '1px solid rgba(0, 100, 210, 1.0)',
  },
  target: {},
})

const PortalOutlet = withStyles(portalStyles, { name: 'AMSPortalOutlet' })(
  (props) => {
    const { classes, ...otherProps } = props

    const [portal, setPortal] = useAtom(paramsPortalAtom)

    // const setPortal = useSetRecoilState(paramsPortalState)

    return (
      <Box
        className={classes.root}
        ref={(el) => setPortal(el)}
        {...otherProps}
      />
    )
    // return null
  }
)

const groupStyles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  left: {
    flexShrink: 1, // ie11 no auto
    justifyContent: 'flex-start',
  },
  fill: {
    flexGrow: 1, // ie11 no auto
    justifyContent: 'flex-start',
  },
  right: {
    flexShrink: 1, // ie11 no auto
    justifyContent: 'flex-end',
  },
})

const Group = withStyles(groupStyles, { name: 'AMSAppBarGroup' })(
  ({ classes, align, items = [], onMenuClick = () => undefined }) => {
    return (
      <div className={clsx(classes.root, classes[align])}>
        <ComponentMap
          components={componentMap}
          items={items}
          renderProps={({ component, props }) => ({
            ...props,
            ...(component === 'MenuButton' && { onClick: onMenuClick }),
          })}
        />
      </div>
    )
  }
)

const componentMap = {
  MenuButton,
  AppBarTitle,
  AppBarButton,
  Sponsors,
  PortalOutlet,
}

const styles = (theme) => ({
  root: {
    zIndex: theme.zIndex.drawer - 1,
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    margin: 0,
    padding: theme.spacing(0, 2),
    minHeight: 60,
    [theme.breakpoints.down('sm')]: {
      minHeight: 48,
    },
  },
})

const AppBar = ({
  classes,
  onMenuClick = () => undefined,
  groups: groupsProp,
  rows: rowsProp,
  ...appBarProps
}) => {
  const Row = (row, rkey) => (
    <div key={rkey} className={classes.row}>
      {(row.groups || []).map((group, gkey) => (
        <Group key={gkey} {...group} onMenuClick={onMenuClick} />
      ))}
    </div>
  )

  const rows = (rowsProp || [{ groups: groupsProp }]).map(Row)

  return (
    <TenantThemeProvider theme="variants.appBar">
      <MuiAppBar
        position="static"
        className={classes.root}
        // elevation={0}
        {...appBarProps}
      >
        {rows}
      </MuiAppBar>
    </TenantThemeProvider>
  )
}

export default withStyles(styles, { name: 'AMSAppBar' })(AppBar)
