import { types, getRoot } from 'mobx-state-tree'

export const ContentItem = types
  .model({
    // id: types.identifier,
    // attributes: types.model({
    // }),
    type: types.literal('md'),
    body: '',
  })

export const Content = types
  .model({
    items: types.map(ContentItem),
  })

  .views((self) => ({
    get selectedInformation() {
      const noContent = { id: null, body: 'Content not found' }

      const ck = (category, region) => `information/${region}/${category}`

      const sc = getRoot(self).search.params.selectedCategories

      if (typeof sc !== 'string') return noContent

      return self.items.get(ck(sc, 'default')) || noContent
    },

  }))

