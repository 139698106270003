import _ from 'lodash'

const matchesTermsProperty = (path, matchValue) => source => {
  const value = _.get(source, path)

  if (_.isArray(matchValue)) {
    // array values in array
    if (_.isArray(value)) return _.intersection(value, matchValue).length > 0

    // value in array
    return _.some(matchValue, mv => _.isEqual(value, mv))
  }

  return _.isEqual(value, matchValue)
}

export const matchesTerms = matchObject => source =>
  _.every(matchObject, (v, k) => matchesTermsProperty(k, v)(source))
