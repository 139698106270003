import React from 'react'

import clsx from 'clsx'

import { Link as RouterLink } from 'react-router-dom'

import { makeStyles } from '@mui/styles'
import { Box, Button, Typography, Link as MuiLink } from '@mui/material'

import { useConfig } from '@/config'

const appBarButtonStyles = (theme) => ({
  root: {},
  button: {},
  icon: {
    marginRight: theme.spacing(1),
    // ...theme.
    fontSize: 'inherit',
  },
  label: {
    //textDecoration: 'none',
    textTransform: 'none',
    whiteSpace: 'nowrap',
  },
})

const useAppBarButtonStyles = makeStyles(appBarButtonStyles, {
  name: 'AMSAppBarButton',
})

const AppBarButton = ({
  // classes,
  label,
  icon,
  // variant,
  ...buttonProps
}) => {
  const classes = useAppBarButtonStyles()

  const bprops = {
    underline: 'none',
    // color: 'primary',

    variant: 'text',
    ...buttonProps,
  }

  return (
    <Button
      className={classes.root}
      // startIcon={<i className={clsx(icon, classes.icon)} />}
      {...bprops}
    >
      <i className={clsx(icon, classes.icon)} />
      <span className={classes.label}>{label}</span>
    </Button>
  )
}

export default AppBarButton
