import React from 'react'

import { observer } from 'mobx-react-lite'
import { Box } from '@mui/material'

import { BaseLink } from '@/ui/nav/Link'
import { useRootStore } from '@/stores/root/store'
import { useConfig } from '@/config'
import { useQuery } from 'react-query'

import { getPromotions } from '@/api/legacy'

const Promotions = observer(() => {
  const { appConfig: ac } = useConfig()
  const { tenant } = ac

  const store = useRootStore()
  const { councilId } = store.search.params.location || {}
  const params = councilId ? { tenant, councilId } : null

  const { data } = useQuery(
    ['cie-promotions', params],
    async () => getPromotions(params),
    {
      enabled: !!params,
      refetchOnWindowFocus: false,
      select: ({ data }) => data,
    }
  )

  if (!data) return null

  return (
    <Box sx={{ p: 2 }}>
      {data.map(({ captionText, url }, i) => (
        <Box key={i}>
          <BaseLink href={url}>
            <Box component="i" className="fas fa-file-pdf" sx={{ mr: 1 }} />
            {captionText}
          </BaseLink>
        </Box>
      ))}
    </Box>
  )
})
export default Promotions
