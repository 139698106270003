import React from 'react'

import { withRouter } from 'react-router-dom'
import { useAnalytics } from 'use-analytics'

import { makeStyles } from '@mui/styles'
import { Button } from '@mui/material'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
  },
}))

const BackButton = withRouter(({
  history,
  match,
}) => {
  const classes = useStyles()
  const { track } = useAnalytics()

  const handleClick = () => {
    track('contentPageButtonClick', {
      category: 'Navigation',
      label: 'Content Page Back',
    })

    history.goBack()
  }

  if (match.path === '/') return null

  return (
    <div className={classes.root}>
      <Button
        color="primary"
        onClick={handleClick}
      >
        <i className="fas fa-long-arrow-left" />&nbsp;&nbsp;Back
      </Button>
    </div>
  )
})

export default BackButton
