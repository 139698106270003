import React, { useEffect } from 'react'

import _ from 'lodash'
import { observer } from 'mobx-react-lite'

import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import { tokens } from './Mono'
import { useDebugStore } from '@/stores'

const Dump = styled('pre')`
  font-family: 'JetBrains Mono', monospace;
`

const Link = styled(RouterLink)`
  color: ${tokens.color};
`

const TestLinks = observer(() => {
  const store = useDebugStore()

  const { _helper } = store
  if (!_helper) return null

  const { links = [] } = _helper

  return (
    <Box>
      {links.map((to, key) => (
        <Box key={key}>
          <Link to={to}>{to}</Link>
        </Box>
      ))}
    </Box>
  )
})

export default TestLinks
