export const covidAlert = {
  AUS: {
    label: 'Australian Department of Health',
    href: 'https://www.health.gov.au/news/health-alerts/novel-coronavirus-2019-ncov-health-alert',
  },
  QLD: {
    label: 'Queensland',
    href: 'https://www.qld.gov.au/health/conditions/health-alerts/coronavirus-covid-19',
  },
  NSW: {
    label: 'New South Wales',
    href: 'https://www.nsw.gov.au/covid-19/latest-news-and-updates',
  },
  VIC: {
    label: 'Victoria',
    href: 'https://www.dhhs.vic.gov.au/coronavirus',
  },
  TAS: {
    label: 'Tasmania',
    href: 'https://www.coronavirus.tas.gov.au/',
  },
  SA: {
    label: 'South Australia',
    href: 'https://www.covid-19.sa.gov.au/',
  },
  WA: {
    label: 'Western Australia',
    href: 'https://www.wa.gov.au/government/covid-19-coronavirus',
  },
  NT: {
    label: 'Northern Territory',
    href: 'https://coronavirus.nt.gov.au/',
  },
  ACT: {
    label: 'Australian Capital Territory',
    href: 'https://www.covid19.act.gov.au/',
  },
}
