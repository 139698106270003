import React, { useState } from 'react'

import { observer } from 'mobx-react-lite'
import { withRouter, Redirect } from 'react-router-dom'
import { useAsync } from 'react-async'

import { makeStyles } from '@mui/styles'
import {
  Typography,
  Container,
  // Box,
  CircularProgress,
} from '@mui/material'

import { useRootStore } from '@/stores/root/store'
import { getLocation2 as getLocation } from '@/api/legacy'

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(12),
  },
  progressContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progress: {
    margin: '20px auto',
  },
}))

const LocationRedirect = observer(
  withRouter(({ match, redirectTo }) => {
    const store = useRootStore()
    const classes = useStyles()

    const { lat, lng } = match.params

    const [redirect, setRedirect] = useState(null)

    const { isPending } = useAsync({
      promiseFn: getLocation,
      lat,
      lng,
      onResolve: (data) => {
        if (data) store.search.params.setLocation(data)
        setRedirect(redirectTo)
      },
    })

    if (redirect) return <Redirect to={redirect} />

    return (
      <Container maxWidth="md" component="main" className={classes.main}>
        {isPending && (
          <div className={classes.progressContainer}>
            <Typography variant="body2">Resolving Location</Typography>
            <CircularProgress
              size={32}
              thickness={3.0}
              className={classes.progress}
            />
          </div>
        )}
      </Container>
    )
  })
)

export default LocationRedirect
