import shared, { loadImgAssets } from '../shared/assets'

const { cie, headerIcons, flags, healthengine, mapMarkers, mapMarkersSvg } =
  shared.defaults

const landing = loadImgAssets(
  require.context('./assets/landing', true, /\.(png|webp|jpe?g|svg)$/)
)

const sponsors = loadImgAssets(
  require.context('./assets/sponsors', true, /\.(png|webp|jpe?g|svg)$/)
)

// import './assets/fonts/gordita/gordita.css'
import '@fontsource/inter/300.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'

export const assets = {
  logo: shared.images.cissIcons.amh.solid,
  textLogo: shared.images.cissIcons.amh.textLarge,
  santa: shared.images.cissIcons.amh.amhSanta,
  // logo: shared.images.cissIcons.amh.textLarge,
  cie,
  flags,
  headerIcons,
  mapMarkers,
  mapMarkersSvg,
  healthengine,
  sponsors,
  landing,
}
