/* eslint-disable no-unused-vars */
import React, { useState } from 'react'

import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'

export const useMergeOptions = (defaults, options) => {
  const [opts] = useState(merge(cloneDeep(defaults), options))
  return opts
}
