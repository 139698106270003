import React, { useEffect, useState } from 'react'

import _ from 'lodash'
import clsx from 'clsx'
import { useQuery } from 'react-query'

import { observer } from 'mobx-react-lite'

import { grey } from '@mui/material/colors'
import { withStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import { Collapse, Fade, useMediaQuery } from '@mui/material'

import { useConfig } from '@/config'
import { usePlatform } from '@/ui/hooks/usePlatform'
import { useMediaProps } from '@/ui/hooks/useMediaProps'
import { useRootStore } from '@/stores/root/store'
import { getGeographyMembers } from '@/api/legacy'

import {
  default as ImageList,
  styles as imageListStyles,
} from '@/ui/components/images/ImageList'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },
  label: {
    // color: theme.palette.text.secondary,
    padding: theme.spacing(1, 0),
    color: grey[600],
    fontSize: '0.92rem',
    fontWeight: 'light',
  },
  imageList: {
    ...imageListStyles.root,
    gap: 20,
  },
  imageListIe11: {
    justifyContent: 'center',
    margin: theme.spacing(-2),
  },
  imageContainer: {
    ...imageListStyles.imageContainer,
    maxWidth: 200,
    height: 50,
    maxHeight: 50,

    '& img': {
      width: 'inherit',
      height: 'inherit',
      maxWidth: 'inherit',
      maxHeight: 'inherit',
    },
  },
  imageContainerIe11: {
    margin: theme.spacing(2),
  },
})

_.mixin({
  pickValues: (src, keys) => {
    return _.reduce(
      keys,
      (prev, key) => {
        const r = src[key]
        return r ? [...prev, r] : prev
      },
      []
    )
  },
})

const normalizeAssetPrefix = (assetPrefix) => {
  const pfxarr = _.isArray(assetPrefix)
    ? assetPrefix
    : _.isString(assetPrefix)
    ? [assetPrefix]
    : []
  return pfxarr.map((p) => p.split('.'))
}

const resolvePrefixedAsset = (outletId, options) => {
  const { assets, assetMap, assetPrefix } = options
  if (!assets || !assetMap || !assetPrefix) return

  const assetName = assetMap[outletId]
  if (!assetName) return

  for (const pfx of assetPrefix) {
    const asset = _.get(assets, [...pfx, assetName])
    if (asset) return asset
  }
}

const buildSponsorsList = (geographyMembers, options) => {
  const {
    membershipOverrides,
    assetPrefix: apfx,
    assets,
    assetMap,
    itemSet,
    location,
  } = options
  const assetPrefix = normalizeAssetPrefix(apfx)

  const overrideMembership = (sponsor) => {
    const member = membershipOverrides[sponsor.id]
    return member ? { ...sponsor, member } : sponsor
  }

  const overrideAsset = (sponsor) => {
    const src = resolvePrefixedAsset(sponsor.outletId, {
      assets,
      assetMap,
      assetPrefix,
    })
    return src ? { ...sponsor, src } : sponsor
  }

  // TODO: Fix this with R.compose
  // _.chain loads all lodash modules
  return (
    _.chain(geographyMembers)
      // .tap(gm => gm.forEach(g => console.log(JSON.stringify(g, null, 2))))
      .map(overrideMembership)
      .filter((gm) => !!gm.member /* && !!gm.member.logo */)
      .keyBy((gm) => gm.dataset.toLowerCase())
      .mapValues(
        ({
          id,
          dataset,
          member: { outletId, website, url, logo, preferredName },
        }) => ({
          geographyId: id,
          dataset,
          outletId,
          href: website,
          alt: preferredName,
          url,
          src: logo,
        })
      )
      .assign(options.presets)
      .mapValues((value, key) => _.assign(value, { key }))
      .pickValues(itemSet || [])
      .map(overrideAsset)
      .filter((s) => !!s.src)
      .value()
  )
}

/**
 * Displays sponsors logos
 *
 * Relies heavily on themes (see ams/theme.js variants)
 */
const Sponsors = (props) => {
  const { tenantConfig: tc } = useConfig()

  const {
    classes,
    options = tc.sponsors || {},
    label = 'Supported by',
    imageListProps = {},
    ...mediaProps
  } = props

  const store = useRootStore()
  const location = store.search.params.location
  const qparam = _.pick(location, ['id', 'lat', 'lng'])

  const platform = usePlatform()
  const ie11 = !!platform.ieVersion

  const { itemSet: itemSetId, assetPrefix = options.assetPrefix } =
    useMediaProps(mediaProps)

  const itemSet = options.itemSets?.[itemSetId]
  const [assetMap] = useState(
    _.mapValues(_.keyBy(options.assetMap || {}, 'outletId'), (a) => a.asset)
  )

  const { data: sponsors } = useQuery(
    ['geography-members', qparam],
    async () => await getGeographyMembers(qparam),
    {
      enabled: !!itemSet,
      select: (data) =>
        buildSponsorsList(data, {
          ...options,
          assets: tc.assets,
          itemSet,
          assetPrefix,
          assetMap,
          location,
        }),
    }
  )

  const showSponsors = sponsors?.length > 0

  const imageListClasses = {
    root: clsx(classes.imageList, ie11 && classes.imageListIe11),
    imageContainer: clsx(
      classes.imageContainer,
      ie11 && classes.imageContainerIe11
    ),
  }

  return (
    <Fade in={showSponsors}>
      <div className={classes.root}>
        {showSponsors && (
          <>
            {label && <div className={classes.label}>{label}</div>}
            <ImageList
              images={sponsors || []}
              classes={imageListClasses}
              {...imageListProps}
            />
          </>
        )}
      </div>
    </Fade>
  )
}

export default withStyles(styles, { name: 'AMSSponsors' })(observer(Sponsors))
