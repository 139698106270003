import React from 'react'
import { Typography, Box, Divider } from '@mui/material'
import useScript from '@/ui/hooks/useScript'

const styles = {
  mainWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    margin: '0 auto 32px auto',
  },
  calendarWrapper: {},
  calendar: { height: '500px' },
}

const HHSOutletEventCalendar = () => {
  const urlSplitArray = window.location.pathname.split('/')
  const outletId = urlSplitArray[urlSplitArray.length - 2]
  const outletName = urlSplitArray[urlSplitArray.length - 1].replaceAll(
    '_',
    ' '
  )

  useScript('https://www.mycommunitydiary.com.au/widgets/events/events.min.js')

  return (
    <div className="main-wrapper" style={styles.mainWrapper}>
      <div className="title" style={styles.title}>
        Event Calendar for <b>{outletName}</b>
      </div>
      <div className="calendar-wrapper" style={styles.calendarWrapper}>
        <div
          id="eventsBasic"
          widget-type="calendar"
          logo-type="AMHC"
          panel-height="500px"
          data-location={outletId}
          style={styles.calendar}
        ></div>
      </div>
    </div>
  )
}
export default HHSOutletEventCalendar
