import {
  Box,
  OutlinedInput,
  Button,
  IconButton,
  Autocomplete,
  InputAdornment,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { withStyles } from '@mui/styles'
import clsx from 'clsx'
import { useConfig } from '@/config'
import { getLocationSuggestions } from '@/api/legacy'
import { milliseconds as ms } from 'date-fns'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { isAlive, isStateTreeNode } from 'mobx-state-tree'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useCurrentLocation } from '@/ui/hooks/geolocation'
import { usePlatform } from '@/ui/hooks/usePlatform'
import GeolocationError from '@/ui/location/GeolocationError'
import { addState, cleanLabel } from '@/ui/utils/location-utils'
import { useAnalytics } from 'use-analytics'
import useDeepCompareEffect from 'use-deep-compare-effect'

const LocationAutocomplete = styled(Autocomplete)(
  ({ theme }) => `
  .MuiInputBase-adornedEnd {
    padding-right: ${theme.spacing(1.25)} !important;
  }

  .MuiOutlinedInput-root {
    .MuiAutocomplete-hasPopupIcon&,.MuiAutocomplete-hasClearIcon&,
    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon& {
      padding: 0;
    }
  }

  .MuiOutlinedInput-input {
    display: flex;
    ::-ms-clear {
      display: none;
    }
    .MuiAutocomplete-hasPopupIcon&,.MuiAutocomplete-hasClearIcon&,
    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon& {
      padding: ${theme.spacing(1.25)} ${theme.spacing(2.5)}
    }
  }
  `
)

const locIcons = {
  default: 'fas fa-location',
  apple: 'far fa-location-arrow',
  locating: 'far fa-circle-notch fa-spin',
}

const LocateMeButton = (props) => {
  const {
    loading = false,
    label = 'Locate Me',
    showButtonLabel = false,
    ...buttonProps
  } = props

  const platform = usePlatform()
  const iconClassName = loading
    ? locIcons.locating
    : platform.isApple
    ? locIcons.apple
    : locIcons.default

  const icon = (
    <Box
      component="i"
      className={clsx(iconClassName, 'fa-faw')}
      sx={{ fontSize: '0.96rem !important' }}
    />
  )

  if (showButtonLabel)
    return (
      <Button
        aria-label={label}
        title={label}
        startIcon={icon}
        size="small"
        // color="greyTextButton"
        color="inherit"
        sx={{ px: 1.5 }}
        {...buttonProps}
      >
        {label}
      </Button>
    )

  return (
    <IconButton aria-label={label} title={label} {...buttonProps}>
      {icon}
    </IconButton>
  )
}

const styles = (theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
})

const LocationInput = observer(
  ({
    classes,
    id = 'location-select',
    disabled = false,
    value = null,
    showButtonLabel = false,
    defaultLocationButton = false,
    autoFocus = false,
    // disableStateLabel = false,
    onChange = () => undefined,
    ...acprops
  }) => {
    const { tenantConfig: tc } = useConfig()
    const cfg = tc.search?.location || {}
    const { disableStateLabel = false } = cfg

    const { track } = useAnalytics()
    const [internalValue, setInternalValue] = useState(value)
    const [inputValue, setInputValue] = useState('')
    const [inputRef, setInputRef] = useState()

    // update value
    // TODO: doesn't trigger on replace (hack in SurveyLocation)
    const isHome = id === 'location-select-home'
    useDeepCompareEffect(() => {
      const v = isStateTreeNode(value) ? toJS(value) : value

      setInputValue(v ? v.label : '')
      setInternalValue(v)
    }, [toJS(value) || {}])

    // autoFocus

    useEffect(() => {
      inputRef && autoFocus && inputRef.focus()
    }, [inputRef, autoFocus])

    // autocomplete query

    // const query = cleanLabel(inputValue)

    const { filters } = cfg || {}

    const params = {
      searchTerm: cleanLabel(inputValue),
      ...filters,
    }

    const { data: locres } = useQuery(
      ['search-locality', params],
      async () => await getLocationSuggestions(params),
      {
        staleTime: ms({ hours: 24 }),
        keepPreviousData: true,
        select: (data = []) => {
          // console.log({ data })
          const locFull = disableStateLabel
            ? internalValue?.label
            : addState(internalValue?.label, internalValue?.state)
          // const locName = cleanLabel(internalValue?.label)

          // input val matches internal value
          if (internalValue && inputValue === locFull) return [internalValue]

          if (data?.length > 0) return data

          return data || []
        },
        onSettled: () => geoloc.reset(),
      }
    )

    // console.log({ locres })

    const noOptionsText =
      params.searchTerm.length <= 3
        ? 'Enter suburb or postcode'
        : 'No results found'

    // locate me

    const geoloc = useCurrentLocation({
      filters,
      onSuccess: (newValue) => {
        track('locationInputLocateMe', {
          category: 'Location',
          label: newValue?.label || 'null',
        })

        // change

        setInternalValue(newValue)
        onChange({ type: 'locate', code: 'Locate' }, newValue)
      },
      onError: (error) => {
        const { type, location } = error

        if (type === 'LocationFiltered' && location)
          return {
            ...error,
            message: `${tc.options.title} is not available in your region (${location.name} ${location.state}).`,
            suggestDefaultLocation: true,
          }

        return error
      },
    })

    const showGeolocError = !!geoloc.error?.message

    // handlers

    const change = (e, newValue, trackEvent = 'locationSelect') => {
      track(trackEvent, {
        category: 'Location',
        label: newValue?.label || 'null',
      })

      setInternalValue(newValue)
      onChange(e, newValue)
    }

    const handleInputChange = (_, newInputValue) => {
      setInputValue(newInputValue)
    }

    const handleAutoselectChange = (e, newValue) => {
      change(e, newValue, 'locationInputSelect')
    }

    const handleDefaultLocationClick = (e, newValue) => {
      change(e, newValue, 'locationInputSelect')
      geoloc.reset()
    }

    // const locIcon = geoloc.loading ? locIcons.busy : locIcons.default
    const disableAutocomplete = disabled || geoloc.loading

    const getOptionLabel = (option) => {
      if (!option) return ''
      if (isStateTreeNode(option) && !isAlive(option)) return ''
      if (typeof option === 'string') return option

      const { label, state } = option

      if (!(label && state)) return ''
      if (!disableStateLabel) return addState(label, state)
      return label
    }

    const isOptionEqualToValue = (option, value) => option.id === value.id

    // console.log({ internalValue, options })

    // const renderAdornments

    const renderInput = ({ inputProps, InputProps, ...other }) => {
      // MuiAutocomplete uses absolute padding & spacing for endAdornments and only provides
      // rendered components. This uses child renderProps to filter out AutocompleteEndAdornment
      // contents removing the popup icon.

      const { endAdornment, ...rootProps } = InputProps
      const adornments = React.Children.toArray(
        endAdornment.props.children
      ).filter((a) => !a.props['aria-label'].match(/^(Open|Close)$/))

      const customEndAdornments = (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: 'rgba(0, 0, 0, 0.59)',
          }}
        >
          {adornments}
          <LocateMeButton
            disabled={disableAutocomplete}
            loading={geoloc.loading}
            showButtonLabel={showButtonLabel}
            onClick={geoloc.locate}
          />
        </Box>
      )
      const placeholder = isHome ? '  Where are you?' : 'Suburb'

      return (
        <OutlinedInput
          {...rootProps}
          endAdornment={customEndAdornments}
          inputProps={inputProps}
          inputRef={setInputRef}
          placeholder={placeholder}
          variant="outlined"
          fullWidth
          sx={
            isHome && {
              borderRadius: '100px 0px 0px 100px',
              border: '#7e63ab solid',
            }
          }
        />
      )
    }
    return (
      <div className={classes.root}>
        <LocationAutocomplete
          id={id}
          fullWidth
          disabled={disableAutocomplete}
          noOptionsText={noOptionsText}
          autoHighlight
          autoComplete={false}
          blurOnSelect
          selectOnFocus
          inputValue={inputValue}
          value={internalValue}
          options={locres || []}
          onChange={handleAutoselectChange}
          onInputChange={handleInputChange}
          renderInput={renderInput}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={isOptionEqualToValue}
          filterOptions={(x) => x}
          {...acprops}
        />

        <GeolocationError
          error={geoloc?.error}
          defaultLocationButton={defaultLocationButton}
          onLocationChange={handleAutoselectChange}
        />
      </div>
    )
  }
)

export default withStyles(styles, { name: 'AMSLocationInput' })(LocationInput)
