import React from 'react'

import _ from 'lodash'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { styled } from '@mui/material/styles'
import { Box, Button, IconButton, Fab, Drawer } from '@mui/material'
import PerfectScrollbar from 'react-perfect-scrollbar'

import Json from './Json'
import MetaTags from './MetaTags'
import TestLinks from './TestLinks'
import RouteMachine from './RouteMachine'

import { useConfig } from '@/config'
import { useDebugStore } from '@/stores'

import { neatJson } from '@/ui/vendor/neatjson'
import { debugHelperConfig } from '@/stores/debug/config'

const tokens = {
  backgroundColor: '#334',
  color: '#eee',
  menuColor: '#999',
  menuHover: '#fff',
  menuSelected: '#eee',
  menuSelectedFontWeight: '700',
  font: '"JetBrains Mono", monospace',
  fontSize: '12px',
}

const DebugButton = styled(IconButton)`
  position: absolute;
  bottom: 10px;
  /* left: 10px; */
  right: 10px;
  z-index: 3001;
  opacity: 0.5;
`

const DebugPanel = styled(Box)`
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 0px;
  width: 100vw;
  z-index: 3000;
  background-color: ${tokens.backgroundColor};
  font-family: ${tokens.font};
  font-size: ${tokens.fontSize};
  color: ${tokens.color};
`

const DebugContents = styled(Box)`
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
`

const DebugMenuContainer = styled(Box)`
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;

  height: 400px;
  max-height: 400px;
  overflow: hidden;

  & .scrollbar-container {
    width: 100%;
  }

  /* padding: 8px; */
`

const DebugMenuItemContainer = styled(Box)`
  cursor: pointer;
  color: ${tokens.menuColor};
  &::hover {
    color: ${tokens.menuHover};
  }

  &.dbh-selected {
    color: ${tokens.menuSelected};
  }
`

const HostContainer = styled(Box)`
  flex: 1 0 0;
  display: flex;
  height: 400px;
  max-height: 400px;
  overflow: hidden;

  & .scrollbar-container {
    width: 100%;
  }
`

const InfoTitle = styled('span')`
  font-size: 1rem;
  font-weight: 600;
`

const Expander = styled('span')`
  min-width: 1.2rem;
  /* font-size: 1.2rem; */
  text-align: center;
  /* background-color: rgba(0, 0, 0, 0); */
  /* color: ${tokens.menuColor}; */
  /* border: none; */
`

// const DebugHostContainer = styled(Box)`
//   flex: 1 0 auto;
//   display: 'flex',
//   flex-
// `

//const hc = populateHelperConfig(helperConfig)

const helpers = {
  Json,
  TestLinks,
  RouteMachine,
}

const DebugMenu = observer(({ items }) => {
  // const { items = [] } = config
  const store = useDebugStore()

  const handleItemClick = (item) => () => {
    store.setHelper2(item)
  }

  const DebugMenuItem = observer(({ item }) => {
    const { key, pathStr, items } = item

    const state = store._helperMenuState[pathStr]

    const expandContent =
      item.type === 'store' ? ['-', '+'][state.expanded | 0] : null

    // const

    const selected = store._helper?.pathStr === pathStr

    const toggleExpand = () => () => {
      if (item.type !== 'store') return
      store.toggleMenuExpand()
    }

    // console.log({ pathKey })

    return (
      <>
        <DebugMenuItemContainer
          className={selected && 'dbh-selected'}
          sx={{ py: 0.2 }}
          onClick={handleItemClick(item)}
        >
          <span>{key || 'no key'}</span>
          {/* <div><Expander onClick={toggleExpand}>{expandContent}</Expander>{' '} */}
          {/* <div>{pathStr} {store._helper?.pathStr}</div> */}
          {/* <div>{item.type}</div> */}
        </DebugMenuItemContainer>
        {items && (
          <Box sx={{ ml: 2 }}>
            <DebugMenuItems items={items} />
          </Box>
        )}
      </>
    )
  })

  const DebugMenuItems = ({ items = [] }) =>
    _.map(items, (item, i) => <DebugMenuItem key={i} item={item} />)

  const ReactQueryEnable = () => {
    const rqenabled = store.reactQueryDevtools

    return (
      <DebugMenuItemContainer className={rqenabled && 'dbh-selected'}>
        <div onClick={() => store.toggleReactQuery()}>React Query Devtools</div>
      </DebugMenuItemContainer>
    )
  }

  return (
    <DebugMenuContainer sx={{ p: 2 }}>
      <PerfectScrollbar
        options={{
          suppressScrollX: true,
          minScrollbarLength: 50,
        }}
      >
        <Box sx={{ mb: 2 }}>
          <ReactQueryEnable />
        </Box>

        <Box sx={{ pr: 2 }}>{items && <DebugMenuItems items={items} />}</Box>
      </PerfectScrollbar>
    </DebugMenuContainer>
  )
})

const DebugHelperHost = observer(() => {
  const store = useDebugStore()
  const { _helper: h } = store

  const renderHelper = (helperConfig) => {
    const H = helpers[helperConfig?.type]
    if (!H) return <Box sx={{ my: 1 }}>No Helper defined for this path.</Box>

    return <H helperConfig={helperConfig} />
  }

  const title = h?.path.join('.')
  const helper = h && renderHelper(h)
  // const hcfg = JSON.stringify(_.omit(h, ['_this']))

  return (
    <HostContainer>
      <PerfectScrollbar
        options={{
          suppressScrollX: true,
          minScrollbarLength: 50,
        }}
      >
        <Box sx={{ p: 2, width: '100%' }}>
          <Box sx={{ mb: 2 }}>
            <InfoTitle>{title}</InfoTitle>
          </Box>
          {/* <pre>{hcfg}</pre> */}
          {helper}
        </Box>
      </PerfectScrollbar>
    </HostContainer>
  )
})

export const DebugHelper = observer(() => {
  const { appConfig } = useConfig()
  const store = useDebugStore()

  const handleHelperButton = () => store.toggleShow()

  if (!appConfig.debugHelpers) return null

  return (
    <>
      <DebugButton size="small" onClick={handleHelperButton}>
        <i className="fas fa-bug" />
      </DebugButton>

      {store.show && (
        <DebugPanel sx={{ height: 400, maxHeight: 400 }}>
          <DebugContents>
            <DebugMenu items={store.menu.items} />
            <DebugHelperHost />
          </DebugContents>
        </DebugPanel>
      )}
    </>
  )
})

// export default DebugHelper
