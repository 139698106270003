import React, { useState } from 'react'

import platform from 'platform'
import isbot from 'isbot'
//import { PlatformPath } from 'path/posix'
type Platform = typeof platform

const ieVersion = (ua?: string) => ua?.match(/(?:\b(MS)?IE\s+|\bTrident\/7\.0;.*\s+rv:)(\d+)/)?.[2]
const isApple = (family?: string) => !!family?.match(/^(iOS|OS X)$/)

export type UsePlatformState = {
  platform: Platform
  isBot: boolean
  ieVersion?: string
  isApple: boolean
}

export const getPlatform = (pl: Platform = platform): UsePlatformState => ({
  platform: pl,
  isBot: isbot(pl.ua),
  ieVersion: ieVersion(pl.ua),
  isApple: isApple(pl.os?.family),
})

export const usePlatform = (): UsePlatformState => React.useState(getPlatform(platform))[0]
