import _ from 'lodash'
import { toJS, makeAutoObservable } from 'mobx'

const searchPathTypes = {
  'false,false': 'root',
  'false,true': 'searchCategory',
  'true,false': 'searchLocation',
  'true,true': 'searchLocationCategory',
}

class SearchRouteView {
  store = null
  rootStore = null

  constructor(store) {
    makeAutoObservable(this)

    this.store = store
    this.rootStore = store.rootStore
  }

  get searchParams() {
    return this.rootStore.search.params
  }

  get pathSources() {
    const { location, selectedCategoriesMeta: category } = this.searchParams

    const pathTypeName = searchPathTypes[[!!location, category.length > 0]]
    const pathType = this.store.path.pathType[pathTypeName]

    return {
      pathType,
      source: {
        location,
        category,
      },
    }
  }
}

export class RouteViews {
  store = null
  rootStore = null
  searchRouteStore = null

  constructor(store) {
    makeAutoObservable(this)

    this.store = store
    this.rootStore = store.rootStore

    this.searchRouteStore = new SearchRouteView(this.store)
  }

  get regionsTitle() {
    const pc = _.mapValues(this.store.match.params, p => p.replaceAll('_', ' '))
    return { ...pc }
  }

  get categoriesRoute() {
    const params = this.store.match?.params
    const cats = this.rootStore.tenant.categoriesFromParams2(params) || []
    const { location } = this.rootStore.search.params
    const locality = location?.asLocality

    // TODO: just supports single for now
    const categories = cats[0]

    return {
      source: { location, locality, categories },
    }
  }

  get searchRoute() {
    return null
  }
}
