import { types, flow, getRoot } from 'mobx-state-tree'

import _ from 'lodash'
import { toJS } from 'mobx'
import uniq from 'lodash/uniq'
import flatten from 'lodash/flatten'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'
import flatMap from 'lodash/flatMap'
import uniqBy from 'lodash/uniqBy'

import { config } from '@/config'
const { tenantConfig: tc } = config

import { Content } from './Content'
import { getCommissionedServicesByOutlet } from '@/api/legacy'
import { toJSDeep } from '../utils/mobx'
import { asArray } from '@/utils'

export const TenantOptions = types.model({
  title: '',
  categoryMode: types.enumeration('CategoryMode', ['single', 'multiple']),
  combineMode: types.enumeration('CombineMode', [
    'filters',
    'categories',
    'tags',
    'diary-format',
  ]),
  informationCategories: types.optional(types.boolean, false),
  defaultCategoryTitle: '',
  defaultCategorySuffix: '',
  maxVerboseCategories: 3,
})

export const MetaCategory = types
  .model({
    id: '',
    title: '',
    titleSuffix: '',
    shortUrl: '',
    icon: '',
    search: true,
    show: '',
    categories: types.maybeNull(types.array(types.integer), []),
    tags: types.maybeNull(types.array(types.integer), []),
    commissionedServices: types.maybeNull(types.array(types.integer), []),
    diaryFormat: types.maybeNull(types.array(types.integer), []),
    description: '',
  })

  .volatile((self) => ({
    searchOptions: null,
    // badge: null,
  }))

  .views((self) => ({
    get suffix() {
      const to = getRoot(self).tenant.options
      return self.titleSuffix || to.defaultCategorySuffix
    },

    get suffixRegex() {
      return new RegExp(`\\s${self.suffix}$`)
    },

    get titleWithoutSuffix() {
      if (!self.suffix) return self.title
      return self.title.replace(self.suffixRegex, '')
    },

    get titleWithSuffix() {
      if (!self.suffix || self.title.match(self.suffixRegex)) return self.title
      return [self.title, self.suffix].join(' ')
    },

    get urlFormatted() {
      return self.shortUrl.replace(/\s/g, '_')
    },

    get longUrl() {
      return self.title.replace(/[&]/g, 'and').replace(/[\s,/]+/g, '_')
    },
  }))

  .actions((self) => ({
    setVolatile(category) {
      self.searchOptions = category.searchOptions
      // self.badge = category.badge
    },
  }))

export const Tenant = types
  .model({
    options: types.optional(TenantOptions, {
      categoryMode: 'single',
      combineMode: 'categories',
      informationCategories: false,
    }),
    categories: types.array(MetaCategory, []),
    content: types.optional(Content, {}),
  })

  .views((self) => ({
    get helpers() {
      return {
        json: ['options', 'categories', 'content'],
      }
    },

    get metaCategoryMap() {
      return keyBy(self.categories, 'id')
    },

    get metaCategoryLookups() {
      const keys = ['urlFormatted', 'longUrl']
      const lucap = _.fromPairs(
        keys.map((k) => [k, _.keyBy(self.categories, k)])
      )
      const lu = _.mapValues(lucap, (v) =>
        _.mapKeys(v, (mv, mk) => mk.toLowerCase())
      )
      const all = _.reduce(lu, (prev, curr) => ({ ...prev, ...curr }), {})

      const res = { ...lu, all }
      return res
    },

    getCategory(id) {
      return self.metaCategoryMap[id]
      // return id in self.metaCategoryMap ? self.metaCategoryMap[id] : undefined
    },

    categoriesForIds(metaCategoryIds) {
      return asArray(metaCategoryIds)
        .map((catId) => self.metaCategoryMap[catId])
        .filter((mc) => !!mc)
    },

    categoriesFromParams(params) {
      console.warn(
        'categoriesFromParams() deprecated, use categoriesFromParams2()'
      )

      const cmode = tc.options.categoryMode

      const rconfig = tc?.search?.router?.category
      if (!rconfig) throw new Error('No config')

      const field = rconfig.field || 'title'
      const delimiter = rconfig.delimiter || '+'

      if (cmode === 'single') {
        // console.log('single')
        if (!params.category) return null

        const pp = params.category.replace('_', ' ')
        const pc = self.categories.find((c) => c[field] === pp)

        return pc ? pc.id : null
      }

      if (cmode === 'multiple') {
        console.log('multiple')
        if (!params.category) return []

        const pp = params.category
          .split(delimiter)
          .map((c) => c.replace('_', ' '))
        const cm = keyBy(self.categories, field)
        const cids = pp.filter((c) => c in cm).map((c) => cm[c].id)

        return cids
      }

      return null
    },

    categoriesFromParams2(param, { field = 'all' } = {}) {
      const category = param?.category || param?.categories
      // const { category } = param || {}

      if (!_.isString(category)) return []
      if (!category) return []

      const rconfig = tc?.search?.router?.category
      if (!rconfig) throw new Error('No config')

      const delimiter = rconfig.delimiter || '+'
      const pp = category.split(delimiter).map((c) => c.toLowerCase())

      const cl = pp.map((p) => this.metaCategoryLookups[field]?.[p])
      return _.compact(cl)
    },

    // combine search params from combined metacategories
    // for simple array types (categories, tags, diaryFormat etc.)

    combinedParamArray(
      metaCategoryIds,
      iterator,
      options = {
        default: [-1],
      }
    ) {
      const metaCategories = self.categoriesForIds(metaCategoryIds)
      const res = _.uniq(
        _.flatMap(metaCategories, (mc) => asArray(iterator(mc)))
      )
      return res.length > 0 ? res : options.default
    },

    // lookup for badges
    // TODO: migrate when elastic named queries available

    get commissionedServiceCategoryMap() {
      const cscats = self.categories.reduce((acc, curr) => {
        ;(curr?.commissionedServices || []).forEach((csid) => {
          // console.log('csid', csid)
          acc[csid] = [...(acc[csid] || []), curr.id]
        })
        return acc
      }, {})

      const res = mapValues(cscats, (c) =>
        uniq(c.sort((a, b) => a.localeCompare(b)))
      )
      return res
    },

    categoriesForCommissionedService(id) {
      const cats = (self.commissionedServiceCategoryMap[id] || []).map((id) =>
        self.getCategory(id)
      )
      return cats
    },

    // access md content

    getContent({ type = 'static', region = 'default', id = 'default' }) {
      const ck = (type, region, id) => `${type}/${region}/${id}`

      const regions = region === 'default' ? [region] : [region, 'default']

      const content = regions
        .map((r) => self.content.items.get(ck(type, r, id)))
        .find((c) => c)

      return content
    },
  }))

  .actions((self) => ({
    loadVolatile() {
      ;(tc.categories || []).forEach((cat) =>
        self.metaCategoryMap[cat.id].setVolatile(cat)
      )
    },

    // this might be redundant now!
    // loadCommissionedServices: flow(function* loadCommissionedServices({
    //   commissioningOutletId,
    //   typeMap,
    // }) {
    //   if (!commissioningOutletId) {
    //     console.error(`Invalid commissioningOutletId`)
    //     return
    //   }

    //   const cso = yield getCommissionedServicesByOutlet(commissioningOutletId)

    //   if (!cso || cso.length === 0) {
    //     console.error(`No valid commissioned services returned for outlet ${commissioningOutletId}`)
    //     return
    //   }

    //   // bin cs types by category
    //   // a commisioned service can be in more than one category (cs type)

    //   const tagMap = mapValues(keyBy(typeMap, 'tagId'), v => v.category)

    //   const csm = cso.reduce((acc, curr) => {
    //     if (!curr.types) return acc

    //     curr.types.forEach(t => {
    //       const catId = tagMap[t.tagId]
    //       if (!catId) return

    //       acc[catId] = [...(acc[catId] || []), curr.commissionedServiceId]
    //     })

    //     return acc
    //   }, {})

    //   // get icons

    //   const icons = uniqBy(flatMap(cso.map(cs => cs.types)), 'tagId').map(t => ({
    //     id: tagMap[t.tagId],
    //     icon: t.icon,
    //   }))

    //   // save to ams categories

    //   self.categories.forEach(cat => {
    //     cat.icon = icons[cat.id] || ''
    //     cat.commissionedServices = csm[cat.id] || []
    //   })
    // }),
  }))
