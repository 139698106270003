import React, { useEffect } from 'react'

import _ from 'lodash'
// import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useAnalytics } from 'use-analytics'

import { makeStyles } from '@mui/styles'
import { Box, Typography, Divider } from '@mui/material'

import { useConfig } from '@/config'
import { useRootStore } from '@/stores/root/store'
import {
  resolveVariantText,
  resolveVariantStrings,
} from '@/stores/root/utils/survey-utils'

import SurveyQuestion from './SurveyQuestion'
import SurveyButtons from './SurveyButtons'
import SurveyLocation from './SurveyLocation'
import SurveyRadius from './SurveyRadius'
import SurveyRadioGroup from './SurveyRadioGroup'
import SurveyCategories from './SurveyCategories'
import SurveyInterests from './SurveyInterests'
import SurveyScaleEval from './SurveyScaleEval'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  question: {
    margin: theme.spacing(3, 0),
  },
}))

const Question = observer(({ pathway, question }) => {
  const { track } = useAnalytics()
  const store = useRootStore()
  const { tenantConfig } = useConfig()

  const questionText = resolveVariantText(question, 'question', pathway)
  const descriptionText = resolveVariantText(question, 'description', pathway)

  // survey questions default to set(key, value) using question id

  const handleChange = (value) => {
    track('surveyQuestionChange', {
      category: 'Survey',
      label: question.id,
    })

    store.survey.set(question.id, value)
  }

  switch (question.type) {
    case 'buttons':
      return <SurveyButtons question={question} pathway={pathway} />

    case 'location':
      return (
        <SurveyLocation
          defaultLocation={tenantConfig.search?.location?.defaultLocation}
          label={questionText}
          // value={toJS(store.survey[question.id])}
          // onChange={handleChange}
        />
      )

    case 'radius':
      return (
        <SurveyRadius
          label={questionText}
          value={store.survey[question.id]}
          onChange={handleChange}
        />
      )

    case 'radio':
      return (
        <SurveyRadioGroup
          name={question.id}
          label={questionText}
          value={store.survey[question.id]}
          onChange={handleChange}
          options={question.options}
          deselect
          row
        />
      )

    case 'categories':
      return (
        <SurveyCategories
          question={question}
          label={questionText}
          metaCategoryMap={store.tenant.metaCategoryMap}
          selected={
            store.survey[question.id] ? store.survey[question.id].slice() : []
          }
          onChange={handleChange}
        />
      )

    case 'interests':
      return (
        <SurveyInterests
          question={question}
          pathway={pathway}
          selected={
            store.survey[question.id] ? store.survey[question.id].slice() : []
          }
          onChange={handleChange}
        />
      )

    case 'scaleeval':
      return <SurveyScaleEval question={question} />

    default:
      return null
    // return <Box>Question children...</Box>
  }
})

const SurveyStep = ({ pathway, questions, stepConfig }) => {
  const { track } = useAnalytics()
  const classes = useStyles()

  const questionProps = stepConfig?.questions
    .map((q) => questions[q.id])
    .filter((q) => q)
    .map((q) => ({
      pathway,
      question: {
        ...q,
        strings: resolveVariantStrings(q.strings, pathway),
      },
    }))

  const stepTitle = resolveVariantText(stepConfig, 'title', pathway)
  const stepDescription = resolveVariantText(stepConfig, 'description', pathway)

  useEffect(() => {
    track(`surveyStepChange`, {
      category: 'Survey',
      label: stepTitle,
    })
  }, [stepConfig, stepTitle, track])

  return (
    <Box className={classes.root}>
      <Typography variant="h5" gutterBottom>
        {stepTitle}
      </Typography>
      <Divider sx={{ mb: 3 }} />
      {stepDescription && (
        <Typography variant="body1" sx={{ my: 2 }}>
          {stepDescription}
        </Typography>
      )}

      {questionProps?.map((q, i) => (
        <Box key={i} sx={{ mb: 3 }}>
          <Question {...q} />
        </Box>
      ))}

      {/* <pre>
        {JSON.stringify(stepConfig, null, 2)}
      </pre> */}
    </Box>
  )
}

export default SurveyStep
