import React from 'react'

import { observer } from 'mobx-react-lite'
import { useMediaQuery } from '@mui/material'

import { useConfig } from '@/config'
import { useRootStore } from '@/stores/root/store'
import { useResultsControl } from '@/ui/search/results/results-control'

import DesktopSearch from '@/ui/search/DesktopSearch'
import MobileSearch from '@/ui/search/MobileSearch'

import SearchRouteHandler from '@/ui/router/SearchRouteHandler'

import { useSearchParams } from 'react-router-dom'

const ResultsControlUpdater = observer(() => {
  const store = useRootStore()

  const { setSelectedResult } = useResultsControl({
    subscriber: { searchId: 'all', control: 'searchRoot' },
  })

  React.useEffect(() => {
    setSelectedResult()
  }, [store.search.searchCount])

  return null
})

const Search = () => {
  const store = useRootStore()
  const { tenantConfig: tc } = useConfig()
  const isSearchRouteHandlerEnabled =
    tc?.routeOptions?.searchRouteHandler?.enabled
  const queryParameters = new URLSearchParams(window.location.search)
  const tab = queryParameters.get('tab')

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('ph'))
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('ph'))

  //tab are able to be switch through url
  if (tab) {
    store.search.ui.setSelectedTab(Number(tab))
  }

  return (
    <>
      <ResultsControlUpdater />
      {isSearchRouteHandlerEnabled && <SearchRouteHandler />}

      {isMobile && <MobileSearch />}
      {isDesktop && <DesktopSearch />}
    </>
  )
}

export default Search
