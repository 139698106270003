import React from 'react'

import { makeStyles } from '@mui/styles'

import { Link as RouterLink } from 'react-router-dom'
// import { Button } from '@mui/material'
import Button from '@/ui/components/buttons/Button'
import FAIcon from '@/ui/components/icon/FontAwesomeIcon'

export const SiteSupportButton = (props) => {
  const { variant = 'outlined', to = '/support' } = props

  return (
    <Button
      // className={[classes.button, classes.horizontalMargin].join(' ')}
      component={RouterLink}
      variant={variant}
      to={to}
      startIcon={<FAIcon fa="fas fa-user-headset" />}
    >
      Contact Site Support
    </Button>
  )
}

export default SiteSupportButton
