import React, { useState, useEffect } from 'react'

import { observer } from 'mobx-react-lite'
import { addDays } from 'date-fns'

import enLocale from 'date-fns/locale/en-AU'
import { Box, Button, Input, useTheme } from '@mui/material'
import {
  LocalizationProvider,
  DateRangePicker,
} from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'

const DateRangeParam = observer(({ value, onChange = () => undefined }) => {
  const theme = useTheme()

  // const internalValue = value ? [value.start, value.end] : [null, null]
  const [internalValue, setInternalValue] = useState([null, null])

  const anytimeSelected = value?.delta === -1

  const minDate = new Date()
  const maxDate = addDays(new Date(), 90)

  useEffect(() => {
    setInternalValue([value?.start || null, value?.end || null])
  }, [value?.start, value?.end])

  const handleAccept = newValue => {
    const dr = newValue?.some(x => x)
      ? {
          delta: -2,
          start: newValue[0],
          end: newValue[1],
        }
      : {
          delta: -1,
          start: null,
          end: null,
        }

    onChange(dr)
  }

  const handleDateChange = newValue => {
    setInternalValue(newValue)
  }

  const handleAnytimeClick = () => {
    onChange({
      delta: -1,
      start: null,
      end: null,
    })
  }

  const renderInput = (startProps, endProps) =>
    <Box sx={{ display: 'flex' }}>
      <Input {...startProps.inputProps} placeholder={startProps.label} />
      <Box sx={{ mx: 2 }}>→</Box>
      <Input {...endProps.inputProps} placeholder={endProps.label} />
    </Box>

  return (
    <div>
      <Box pt={1} pb={2}>
        <Button
          variant="contained"
          size="small"
          color={anytimeSelected ? 'primary' : 'default'}
          onClick={handleAnytimeClick}
          disableElevation
        >
          Anytime
        </Button>
      </Box>

      <LocalizationProvider dateAdapter={AdapterDateFns} locale={enLocale}>
        <DateRangePicker
          startText="From"
          endText="To"
          value={internalValue}
          onChange={handleDateChange}
          onAccept={handleAccept}
          renderInput={renderInput}
          desktopModeMediaQuery={theme.breakpoints.up('sm')}
          minDate={minDate}
          maxDate={maxDate}
        />
      </LocalizationProvider>
    </div>
  )
})

export default DateRangeParam
