import React, { useState, useEffect, useRef } from 'react'

import clsx from 'clsx'
import scrollIntoView from 'scroll-into-view-if-needed'

import { makeStyles } from '@mui/styles'
import {
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  Link,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const useStyles = makeStyles(theme => ({
  root: {

  },
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 26,
    },
  },
  intro: {
    fontSize: 18,
  },
  emergencyButton: {
    fontSize: 20,
    fontWeight: 600,

  },
  grid: {
    marginTop: theme.spacing(4),
  },
  accordion: {
    backgroundColor: '#fafafa',
    margin: theme.spacing(2, 0),
    // boxShadow: 'none',
    boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.12)',
    '&:before': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
  },
  groupTitle: {
    fontSize: 18,
    fontWeight: 600,
  },
  groupIcon: {
    marginRight: theme.spacing(2),
  },
  serviceLink: {
    // backgroundColor: '#f0f',
    ...theme.typography.body1,
    // marginTop: theme.spacing(2),
    // marginLeft: 'auto',
  },
  serviceLinkIcon: {
    marginRight: theme.spacing(1),
  },
  appLink: {
    backgroundColor: '#666',
    color: '#fff',
    borderRadius: 4,
    fontWeight: 700,
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    '& > i': {
      marginRight: theme.spacing(1),
    },
  },
}))

const lm = {
  tel: ({ link }) => ({
    icon: 'fas fa-phone',
    label: link,
    href: `tel:${link.replaceAll(/[^0-9]/g, '')}`,
  }),
  web: ({ link }) => ({
    icon: 'fa fa-link',
    label: link.replace((/^https?:\/+/), ''),
    href: link,
  }),
}

const ServiceLink = ({ icon, label, href }) => {
  const classes = useStyles()

  return (
    <div className={classes.serviceLink}>
      <Link
        color="primary"
        href={href}
        underline="none"
        target="_blank"
      >
        <i className={clsx(icon, classes.serviceLinkIcon)} />
        {label}
      </Link>
      {/* {href} */}
    </div>
  )
}

const appLinkConfig = {
  ios: {
    icon: 'fab fa-apple',
    label: 'App Store',
  },
  android: {
    icon: 'fab fa-google-play',
    label: 'Google Play',
  },
}

const AppLink = ({ type, url }) => {
  const classes = useStyles()

  if (!type || !url) return null

  const cfg = appLinkConfig[type]
  if (!cfg) return

  return (
    <Link
      className={classes.appLink}
      href={url}
      target="_blank"
    >
      <i className={clsx(cfg.icon, 'fa-fw')} />
      {cfg.label}
    </Link>
  )
}

const Item = ({
  title,
  links = [],
  apps = [],
  description,
}) => {
  const classes = useStyles()

  // const serviceLinkProps = phone
  //   ? { icon: 'fas fa-phone', label: phone, href: `tel:${phone.replace(/[^\d]/g, '')}`}
  //   : chatUrl
  //   ? { icon: 'fas fa-comments', label: 'Chat', href: chatUrl }
  //   : null

  const linkProps = links?.map(l => {
    if (!(l.type in lm)) return null
    return lm[l.type](l)
  })

  return (
    <Box my={3} mx={2}>
      <Typography variant="h6" className={classes.groupTitle} gutterBottom>{title}</Typography>

      {linkProps && linkProps.map((lp, i) => <ServiceLink key={i} {...lp} />)}

      <Box mt={2}>
        <Typography variant="body1">{description}</Typography>
      </Box>

      {apps && (
        <Box mt={2} display="flex" flexDirection="row">
          {apps.map((a, i) => <AppLink key={i} {...a} />)}

        </Box>
      )}



      {/* {JSON.stringify(linkProps)} */}

      {/* <Box textAlign="right">
        {linkProps.map((lp, i) => <ServiceLink key={i} {...lp} />)}
      </Box> */}
    </Box>
  )
}

const Group = ({
  group,
  expanded,
  expandId,
  onAccordionClick = () => undefined,
}) => {
  const classes = useStyles()

  return (

    <Accordion
      classes={{ root: classes.accordion }}
      expanded={expanded === expandId}
      onChange={() => onAccordionClick(expandId)}
      TransitionProps={{
        timeout: 200,
        addEndListener: (node, done) => {
          if (expanded === expandId) {
            const el = document.getElementById(expandId)
            if (!el) return

            setTimeout(() => {
              scrollIntoView(el, {
                scollMode: 'always',
                behavior: 'smooth',
                block: 'start',
              })
            }, 200)
          }
        },
      }}
      square
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        // aria-controls={id}
        id={expandId}
      >
        <Typography variant="h6" color="textSecondary">
          <i className={clsx(group.icon, classes.serviceLinkIcon)} />{group.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.accordionDetails }}>
        {group.items.map((item, i) => (
          <Item
            key={i}
            {...item}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  )
}

const Block = ({ data }) => {
  const classes = useStyles()
  const rootRef = useRef(null)
  const [expanded, setExpanded] = useState('')
  const groups = data.groups

  const handleAccordionClick = (panel) => {
    setExpanded(expanded === panel ? undefined : panel)
  }

  const scrollTo = ({ expanded }) => {
    if (!expanded) {
      const el = document.getElementById('scroll-content')
      if (!el) return

      el.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  useEffect(() => {
    scrollTo({ expanded })
  }, [expanded])

  return (
    <div ref={rootRef} className={classes.root}>
      <Typography
        variant="h4"
        gutterBottom
        className={classes.title}
      >
        Self Help Resources
      </Typography>
      <Divider />
      <br />
      <br />

      <div>
        {groups.map((group, i) => (
          <Group
            key={i}
            id={`${group.id}-${i}`}
            group={group}
            expanded={expanded}
            expandId={`${group.id}-${i}`}
            onAccordionClick={handleAccordionClick}
          />
        ))}
      </div>

    </div>
  )

}

export default Block
