import React from 'react'
import { Helmet } from 'react-helmet'

import { useConfig } from '@/config'

export const GoogleSiteVerification = () => {
  const { tenantConfig: tc } = useConfig()
  const { tagId } = tc.analytics?.googleSiteVerification || {}

  if (!tagId) return null

  return (
    <Helmet>
      <meta name="google-site-verification" content={tagId} />
    </Helmet>
  )
}
