import Information from './information/Information'
import SearchParameters from './params/SearchParameters'
import SearchResultsList from './results/SearchResultsList'
import {
  Container,
  Box,
  Portal,
  SwipeableDrawer,
  useMediaQuery,
} from '@mui/material'
import { withStyles, useTheme } from '@mui/styles'
import clsx from 'clsx'
import { useConfig } from '@/config'
import { useRootStore } from '@/stores/root/store'
import { toJSDeep } from '@/stores/root/utils/mobx'
import { useAtom } from 'jotai'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useState, useEffect } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { paramsPortalAtom } from '@/ui/app/app-params-portal'
import Acknowledgement from '@/ui/footer/Acknowledgement'
import Sponsors from '@/ui/footer/Sponsors'
import { usePlatform } from '@/ui/hooks/usePlatform'
import LocationDialog from '@/ui/location/LocationDialog'
import ResultsMap from '@/ui/maps/ResultsMap'
import DesktopSearchToolbar from '@/ui/search/DesktopSearchToolbar'
import InfoOnly from '@/ui/search/information/InfoOnly'
import Calendar from '@/ui/search/results/Calendar'
import { TenantThemeProvider } from '@/ui/theme/TenantTheme'
import { useAnalytics } from 'use-analytics'
import HHSOutlets from '@/ui/footer/HHSOutlets'

// import { useRecoilValue } from 'recoil'
// import { paramsPortalState } from '@/ui/state/search'

const paramatersWidth = 380
const resultsWidth = 450

const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  hidden: {
    display: 'none !important',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  tabPanel: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  parameterDrawer: {
    minWidth: paramatersWidth,
    maxWidth: paramatersWidth,
    overflowX: 'hidden',
  },
  parameters: {
    width: paramatersWidth,
    borderRight: '1px solid rgba(0, 0, 0, 0.1)',
  },
  results: {
    width: resultsWidth,
    borderRight: '1px solid rgba(0, 0, 0, 0.1)',
  },
  informationOnly: {
    flex: '1 0 0',
  },
  map: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100vw - 380px - 450px)',
  },
  mapContainer: {
    flexGrow: 1,
  },
})

const TabPanel = ({ classes, index, value, children, ...otherProps }) => {
  const cn = clsx(classes.tabPanel, value !== index && classes.hidden)

  // if (value !== index) return null

  return (
    <div
      className={cn}
      role="tabpanel"
      id={`desktop-search-tabpanel-${index}`}
      aria-labelledby={`desktop-search-tab-${index}`}
      {...otherProps}
    >
      {children}
    </div>
  )
}

const ParametersScrollContainer = ({ classes, ...searchParamProps }) => {
  return (
    <div className={classes.parameters}>
      <PerfectScrollbar
        options={{
          suppressScrollX: true,
          minScrollbarLength: 50,
        }}
      >
        <SearchParameters {...searchParamProps} />
      </PerfectScrollbar>
    </div>
  )
}

const mapFooterComponents = {
  HHSOutlets,
  Sponsors,
  Acknowledgement,
}

const mapFooterStyles = {
  root: {
    flex: '0 1 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'flex-start',
  },
  section: {},
}

const MapFooter = withStyles(mapFooterStyles, { name: 'AMSMapFooter' })(
  ({ classes, sections = [] }) => {
    return (
      <div className={classes.root}>
        {sections.map((s, key) => {
          const C = mapFooterComponents[s.component]
          return C ? (
            <div key={key} className={classes.section}>
              <C {...s.props} variant="mapFooter" />
            </div>
          ) : null
        })}
      </div>
    )
  }
)

const Results = observer(({ classes, config }) => {
  const store = useRootStore()
  const containerRef = React.useRef()

  const { tenantConfig: tc } = useConfig()
  const mapFooterProps = tc.ui?.search?.desktop?.mapFooter

  const infoCategory = store.search.selectedInformationCategory
  // console.log(config.options.search)
  if (infoCategory && !infoCategory?.search) return <InfoOnly />

  return (
    <>
      <div className={classes.results}>
        <PerfectScrollbar
          options={{
            minScrollbarLength: 50,
          }}
        >
          <TenantThemeProvider theme="variants.searchResultsList">
            <SearchResultsList searchId={config.options.search} />
          </TenantThemeProvider>
        </PerfectScrollbar>
      </div>

      <div className={classes.map}>
        <div
          ref={containerRef}
          data-leaflet-hint="mapcontainer"
          className={classes.mapContainer}
        >
          <ResultsMap
            searchId={config.options.search}
            containerRef={containerRef}
          />
        </div>

        {mapFooterProps && (
          <TenantThemeProvider theme="variants.mapFooter">
            <MapFooter {...mapFooterProps} />
          </TenantThemeProvider>
        )}
      </div>
    </>
  )
})
const CalendarScheduler = () => {
  return <Calendar />
}

const Info = ({ classes }) => {
  return (
    <div className={classes.informationOnly}>
      <PerfectScrollbar
        options={{
          minScrollbarLength: 50,
        }}
      >
        <Container maxWidth="md" sx={{ py: 4 }}>
          <Information />
        </Container>
      </PerfectScrollbar>
    </div>
  )
}

const tabComponentsMap = {
  Information: Info,
  SearchResults: Results,
  Calendar: CalendarScheduler,
}

const TabComponent = ({ classes, value, index, config }) => {
  const Component = tabComponentsMap[config.component]
  return (
    <TabPanel classes={classes} value={value} index={index}>
      <Component classes={classes} config={config} />
    </TabPanel>
  )
}

const DesktopSearch = observer(({ classes }) => {
  const store = useRootStore()
  const { track } = useAnalytics()

  const { tenantConfig: tc } = useConfig()
  const scfg = tc.ui?.search || {}
  const dscfg = scfg?.desktop || {}
  const ui = store.search.ui

  const tabs = scfg.tabs || []
  const tab = ui.selectedTab

  const [showSearch, setShowSearch] = useState(false)
  const [showLocation, setShowLocation] = useState(false)

  const theme = useTheme()
  const isTablet = useMediaQuery(
    theme.breakpoints.down(dscfg.paramsDrawer?.breakpoint || 'tb')
  )

  const { isBot } = usePlatform()

  const initLocation = tc.search?.location?.initLocation
  const desktopParams = scfg.parameterGroups?.desktop
  const appBarParams = scfg.parameterGroups?.appBar

  // const paramsPortal = useRecoilValue(paramsPortalState)
  const [paramsPortal] = useAtom(paramsPortalAtom)

  // wait to show location dialog
  useEffect(() => {
    const show =
      !isBot &&
      initLocation === 'dialog' &&
      !store.search.ui.locationDialogShown &&
      store.search.params.location === null

    const timeout = setTimeout(() => {
      if (show) {
        track('searchLocationDialogOpen', {
          category: 'Search',
          label: 'Show',
        })
      }
      setShowLocation(show)
    }, 1000)

    return () => clearTimeout(timeout)
  }, [store.search.params.location, store.search.ui.locationDialogShown])

  const toggleShowSearchParams = (value) => {
    track('toggleShowSearchParams', {
      category: 'Search',
      label: value ? 'Show' : 'Hide',
    })

    setShowSearch(value)
  }

  const handleCloseLocationDialog = () => {
    track('searchLocationDialogClose', {
      category: 'Search',
      label: 'Close',
    })

    setShowLocation(false)
    store.search.ui.setLocationDialogShown(true)
  }

  return (
    <div className={classes.root}>
      {initLocation === 'dialog' && (
        <LocationDialog
          open={showLocation}
          onClose={handleCloseLocationDialog}
        />
      )}

      <DesktopSearchToolbar
        tabs={tabs}
        showSearch={showSearch}
        onClickSearch={toggleShowSearchParams}
      />

      {appBarParams && paramsPortal && !isTablet && (
        <Portal container={paramsPortal}>
          <SearchParameters {...appBarParams} />
        </Portal>
      )}

      <div className={classes.content}>
        {!isTablet && !appBarParams && (
          <>
            <ParametersScrollContainer classes={classes} {...desktopParams} />
          </>
        )}
        {isTablet && (
          <SwipeableDrawer
            anchor="left"
            open={showSearch}
            onOpen={() => toggleShowSearchParams(true)}
            onClose={() => toggleShowSearchParams(false)}
          >
            <div className={classes.parameterDrawer}>
              <PerfectScrollbar
                options={{ minScrollbarLength: 50, suppressScrollX: true }}
              >
                <SearchParameters {...desktopParams} />
              </PerfectScrollbar>
            </div>
          </SwipeableDrawer>
        )}
        {tabs.map((t, i) => (
          <TabComponent
            key={i}
            classes={classes}
            value={tab}
            index={i}
            config={t}
          />
        ))}
      </div>
    </div>
  )
})

export default withStyles(styles, { name: 'AMSDesktopSearch' })(DesktopSearch)
