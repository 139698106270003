import _ from 'lodash'

/**
 * Ensures value is an array of something
 *
 * ```typescript
 * asArray(undefined)
 * // []
 *
 * asArray('bang!')
 * // ['bang']
 *
 * asArray(['already', 'done'])
 * // ['already', 'done']
 */
export const asArray = (val: any) => {
  if (!val) return []
  if (Array.isArray(val)) return val
  if (typeof val === 'object' && 'slice' in val) return val.slice()
  return [val]
}

/**
 * Removes item from array without mutating the array
 */
export const removeAt = <A = unknown>(arr: A[], index: number) => [
  ...[...arr].slice(0, index),
  ...[...arr].slice(index + 1),
]
