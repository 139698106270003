import React from 'react'

import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  root: {
    // width: 300,
    // width: '100%',
    // margin: theme.spacing(2, 2)
  },
  // margin: {
  //   margin: theme.spacing(1, 3),
  // },
  label: {
    fontWeight: 300,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}))

export const IconLabel = ({ iconClasses = '', label = '' }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography className={classes.label} variant="body1" gutterBottom>
        {iconClasses && <i className={`${iconClasses} ${classes.icon}`} />}
        {label}
      </Typography>
    </div>
  )
}

export default IconLabel
