/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'
import { useAnalytics } from 'use-analytics'
import useDeepCompareEffect from 'use-deep-compare-effect'

export const useAppAnalytics = () => {
  const location = useLocation()
  const { page, track } = useAnalytics()
  const [path, setPath] = useState(null)

  // global a href tracking

  const registerOutboundTracking = useCallback(() => {
    document.addEventListener('click', e => {
      const a = e.target.closest('a')
      if (!a) return

      const href = a.getAttribute('href')
      if (href.match(/^\/.*/)) return

      track('outboundLinkClick', {
        category: 'External',
        label: href,
      })
    })
  }, [track])

  useEffect(() => {
    registerOutboundTracking()
  }, [registerOutboundTracking])


  // track url changes

  const updatePath = useCallback(() => {
    if (location.pathname !== path) {
      page()
      setPath(location.pathname)
    }
  }, [location.pathname, path, page])

  useEffect(() => {
    updatePath()
  }, [location, updatePath])

}
