import React from 'react'

import clsx from 'clsx'
import ReactMarkdown from 'markdown-to-jsx'
import { NavLink } from 'react-router-dom'
// import scrollIntoView from 'scroll-into-view-if-needed'

import { withStyles } from '@mui/styles'
import { Typography, Divider, Link } from '@mui/material'

import { config } from '@/config'

// import MarkdownAccordion from '@/ui/components/atoms/MarkdownAccordion'
import ImageAsset from '@/ui/components/images/ImageAsset'
// import HomeButtons from '@/ui/components/atoms/HomeButtons'
import Logo from '@/ui/components/images/Logo'
// import PreviousSearch from '@/ui/components/atoms/PreviousSearch'

const { tenantConfig } = config

export const styles = (theme) => ({
  ul: {
    paddingInlineStart: '30px !important',
    [theme.breakpoints.down('sm')]: {
      paddingInlineStart: '20px !important',
    },
  },
  li: {
    marginTop: theme.spacing(1),
  },
  navLink: {
    fontSize: 16,
  },
  h1: {
    fontSize: 20,
  },
  p: {
    color: theme.palette.primary.text,
    marginBottom: theme.spacing(1),
  },
  a: {
    color: '#666',
    // fontWeight: 600,
  },
  i: {
    marginRight: theme.spacing(1),
  },
})

// const useStyles = makeStyles(styles)

// const useVariantStyles = makeVariantStyles(styles, {
//   name: 'AMSMarkdown',
//   variants: tenantConfig?.themeOptions?.variants?.markdown,
// })

const makeOptions = ({ classes }) => ({
  overrides: {
    h1: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'h6',
        classes: {
          root: classes.h1,
        },
      },
    },
    h2: { component: Typography, props: { gutterBottom: true, variant: 'h5' } },
    h3: {
      component: Typography,
      props: { gutterBottom: true, variant: 'subtitle1' },
    },
    h4: {
      component: Typography,
      props: { gutterBottom: true, variant: 'caption', paragraph: true },
    },
    p: {
      component: Typography,
      props: {
        variant: 'body1',
        paragraph: true,
        classes: {
          root: classes.p,
        },
      },
    },
    a: {
      component: Link,
      props: {
        underline: 'none',
        target: '_blank',
        classes: {
          root: classes.a,
        },
      },
    },
    ul: {
      component: withStyles(styles)(({ classes, children }) => (
        <ul className={classes.ul}>{children}</ul>
      )),
    },
    li: {
      component: withStyles(styles)(({ classes, ...props }) => (
        <li className={classes.li}>
          <Typography component="span" {...props} />
        </li>
      )),
    },
    hr: {
      component: Divider,
    },
    i: {
      component: withStyles(styles)(({ classes, className, ...props }) => (
        <i {...props} className={clsx(className, classes.i)} />
      )),
    },
    navlink: {
      component: NavLink,
    },
    imgasset: {
      component: ImageAsset,
    },
    logo: {
      component: Logo,
    },
  },
})

const Markdown = ({ classes, ...props }) => {
  const options = makeOptions({ classes })
  return <ReactMarkdown options={options} {...props} />
}

export default withStyles(styles, { name: 'AMSMarkdown' })(Markdown)
