import _ from 'lodash'

const baseConfig = {
  id: 'debug',
  items: [
    {
      id: 'routeStore',
      items: [
        {
          id: 'config',
          items: [
            {
              id: '_config',
              component: 'Json',
              store: 'routeStore',
              storePath: 'config._config',
            },
          ],
        },
        {
          id: 'location',
          component: 'Json',
          store: 'routeStore',
          storePath: 'location',
        },
        {
          id: 'match',
          component: 'Json',
          store: 'routeStore',
          storePath: 'match',
        },
        {
          id: 'matchedRoute',
          component: 'Json',
          store: 'routeStore',
          storePath: 'matchedRoute',
        },
        {
          id: 'path',
          items: [
            {
              id: 'pathTypes',
              component: 'Json',
              store: 'routeStore',
              storePath: 'path.pathTypes',
            },
            // {
            //   id: 'pathTypeReverse',
            //   component: 'Json',
            //   store: 'routeStore',
            //   storePath: 'path.pathTypeReverse',
            // },
            {
              id: 'pathConfig',
              component: 'Json',
              store: 'routeStore',
              storePath: 'path.pathConfig',
            },
            {
              id: 'pathSources',
              component: 'Json',
              store: 'routeStore',
              storePath: 'path.pathSources',
            },
            {
              id: 'info',
              component: 'Json',
              store: 'routeStore',
              storePath: 'path.info',
            },
          ],
        },
        {
          id: 'metaConfig',
          component: 'Json',
          store: 'routeStore',
          storePath: 'metaConfig',
        },
        {
          id: 'routeConfigViews',
          component: 'Json',
          store: 'routeStore',
          storePath: 'routeConfigViews',
        },
        {
          id: 'metaTagInfo',
          component: 'Json',
          store: 'routeStore',
          storePath: 'metaTagInfo',
        },
        {
          id: 'helmetState',
          component: 'Json',
          store: 'routeStore',
          storePath: 'helmetState',
        },
      ],
    },
  ],
}

const populateConfigNode = (node, { path: parentPath = [] } = {}) => {
  if (!node) return

  const { id, items: itemsSrc } = node
  const path = [...parentPath, id]
  const spath = _.tail(path)

  const items = _.isArray(itemsSrc)
    ? itemsSrc.map(child => populateConfigNode(child, { path: path }))
    : undefined

  return {
    ...node,
    path: spath,
    pathKey: spath.join('.'),
    ...(items && {
      items,
      // itemsMap: _.keyBy(items, 'id'),
    }),
  }
}

export const buildConfigMap = config => {
  let configMap = {}

  const mapNode = node => {
    console.log('node')

    const { pathKey, items } = node
    configMap[pathKey] = node

    if (!_.isArray(items)) return
    items.forEach(child => mapNode(child))
  }

  mapNode(config)
  return configMap
}

export const debugHelperConfig = populateConfigNode(baseConfig)
