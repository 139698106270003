import React from 'react'

import {
  Select,
  MenuItem,
} from '@mui/material'

// eslint-disable-next-line react/display-name
const CategorySelect = ({
  paramConfig = null,
  selectMode = 'single',
  selected = null,
  categories = [],
  onChange = () => undefined,
  ...inputProps
}) => {
  const handleChange = ({ target: { value }}) => {
    const nval = value !== 'null' ? value : null
    onChange(nval)
  }

  const options = [
    { value: 'null', label: 'All Categories' },
    ...categories.map(o => ({ value: o.id, label: o.title })),
  ]

  const ival = selected || 'null'

  if (selectMode !== 'single') return (
    <div>selectMode: {selectMode} not supported!!</div>
  )

  return (
    <Select
      {...inputProps}
      value={ival}
      variant="outlined"
      onChange={handleChange}
    >
      {options.map((o, i) => (
        <MenuItem key={i} value={o.value}>{o.label}</MenuItem>
      ))}
    </Select>
  )
}

export default CategorySelect
