import { surveyShared } from '../shared/survey'

import keyBy from 'lodash/keyBy'

const questions = [
  ...surveyShared.questions,
  {
    id: 'categories',
    type: 'categories',
    label: 'Categories',
    icon: 'far fa-stream',
    question: {
      self: 'What are you interested in?',
      other: 'What are they interested in?',
    },
    categoryGroups: [
      {
        title: 'Services',
        categories: [
          'emergency-services',
          'general-practitioners',
          'mental-health-practitioners',
          'social-support-services',
        ],
      },
      {
        title: 'Information',
        categories: [
          'online-resources-and-information',
        ],
      },
    ],
  },
]

export const survey = {
  questions,

  // TODO: move this and the merge to the config loader
  questionsMap: keyBy(questions, 'id'),

  pathways: [
    {
      pathways: ['self', 'other'],
      steps: [
        {
          title: 'Location',
          questions: [
            { id: 'location' },
            { id: 'radius' },
          ],
          validation: [
            ['location', 'neq', null],
          ],
        },
        {
          title: {
            self: 'About You',
            other: 'About Them',
          },
          questions: [
            { id: 'age' },
            { id: 'gender' },
            { id: 'atsi' },
            { id: 'sexuality' },
          ],
        },
        {
          title: 'Categories',
          questions: [
            { id: 'categories' },
          ],
        },
        {
          title: 'K-5',
          questions: [
            { id: 'k5' },
          ],
          when: [
            ['pathway', 'eq', 'self'],
            ['atsi', 'eq', 'Yes'],
            ['categories', 'contains', 'mental-health-practitioners'],
          ],
        },
        {
          title: 'K-10',
          questions: [
            { id: 'k10' },
          ],
          when: [
            ['pathway', 'eq', 'self'],
            ['atsi', 'neq', 'Yes'],
            ['categories', 'contains', 'mental-health-practitioners'],
          ],
        },

      ],
    },
  ],

}
